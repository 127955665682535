export const monthNamesLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const smallMonthToLongMonth = (mon) => {
  return monthNamesLong[monthNamesShort.indexOf(mon)];
};

export const hoursDifference = (subscriptionTime, tempDataTime) => {
  var diffMs = subscriptionTime - tempDataTime;
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  return diffHrs;
};

export const daysDifference = (subscriptionTime, tempDataTime) => {
  var diffMs = subscriptionTime - tempDataTime;
  var diffDays = Math.floor(diffMs / 86400000);
  return diffDays;
};

export const minsDifference = (subscriptionTime, tempDataTime) => {
  var diffMs = subscriptionTime - tempDataTime;
  var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  return diffMins;
};

export const lastWeek = () => {
  var today = new Date();
  var nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  return nextweek;
};

export const nextDay = () => {
  var today = new Date();
  var nextDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  return nextDay;
};

export const nextMonth = () => {
  var today = new Date();
  var nextweek = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  return nextweek;
};
