import React from "react";

import ErrorMessageAtom from "../error-message/error-message.atom";
import ImportantFieldAsterik from "../important-field-asterik/important-field-asterik.atom";

import "./textfield.styles.scss";

const TextfieldAtom = ({
  value,
  setValue,
  redBorder,
  label,
  setError,
  error,
  required,
  name,
  errorMessage,
  disabled,
  regularExpression,
  type,
  disable,
  min,
  step,
  id,
  white,
  ...props
}) => {
  const [focus, setFocus] = React.useState(false);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (value || value === 0) {
      setFocus(true);
    }
  }, [value]);

  React.useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [focus]);

  const handleFocus = (e) => {
    setFocus(true);
  };

  const looseFocus = (e) => {
    if (!value && value !== 0) {
      setFocus(false);
    }
  };

  const handleChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    setValue(value);
    let errorCount = 0;
    if (required) {
      if (!value) {
        errorCount++;
      }
    }
    if (regularExpression) {
      if (!value.match(regularExpression)) {
        errorCount++;
      }
    }

    if (required || regularExpression) {
      setError(errorCount > 0 ? true : false);
    }
  };

  return (
    <div
      onClick={handleFocus}
      disabled={disabled}
      className={`textfield-atom ${white ? "white" : ""}`}
    >
      <label className={focus ? "focus" : ""}>
        {label} {required && <ImportantFieldAsterik />}
      </label>
      <input
        className={redBorder ? "redBorder" : ""}
        id={id}
        ref={inputRef}
        disabled={disabled}
        name={name ? name : "text-field"}
        type={type}
        value={value}
        min={min}
        onChange={handleChange}
        onBlur={looseFocus}
        {...props}
      />
      {error ? (
        <div className="error">
          <ErrorMessageAtom
            message={`${errorMessage ? errorMessage : `${label} is required`}`}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TextfieldAtom;
