const initialState = {
  createTableIndexLoading: { loading: false, success: false, error: null },
  globalBalanceLoading: { loading: false, success: false, error: null },
  globalBalance: [],
};

const qldbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_TABLE_INDEX_LOADING":
      return { ...state, createTableIndexLoading: action.payload };
    case "SET_GLOBAL_BALANCE":
      return { ...state, globalBalance: action.payload };
    case "SET_GLOBAL_BALANCE_LOADING":
      return { ...state, globalBalanceLoading: action.payload };
    default:
      return state;
  }
};

export default qldbReducer;
