const initialState = {
  disbursementLoading: {
    loading: false,
    success: false,
    error: null,
  },
  disbursementTableData: [],
  fetchDisbursementTableDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchDisbursementsDataFirestoreLoading: {
    loading: false,
    success: false,
    error: null,
  },
  disbursementsDataFirestore: [],
  fetchDisbursementDataFirestoreLoading: {
    loading: true,
    success: false,
    error: null,
  },
  disbursementDataFirestore: { data: {}, global: [], individual: [] },
  disbursementDataFirestoreSnapshotsSubscriptions: {
    data: null,
    global: null,
    individual: null,
  },
  startProcessLoading: { loading: false, error: null, success: false },
  globalDisbursementList: [],
  individualDisbursementList: [],
  globalLoading: true,
  individualLoading: true,
  pastDisbursements: [],
  initiationIndex: null,
};

const disbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DISBURSEMENT_LOADING":
      return {
        ...state,
        disbursementLoading: action.payload,
      };
    case "SET_DISBURSEMENT_TABLE_DATA":
      return {
        ...state,
        disbursementTableData: action.payload,
      };
    case "SET_DISBURSEMENT_TABLE_DATA_LOADING":
      return {
        ...state,
        fetchDisbursementTableDataLoading: action.payload,
      };
    case "SET_DISBURSEMENTS_DATA_FIRESTORE_LOADING":
      return {
        ...state,
        fetchDisbursementsDataFirestoreLoading: action.payload,
      };
    case "SET_GLOBAL_DISBURSEMENT_LIST":
      return { ...state, globalDisbursementList: action.payload };
    case "SET_INDIVIDUAL_DISBURSEMENT_LIST":
      return { ...state, individualDisbursementList: action.payload };
    case "SET_LOADING_GLOBAL_LIST":
      return { ...state, globalLoading: action.payload };
    case "SET_LOADING_INDIVIDUAL_LIST":
      return { ...state, individualLoading: action.payload };

    case "SET_DISBURSEMENTS_DATA_FIRESTORE":
      return {
        ...state,
        disbursementsDataFirestore: action.payload,
      };
    case "SET_DISBURSEMENT_DATA_FIRESTORE_LOADING":
      return {
        ...state,
        fetchDisbursementDataFirestoreLoading: action.payload,
      };
    case "SET_DISBURSEMENT_DATA_FIRESTORE":
      return {
        ...state,
        disbursementDataFirestore: {
          ...state.disbursementDataFirestore,
          ...action.payload,
        },
      };
    case "RESET_DISBURSEMENT_DATA_FIRESTORE":
      return {
        ...state,
        disbursementDataFirestore: { data: {}, global: [], individual: [] },
      };
    case "SET_DISBURSEMENT_DATA_FIRESTORE_SNAPSHOT_SUBSCRIPTIONS":
      return {
        ...state,
        disbursementDataFirestoreSnapshotsSubscriptions: action.payload,
      };
    case "SET_DISBURSEMENT_START_PROCESSING_LOADING":
      return {
        ...state,
        startProcessLoading: action.payload,
      };
    case "SET_PAST_DISBURSEMENTS":
      return { ...state, pastDisbursements: action.payload };
    case "SET_INDEX_TO_INITIATE_DISBURSEMENT":
      return { ...state, initiationIndex: action.payload };
    default:
      return state;
  }
};

export default disbursementReducer;
