// eslint-disable-next-line
export const mailFormat =
  // eslint-disable-next-line
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const nameFormat = /^[a-zA-Z0-9\s]+$/;
export const passwordFormat = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const numberFormat = /^[0-9]+$/;
export const descriptFormat = /^[\s\S]{15,1700}$/;
export const expressionToCheckHTMLTags = /<[^>]*>?/gm;

export const regexStrongPassword = // eslint-disable-next-line
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/;

export const simpleNameFormatCampaign = /^[\s\S]{3,100}$/;
export const simpleNameFormat = /^[\s\S]{1,20000000}$/;

export const allIntegerFormat = /^[0-9]*$/;
export const floatFormat = /^[0-9]*\.?[0-9]*$/;
export const urlFormat =
  // eslint-disable-next-line
  /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/;

export const phoneNumberFormat =
  // eslint-disable-next-line
  /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export const businessPhoneExtension = /^.[0-9]{5}$/;

export const stringFormat = /^[\s\S]{1,30}$/;

export const branchNumberFormat = /^.[0-9]{4}$/;

export const institutionNumberFormat = /^.[0-9]{2}$/;

export const accountNumberFormat = /^.[0-9]{3,9}$/;

export const dateFormat =
  // eslint-disable-next-line
  /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;

export const floatR =
  /^([+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/;
