import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { descriptFormat, mailFormat } from "../../utils/regularExpressions";
import { functions } from "../../firebase/firebaseConfig";

import PrimaryButton from "../primary-button/primary-button.component";
import TextfieldAtom from "../../atoms/textfield/textfield.atom";

import "./floating-help.styles.scss";

let initialState = {
  email: "",
  description: "",
};
const FloatingHelp = ({ showHelp, setShowHelp, ...props }) => {
  const [error, setError] = useState({
    email: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [issueData, setIssueData] = useState(initialState);
  const currentState = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.user);
  useEffect(() => {
    if (currentUser) {
      setIssueData({ email: currentUser.email, description: "" });
      setError({ email: false, description: "" });
    }
  }, [currentUser]);

  const handleChange = (value, name) => {
    let tempError = { ...error };
    if (value !== "") {
      if (name === "email") {
        if (value.match(mailFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
      if (name === "description") {
        if (value.match(descriptFormat)) {
          tempError = { ...error, [name]: false };
        } else {
          tempError = { ...error, [name]: true };
        }
      }
    } else {
      tempError = { ...error, [name]: true };
    }

    setError({ ...tempError });
    setIssueData({ ...issueData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Convert the DOM element with ID "root" to a PNG image using domtoimage
      const reportIssue = functions.httpsCallable("reportIssue");
      const response = await reportIssue({
        ...issueData,
        route: window.location.pathname,
        state: JSON.stringify(currentState),
      });
      if (response) {
        console.log("issue ", response);
        if (currentUser) {
          setError({ email: false, description: true });
          setIssueData({ email: currentUser.email, description: "" });
        } else {
          setError({ email: false, description: true });
          setIssueData({ email: "", description: "" });
        }

        setShowHelp(false);
        setLoading(false);
      }
    } catch (error) {
      // Log any errors that occurred during the request and set loading to false
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="button-floating-container" id="button-floating-component">
      <button className="button-floating" {...props}>
        {props.children}
      </button>
      {showHelp ? (
        <div className="help-modal">
          <div className="help-modal-header">Report an Issue</div>
          <div className="help-modal-body">
            <TextfieldAtom
              redBorder={error.email}
              disabled={currentUser.authenticated || loading}
              value={issueData.email}
              onChange={(e) => handleChange(e.target.value, "email")}
              placeholder="Enter your email"
            />
            <textarea
              className={`${error.description ? "redBorder" : ""}`}
              value={issueData.description}
              onChange={(e) => handleChange(e.target.value, "description")}
              disabled={loading}
              placeholder="Describe your issue"
            />
            <PrimaryButton
              disabled={
                error.description || loading || error.description === ""
              }
              small
              loading={loading}
              handleClick={handleSubmit}
            >
              Send
            </PrimaryButton>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FloatingHelp;
