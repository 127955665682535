const initialState = {
  allCauses: [],
  causesDataFirebase: [],
  disabledCauses: [],
  inActiveCauses: [],
  activeCauses: [],
  privateCauses: [],
  causeDataLoading: {
    loading: false,
    error: null,
    success: false,
  },
  fetchCauseDataLoading: {
    loading: false,
    error: null,
    success: false,
    causeLoaded: false,
  },
  transferDataFirestore: [],
  transferDataFirestoreLoading: {
    loading: false,
    success: false,
    error: null,
  },
  transferDetailFirestore: [],
  transferDetailFirestoreLoading: {
    loading: false,
    success: false,
    error: null,
  },
  followedCauses: [],
};

const causesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ALL_CAUSES":
      return { ...state, allCauses: action.payload };
    case "SET_CAUSE_DATA_LOADING":
      return { ...state, causeDataLoading: action.payload };
    case "SET_CAUSES_DATA_FIREBASE":
      return { ...state, causesDataFirebase: action.payload };
    case "SET_ARCHIVED_CAUSES":
      return { ...state, disabledCauses: action.payload };
    case "SET_INACTIVE_CAUSES":
      return { ...state, inActiveCauses: action.payload };
    case "SET_ACTIVE_CAUSES":
      return { ...state, activeCauses: action.payload };
    case "SET_PRIVATE_CAUSES":
      return { ...state, privateCauses: action.payload };
    case "FETCH_CAUSE_DATA_LOADING":
      return { ...state, fetchCauseDataLoading: action.payload };
    case "SET_TRANSFER_DATA_FIRESTORE_LOADING":
      return { ...state, transferDataFirestoreLoading: action.payload };
    case "SET_TRANSFER_DETAIL_FIRESTORE_LOADING":
      return { ...state, transferDetailFirestoreLoading: action.payload };
    case "SET_TRANSFER_DATA_FIRESTORE":
      return { ...state, transferDataFirestore: action.payload };
    case "SET_TRANSFER_DETAIL_FIRESTORE":
      return {
        ...state,
        transferDetailFirestore: {
          ...state.transferDetailFirestore,
          ...action.payload,
        },
      };

    case "RESET_TRANSFER_DETAIL_FIRESTORE":
      return {
        ...state,
        disbursementDataFirestore: { data: {}, global: [], individual: [] },
      };

    case "SET_FOLLOWED_CAUSES":
      return { ...state, followedCauses: action.payload };
    default:
      return state;
  }
};

export default causesReducer;
