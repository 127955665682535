export const fetchBankBalanceQLDB = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_BANK_BALANCE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const fetchBankBalanceData = firebase
        .functions()
        .httpsCallable("fetchBankBalanceData");
      const response = await fetchBankBalanceData();
      if (response.data.success) {
        dispatch({ type: "SET_BANK_BALANCE", payload: response.data.result });
        dispatch({
          type: "SET_BANK_BALANCE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = {
          status: 500,
          message: response.data.errorMessage,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log("error :", err.message);
      dispatch({
        type: "SET_BANK_BALANCE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetFetchBankBalanceLoading = () => ({
  type: "SET_BANK_BALANCE_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const withdrawFromBankBalanceQLDB = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "SET_WITHDRAW_FROM_BANK_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const withdrawFromBank = firebase
        .functions()
        .httpsCallable("withdrawFromBank");
      const response = await withdrawFromBank(data);
      if (response.data.success) {
        dispatch(fetchBankBalanceQLDB());
        dispatch({
          type: "SET_WITHDRAW_FROM_BANK_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = {
          status: 400,
          message: response.data.errorMessage,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log("error :", err);
      dispatch({
        type: "SET_WITHDRAW_FROM_BANK_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetwithdrawFromBankBalance = () => ({
  type: "SET_WITHDRAW_FROM_BANK_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchWithdrawTableFromQLDB = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_WITHDRAW_TABLE_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const fetchWithdrawTableData = firebase
        .functions()
        .httpsCallable("fetchWithdrawTableData");
      const resp = await fetchWithdrawTableData(data);
      if (resp.data.success) {
        dispatch({
          type: "SET_WITHDRAW_TABLE_DATA",
          payload: resp.data.result,
        });
        dispatch({
          type: "SET_WITHDRAW_TABLE_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: resp.data.errorMessage };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_WITHDRAW_TABLE_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
