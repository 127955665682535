import { checkFormatingOfLabel } from "./stringFormatting";

export const featureNames = [
  "manageServices",
  "organizationPage",
  "campaigns",
  "disbursement",
  "tips",
  "allUsers",
  "userdata",
  "withdraw",
  "dashboard",
  "uploadCSVPastUserRecord",
  "addMoreAdmins",
  "emailCMS",
  "transfer",
  "events",
  "taxReciepts",
];

export const routes = [
  "/manage-causes",
  "/project-page",
  "/create-project",
  "/disbursement-page",
  "/tips-page",
  "/all-users/:id",
  "/user-profile-dashboard",
  "/withdraw-page",
  "/admin-dashboard",
  "/upload-user-data",
  "/all-admins",
  "/email-actions",
  "/transfer-page",
  "/events/:eventPage",
  "/user-tax-reciepts",
];

export const applicationModeFeatures = ["disbursement", "cause-project"];

export const brandingFeatures = ["infaqueDefaultIlustration"];

export const makeFeatureObj = () => {
  let arr = {};

  for (let i in featureNames) {
    const item = featureNames[i];
    const obj = { active: false, route: routes[i] };
    arr[item] = obj;
  }
  return arr;
};

export const makeBrandingFeatureObj = () => {
  let arr = [];

  for (let i in brandingFeatures) {
    const item = brandingFeatures[i];
    const obj = { active: false };
    arr[item] = obj;
  }
  return arr;
};

export const getCampaignPageToggles = (
  campaignName,
  applicationMode,
  authenticated
) => {
  const dropdownArray = [
    {
      id: "active",
      name: "Active",
    },
    {
      id: "funded",
      name: "Funded",
    },
  ];
  if (applicationMode === "cause-project" && authenticated) {
    dropdownArray.push({
      id: `my${checkFormatingOfLabel(campaignName)}`,
      name: `My ${checkFormatingOfLabel(campaignName)}`,
    });
  }
  return dropdownArray;
};

export const activeInactiveToggle = [
  {
    id: "active",
    name: "Upcoming",
  },
  {
    id: "inactive",
    name: "Ended",
  },
  {
    id: "myEvents",
    name: "My Events",
  },
];

export const OnlineOnsiteToggle = [
  {
    id: "allModes",
    name: "All Modes",
    value: "allModes",
  },
  {
    id: "online",
    name: "Online",
    value: true,
  },
  {
    id: "onsite",
    name: "Onsite",
    value: false,
  },
];

export const EventTypes = [
  {
    id: "allEvents",
    name: "All Events",
    value: "allEvents",
  },
  {
    id: "myEvents",
    name: "My Events",
    value: "myEvents",
  },
];
