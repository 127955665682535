import firebase from "../../firebase/firebaseConfig";
import { checkForErrors } from "../../utils/errorFunctions";
import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const createCampaign =
  (data, email, draft, emailData) => async (dispatch) => {
    try {
      let response = { data: { success: true } };

      if (emailData) {
        data = { ...data, emailData };
      }

      dispatch({
        type: "SET_CAMPAIGN_CREATION_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      if (draft) {
        const createCampaignDraftToFirebase = firebase
          .functions()
          .httpsCallable("createCampaignDraftToFirebase");
        response = await createCampaignDraftToFirebase(data, email);
      } else {
        const createCampaignToFirebase = firebase
          .functions()
          .httpsCallable("createCampaignToFirebase");
        response = await createCampaignToFirebase(data, email);
      }
      response = { data: { success: true } };
      if (response.data.success) {
        dispatch({
          type: "SET_CAMPAIGN_CREATION_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        dispatch({
          type: "SET_CAMPAIGN_BOOLEAN",
          payload: { campaignValue: response },
        });
      } else {
        const errorObject = {
          status: 400,
          message: response.data.errorMessage,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_CAMPAIGN_CREATION_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const updateCauseProject =
  (causeProject) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({
      type: "UPDATE_CAUSE_PROJECT_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const updateCauseProjectData = firebase
        .functions()
        .httpsCallable("updateCauseProjectData");
      console.log(causeProject);
      const resp = await updateCauseProjectData(causeProject);

      const data = resp.data;
      checkForErrors(data);
      dispatch({
        type: "UPDATE_CAUSE_PROJECT_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "UPDATE_CAUSE_PROJECT_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const resetUpdateCauseProjectDataLoading = () => ({
  type: "UPDATE_CAUSE_PROJECT_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetCreateCampaignLoading = () => ({
  type: "SET_CAMPAIGN_CREATION_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchOrganizationCampaigns = (orgId) => {
  return async (dispatch, getState, { getFirestore }) => {
    const db = getFirestore();

    dispatch({
      type: "SET_ALL_CAMPAIGN_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    let campaigns;
    let campaignData = [];
    try {
      if (orgId) {
        campaigns = await db
          .collection("organization")
          .doc(orgId)
          .collection("campaign")
          .get();
      } else {
        campaigns = await db.collectionGroup("campaign").get();
      }

      for (let doc of campaigns.docs) {
        const orgId = doc.ref.parent.parent.id;
        const org = await db.collection("organization").doc(orgId).get();

        const orgData = {
          ...doc.data(),
          id: doc.id,
          status: doc.data().status ? "Active" : "Inactive",
          orgId: orgId,
          orgName: org.data().organizationInfo.orgName,
          orgEmail: org.data().organizationInfo.orgEmail,
        };
        campaignData.push(orgData);
      }
      dispatch({
        type: "SET_CAMPAIGN_DATA",
        payload: [...campaignData],
      });
      dispatch({
        type: "SET_ALL_CAMPAIGN_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ALL_CAMPAIGN_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchOrganizationDraftCampaigns = (orgId) => {
  return async (dispatch, getState, { getFirestore }) => {
    const db = getFirestore();
    dispatch({
      type: "SET_CAMPAIGN_DRAFT_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    let campaignData = [];
    let campaigns;
    try {
      if (orgId) {
        campaigns = await db
          .collection("organization")
          .doc(orgId)
          .collection("campaignDraft")
          .get();
      } else {
        campaigns = await db.collectionGroup("campaignDraft").get();
      }
      for (let doc of campaigns.docs) {
        const orgId = doc.ref.parent.parent.id;
        const org = await db.collection("organization").doc(orgId).get();
        const orgData = {
          ...doc.data(),
          id: doc.id,
          orgId: orgId,
          status: doc.data().status ? "Active" : "Inactive",
          orgName: org.data().organizationInfo.orgName,
          orgEmail: org.data().organizationInfo.orgEmail,
        };
        campaignData.push(orgData);
      }

      dispatch({
        type: "SET_CAMPAIGN_DRAFT_DATA",
        payload: [...campaignData],
      });

      dispatch({
        type: "SET_CAMPAIGN_DRAFT_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAMPAIGN_DRAFT_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAllCampaigns = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const campaignsCollectionRef = firestore
      .collectionGroup("campaign")
      .orderBy("createdAt", "desc");

    dispatch({
      type: "SET_CAMPAIGNS_LIST_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const allCampaignsSnapshot = campaignsCollectionRef.onSnapshot(
        async (allCampaignsRef) => {
          const campaigns = [];
          const inActiveCampaigns = [];
          const archivedCampaigns = [];
          const campaignsIndexed = {};
          for (const campaignRef of allCampaignsRef.docs) {
            const campaign = {
              ...campaignRef.data(),
              campaignId: campaignRef.id,
              organizationId: campaignRef.ref.parent.parent.id,
            };
            const status = campaignRef.data().status;
            if (status && status !== "archived") {
              campaigns.push(campaign);
              campaignsIndexed[campaignRef.id] = campaign;
            } else if (!status) {
              inActiveCampaigns.push(campaign);
              campaignsIndexed[campaignRef.id] = campaign;
            } else {
              archivedCampaigns.push(campaign);
              campaignsIndexed[campaignRef.id] = campaign;
            }
          }

          dispatch({
            type: "SET_ALL_CAMPAIGNS",
            payload: [...campaigns, ...archivedCampaigns, ...inActiveCampaigns],
          });
          dispatch({ type: "SET_ACTIVE_CAMPAIGNS_LIST", payload: campaigns });
          dispatch({
            type: "SET_ACTIVE_CAMPAIGNS_LIST_INDEXED",
            payload: campaignsIndexed,
          });
          dispatch({
            type: "SET_ARCHIVED_CAMPAIGNS_LIST",
            payload: archivedCampaigns,
          });
          dispatch({
            type: "SET_IN_ACTIVE_CAMPAIGNS_LIST",
            payload: inActiveCampaigns,
          });
          dispatch({
            type: "SET_CAMPAIGNS_LIST_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "all-campaigns-snapshot",
          snapshot: allCampaignsSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAMPAIGNS_LIST_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAllCauseProjects = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const causeProjectsCollectionRef = firestore
      .collection("causeProjects")
      .orderBy("createdAt", "desc");

    dispatch({
      type: "SET_CAUSE_PROJECT_LIST_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const causeProjectsSnapshot = causeProjectsCollectionRef.onSnapshot(
        async (causeProjectRef) => {
          const causeProjects = [];
          const causeProjectsById = {};

          for (const campaignRef of causeProjectRef.docs) {
            const causeProject = {
              ...campaignRef.data(),
              campaignId: campaignRef.id,
              organizationId: null,
              causeProject: true,
            };
            causeProjectsById[campaignRef.id] = {
              ...campaignRef.data(),
              campaignId: campaignRef.id,
              organizationId: null,
              causeProject: true,
            };
            causeProjects.push(causeProject);
          }

          dispatch({
            type: "SET_ALL_CAUSE_PROJECTS",
            payload: causeProjects,
          });
          dispatch({
            type: "SET_ALL_CAUSE_PROJECTS_BY_ID",
            payload: causeProjectsById,
          });
          dispatch({
            type: "SET_ACTIVE_CAUSE_PROJECTS",
            payload: causeProjects.filter(
              (item) => item.status && item.status !== "archived"
            ),
          });
          dispatch({
            type: "SET_INACTIVE_CAUSE_PROJECTS",
            payload: causeProjects.filter((item) => !item.status),
          });
          dispatch({
            type: "SET_ARCHIVED_CAUSE_PROJECTS",
            payload: causeProjects.filter((item) => item.status === "archived"),
          });

          dispatch({
            type: "SET_CAUSE_PROJECT_LIST_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "cause-projects-snapshot",
          snapshot: causeProjectsSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAUSE_PROJECT_LIST_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAllCampaignsDrafts = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const campaignsDraftCollectionRef =
      firestore.collectionGroup("campaignDraft");

    dispatch({
      type: "SET_ALL_CAMPAIGN_DRAFTS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const allCampaignsDraftsSnapshot = campaignsDraftCollectionRef.onSnapshot(
        (allCampaignDraftsRef) => {
          const campaignDrafts = [];
          for (const campaignDraftRef of allCampaignDraftsRef.docs) {
            const campaign = {
              ...campaignDraftRef.data(),
              draftId: campaignDraftRef.id,
              organizationId: campaignDraftRef.ref.parent.parent.id,
            };

            campaignDrafts.push(campaign);
          }

          dispatch({
            type: "SET_ALL_CAMPAIGN_DRAFTS",
            payload: campaignDrafts,
          });

          dispatch({
            type: "SET_ALL_CAMPAIGN_DRAFTS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "all-campaigns-drafts-snapshot",
          snapshot: allCampaignsDraftsSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ALL_CAMPAIGN_DRAFTS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const deleteDraftCampaign = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_DELETE_DRAFT_CAMPAIGN_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const setDeleteDraftCampaign = firebase
      .functions()
      .httpsCallable("setDeleteDraftCampaign");
    const response = await setDeleteDraftCampaign(data);
    if (response.data.success) {
      dispatch({
        type: "SET_DELETE_DRAFT_CAMPAIGN_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } else {
      dispatch({
        type: "SET_DELETE_DRAFT_CAMPAIGN_LOADING",
        payload: {
          loading: false,
          success: false,
          error: response.data.errorMessage,
        },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: "SET_DELETE_DRAFT_CAMPAIGN_LOADING",
      payload: { loading: true, success: false, error: err.message },
    });
  }
};

export const resetDeleteDraftCampaignLoading = () => ({
  type: "SET_DELETE_DRAFT_CAMPAIGN_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetSendEmailLoading = () => ({
  type: "SET_SEND_EMAIL_CONTRIBUTORS_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const optOutOfCampaign = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "OPT_OUT_OF_CAMPAIGN_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    const functions = firebase.functions();
    const userOptOutOfCampaign = functions.httpsCallable(
      "userOptOutOfCampaign"
    );
    try {
      const response = await userOptOutOfCampaign(data);
      const result = response.data;
      if (result.success) {
        dispatch({
          type: "OPT_OUT_OF_CAMPAIGN_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: result.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "OPT_OUT_OF_CAMPAIGN_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetOptOutOfCampaignLoading = () => ({
  type: "OPT_OUT_OF_CAMPAIGN_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchCampaignAllCampaignInActionPosts = (id, force) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const campaignInActionPosts = getState().campaign.campaignInActionPosts;
    const campaignPostsAreFetchedAlready = campaignInActionPosts[id];
    if (campaignPostsAreFetchedAlready && !force) {
      return;
    }
    dispatch({
      type: "FETCH_CAMPAIGN_ALL_CAMPAIGN_IN_ACTION_POSTS_LOADING",
      payload: { loading: true, error: null, success: false },
    });

    const firestore = getFirestore();
    try {
      const campaignInActionQueryRef = firestore
        .collection("campaign_in_action")
        .where("campaignId", "==", id)
        .orderBy("timeValue", "desc");
      const campaignInActionSnapshot = campaignInActionQueryRef.onSnapshot(
        (snapshot) => {
          const posts = snapshot.docs.map((item) => ({
            ...item.data(),
            id: item.id,
          }));
          dispatch({
            type: "SET_CAMPAIGIN_IN_ACTION_ALL_POSTS_OF_CAMPAIGN",
            payload: { ...campaignInActionPosts, [id]: posts },
          });
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "campaign-or-cause-project-in-action-snapshot",
          snapshot: campaignInActionSnapshot,
        })
      );
      dispatch({
        type: "FETCH_CAMPAIGN_ALL_CAMPAIGN_IN_ACTION_POSTS_LOADING",
        payload: { loading: false, error: null, success: true },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_CAMPAIGN_ALL_CAMPAIGN_IN_ACTION_POSTS_LOADING",
        payload: { loading: false, error: err.message, success: false },
      });
    }
  };
};

export const updateCampaignInActionPostsList = (campaignId, newList) => {
  return (dispatch, getState, getFirestore, getFirebase) => {
    const campaignInActionPosts = getState().campaign.campaignInActionPosts;
    dispatch({
      type: "SET_CAMPAIGIN_IN_ACTION_ALL_POSTS_OF_CAMPAIGN",
      payload: { ...campaignInActionPosts, [campaignId]: newList },
    });
  };
};

export const publishCampaignInActionPost = (post) => {
  return async (dispatch, getState, { getFirebase }) => {
    const functions = getFirebase().functions();
    dispatch({
      type: "SET_CAMPAIGN_IN_ACTION_CREATE_UPDATE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const publishCampaignInActionPost = functions.httpsCallable(
        "publishCampaignInActionPost"
      );
      const result = await publishCampaignInActionPost(post);
      const response = result.data;
      checkForErrors(response);
      dispatch({
        type: "SET_CAMPAIGN_IN_ACTION_CREATE_UPDATE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAMPAIGN_IN_ACTION_CREATE_UPDATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetPublishCampaignLoading = () => ({
  type: "SET_CAMPAIGN_IN_ACTION_CREATE_UPDATE_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const uploadTransactionToCampaignOrCauseProject = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_CAMPAIGN_CAUSE_PROJECT_UPLOAD_TRANSACTION_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const uploadCampaignCauseProjectTransactions = firebase
        .functions()
        .httpsCallable(
          "paymentFunctions-uploadCampaignCauseProjectTransactions"
        );
      const response = await uploadCampaignCauseProjectTransactions(data);
      const result = response.data;
      checkForErrors(result);
      console.log(result);
      dispatch({
        type: "SET_CAMPAIGN_CAUSE_PROJECT_UPLOAD_TRANSACTION_LOADING",
        payload: {
          loading: false,
          success: true,
          error: null,
          errors: result.errors,
          anyError: result.anyError,
        },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAMPAIGN_CAUSE_PROJECT_UPLOAD_TRANSACTION_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetCampaignCauseProjectUploadTransactionLoading = () => ({
  type: "SET_CAMPAIGN_CAUSE_PROJECT_UPLOAD_TRANSACTION_LOADING",
  payload: { loading: false, success: false, error: null },
});
