import React from "react";

import MobSidebar from "../../components/sidebar/mobile_sidebar.component";
import SideBar from "../../components/sidebar/sidebar.component";
import SystemAdminSidebar from "../../components/systemAdmin-sideBar-component/systemAdmin-sidebar.component";

import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import PublicPageHeader from "../../components/public-page-header/public-page-header.component";
import {
  authPagesWithNoNavBarsList,
  unAuthPublicPagesWithHeaderNavbarList,
} from "../../utils/authPagesListByGroups";
import AdminMenuBar from "../../components/admin-menu-bar/admin-menu-bar.component";
import { getValueFromUrl } from "../../utils/linkFunctions";

const shouldShowNavbar = (location, comparableArray) => {
  const params = location.pathname.split("/");

  const compareableParam = params[1];
  if (comparableArray.includes(compareableParam)) {
    return false;
  } else {
    return true;
  }
};

const shouldShowNavBarsOrNotForUser = (user) => {
  const { userContributionData, subscription, authenticated, explorePlatform } =
    user;
  const previouslySubscribed = !!userContributionData;

  if (
    authenticated &&
    (subscription || previouslySubscribed || explorePlatform)
  ) {
    return true;
  } else {
    return false;
  }
};

const ApplicationSideBars = () => {
  const location = useLocation();
  const biggerThan768 = useMediaPredicate("(min-width: 769px)");

  const currentUser = useSelector((state) => state.user.currentUser);

  const { role, authenticated, verified } = currentUser;

  const searchValues = getValueFromUrl(location.search, "header");

  return searchValues === "no-header" ? (
    <></>
  ) : (
    {
      admin: verified && <AdminMenuBar />,
      "system-admin": verified && <SystemAdminSidebar />,
      undefined:
        shouldShowNavbar(location, authPagesWithNoNavBarsList) &&
        shouldShowNavBarsOrNotForUser(currentUser) ? (
          biggerThan768 ? (
            <SideBar />
          ) : (
            <MobSidebar menuOption={true} />
          )
        ) : (
          !shouldShowNavbar(location, unAuthPublicPagesWithHeaderNavbarList) &&
          (!authenticated ? <PublicPageHeader /> : <></>)
        ),
    }[role]
  );
};

export default ApplicationSideBars;
