const initialState = {
  campaign: false,
  allCampaigns: [],
  campaignData: [],
  activeCampaignsList: [],
  activeCampaignsListIndexed: {},
  archivedCampaignsList: [],
  activeCauseProjects: [],
  inactiveCauseProjects: [],
  archivedCauseProjects: [],
  campaignsListLoading: { loading: false, error: null, success: false },
  campaignDraftData: [],
  campaignDrafts: [],
  campaignCreationLoading: {
    loading: false,
    error: null,
    success: false,
  },
  allCauseProjects: [],
  causeProjectsById: {},
  causeProjectListLoading: {
    loading: false,
    error: null,
    success: false,
  },
  campaignDataLoading: { loading: false, error: null, success: false },
  causeProjectUpdateLoading: { loading: false, error: null, success: false },
  campaignDataDraftLoading: { loading: false, error: null, success: false },
  campaignDraftsLoading: { loading: false, error: null, success: false },
  campaignDataDelete: false,
  sendEmailContributorsLoading: { loading: false, success: false, error: null },
  inActiveCampaignsList: [],
  allCampaignDataLoading: { loading: false, error: null, success: false },
  deleteDraftCampaignLoading: { loading: false, error: null, success: false },
  optOutCampaignLoading: { loading: false, error: null, success: false },
  campaignInActionPosts: {},
  campaignInActionPostsLoading: { loading: false, error: null, success: false },
  campaignCauseProjectUploadTransactionLoading: {
    loading: false,
    error: null,
    success: false,
  },
  campaignInActionCreateUpdateLoading: {
    loading: false,
    error: null,
    success: false,
  },
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CAMPAIGN_BOOLEAN":
      return { ...state, campaign: action.payload };
    case "SET_CAMPAIGN_DATA":
      return { ...state, campaignData: action.payload };
    case "SET_CAMPAIGN_DATA_LOADING":
      return { ...state, campaignDataLoading: action.payload };
    case "UPDATE_CAUSE_PROJECT_DATA_LOADING":
      return { ...state, causeProjectUpdateLoading: action.payload };
    case "SET_ALL_CAMPAIGNS":
      return { ...state, allCampaigns: action.payload };
    case "SET_ALL_CAMPAIGN_DATA_LOADING":
      return { ...state, allCampaignDataLoading: action.payload };
    case "SET_CAMPAIGN_CREATION_LOADING":
      return { ...state, campaignCreationLoading: action.payload };
    case "SET_CAUSE_PROJECT_LIST_LOADING":
      return { ...state, causeProjectListLoading: action.payload };
    case "SET_ACTIVE_CAUSE_PROJECTS":
      return { ...state, activeCauseProjects: action.payload };
    case "SET_INACTIVE_CAUSE_PROJECTS":
      return { ...state, inactiveCauseProjects: action.payload };
    case "SET_ARCHIVED_CAUSE_PROJECTS":
      return { ...state, archivedCauseProjects: action.payload };
    case "SET_ALL_CAUSE_PROJECTS":
      return { ...state, allCauseProjects: action.payload };
    case "SET_ALL_CAUSE_PROJECTS_BY_ID":
      return { ...state, causeProjectsById: action.payload };
    case "SET_CAMPAIGN_DRAFT_DATA":
      return { ...state, campaignDraftData: action.payload };
    case "SET_CAMPAIGN_DRAFT_DATA_LOADING":
      return { ...state, campaignDataDraftLoading: action.payload };
    case "SET_ALL_CAMPAIGN_DRAFTS":
      return { ...state, campaignDrafts: action.payload };
    case "SET_ALL_CAMPAIGN_DRAFTS_LOADING":
      return { ...state, campaignDraftsLoading: action.payload };
    case "SET_CAMPAIGN_DELETE_DATA":
      return { ...state, campaignDataDelete: action.payload };
    case "SET_ACTIVE_CAMPAIGNS_LIST":
      return { ...state, activeCampaignsList: action.payload };
    case "SET_ACTIVE_CAMPAIGNS_LIST_INDEXED":
      return { ...state, activeCampaignsListIndexed: action.payload };
    case "SET_ARCHIVED_CAMPAIGNS_LIST":
      return { ...state, archivedCampaignsList: action.payload };
    case "SET_IN_ACTIVE_CAMPAIGNS_LIST":
      return { ...state, inActiveCampaignsList: action.payload };
    case "SET_CAMPAIGNS_LIST_LOADING":
      return { ...state, campaignsListLoading: action.payload };
    case "SET_SEND_EMAIL_CONTRIBUTORS_LOADING":
      return { ...state, sendEmailContributorsLoading: action.payload };
    case "SET_DELETE_DRAFT_CAMPAIGN_LOADING":
      return { ...state, deleteDraftCampaignLoading: action.payload };
    case "OPT_OUT_OF_CAMPAIGN_LOADING":
      return { ...state, optOutCampaignLoading: action.payload };
    case "FETCH_CAMPAIGN_ALL_CAMPAIGN_IN_ACTION_POSTS_LOADING":
      return { ...state, campaignInActionPostsLoading: action.payload };
    case "SET_CAMPAIGIN_IN_ACTION_ALL_POSTS_OF_CAMPAIGN":
      return { ...state, campaignInActionPosts: action.payload };
    case "SET_CAMPAIGN_IN_ACTION_CREATE_UPDATE_LOADING":
      return { ...state, campaignInActionCreateUpdateLoading: action.payload };
    case "SET_CAMPAIGN_CAUSE_PROJECT_UPLOAD_TRANSACTION_LOADING":
      return {
        ...state,
        campaignCauseProjectUploadTransactionLoading: action.payload,
      };
    default:
      return state;
  }
};

export default campaignReducer;
