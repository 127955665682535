import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import firebase, { addUserNameEmailOAuth } from "./firebase/firebaseConfig";
import { checkSignInMethodAndUpdateRedux } from "./utils/authFunctions";
import Layout from "./layout/layout.component";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import {
  setCurrentUser,
  signOutAction,
  updateUserUploadLoading,
} from "./redux/auth/auth.action";

import {
  addSnapshotsListners,
  setupApplicationAndDatabases,
} from "./redux/app-settings/app-settings.action";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./_variable.scss";
import SiteUnderConstructionPage from "./pages/site-under-construction/site-under-construction.page";

const env = process.env.REACT_APP_ENVIRONMENT;
const title = process.env.REACT_APP_WEB_TITLE;
const construction =
  process.env.REACT_APP_UNDER_CONSTRUCTION === "yes" ? true : false;
console.log(env);

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useSelector((state) => state.user);
  const signOutClickHandler = async () => {
    dispatch(signOutAction());
    history.push("./login");
  };

  const handleOnIdle = (event) => {
    console.log("user is idle", event);
    console.log("last active", getTotalIdleTime());
    if (env === "prod" && currentUser.authenticated) signOutClickHandler();
  };

  const { getTotalIdleTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
    debounce: 500,
  });

  useEffect(() => {
    document.title = title;
    firebase.auth().onAuthStateChanged(async (userAuth) => {
      try {
        if (userAuth) {
          dispatch(updateUserUploadLoading(false));
          const userRef = await addUserNameEmailOAuth(userAuth);
          await checkSignInMethodAndUpdateRedux(dispatch);
          dispatch(updateUserUploadLoading(true));

          let userSnapshot = userRef.onSnapshot(async (snapShot) => {
            const data = snapShot.data() ? snapShot.data() : {};
            const subscriptionData = data.subscriptionData;
            const subscription = subscriptionData ? true : false;
            dispatch(
              setCurrentUser({
                uid: userAuth.uid,
                verified: userAuth.emailVerified,
                authLoaded: true,
                authenticated: true,
                subscription: subscription,
                providerData: userAuth.providerData,
                photoUrl: userAuth.photoURL,
                ...data,
              })
            );
          });
          dispatch(
            addSnapshotsListners({
              type: "user-snapshot",
              snapshot: userSnapshot,
            })
          );
        } else {
          dispatch(
            setCurrentUser({
              email: "",
              displayName: "",
              authLoaded: true,
              authenticated: false,
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (currentUser.authLoaded) {
      const callingQLDB = async () => {
        try {
          dispatch(setupApplicationAndDatabases());
        } catch (err) {
          console.log(err.message);
        }
      };
      callingQLDB()
        .then(() => {})
        .catch((err) => console.log(err));
    }
  }, [currentUser.authLoaded, dispatch]);

  return (
    <Router>
      <div className="App" id="App">
        <div className="main-app-container">
          {construction ? (
            <SiteUnderConstructionPage />
          ) : (
            <Switch>
              <Route path="/" render={() => <Layout />} />
            </Switch>
          )}
        </div>
      </div>
    </Router>
  );
}

export default App;
