import React from "react";

import "./error-message.styles.scss";

const ErrorMessageAtom = ({ message, absolute }) => {
  return (
    <p className={`error-message-atom ${absolute ? "position-absolute" : ""}`}>
      {message}
    </p>
  );
};

export default ErrorMessageAtom;
