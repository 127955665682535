const initialState = {
  pastTransactionsData: [],
  lastTransactionsData: {},
  causeProjectsDonationData: [],
  emptyTransactionsData: false,
  pastTransactionsDataLoading: { loading: false, success: false, error: null },
  causeProjectsDonationDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  individualBalance: [],
  individualBalanceLoading: { loading: false, success: false, error: null },
  addFundsOnSubscription: false,
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAST_TRANSACTIONS_DATA":
      return { ...state, pastTransactionsData: action.payload };
    case "SET_CAUSE_PROJECTS_DONATION_DATA":
      return { ...state, causeProjectsDonationData: action.payload };
    case "SET_LAST_TRANSACTIONS_DATA":
      return { ...state, lastTransactionsData: action.payload };
    case "SET_EMPTY_TRANSACTIONS_DATA":
      return { ...state, emptyTransactionsData: action.payload };
    case "SET_PAST_TRANSACTIONS_DATA_LOADING":
      return { ...state, pastTransactionsDataLoading: action.payload };
    case "SET_CAUSE_PROJECTS_DONATION_DATA_LOADING":
      return {
        ...state,
        causeProjectsDonationDataLoading: action.payload,
      };
    case "SET_PAST_INDIVIDUAL_BALANCE":
      return { ...state, individualBalance: action.payload };

    case "SET_PAST_INDIVIDUAL_BALANCE_LOADING":
      return { ...state, individualBalanceLoading: action.payload };

    case "SET_ADD_FUNDS_ON_SUBSCRIPTION":
      return { ...state, addFundsOnSubscription: action.payload };
    default:
      return state;
  }
};
export default billingReducer;
