export const getCauseLabelFromCampaignData = (campaign) => {
  const causesArray = campaign.causes ? campaign.causes : [];
  const causeIndexed = causesArray[0] ? causesArray[0] : "";
  const cause = causeIndexed ? causeIndexed.label : "N/A";
  return cause;
};
export const getCauseValueFromCampaignData = (campaign) => {
  const causesArray = campaign.causes ? campaign.causes : [];
  const causeIndexed = causesArray[0] ? causesArray[0] : "";
  const cause = causeIndexed ? causeIndexed.value : "N/A";
  return cause;
};

export const getCauseTitleFromCauses = (causeId, causeData) => {
  const causes = causeData.filter((item) => item.id === causeId);
  return causes[0].title;
};

export const getCauseFromCauseProject = (causeProjects, causeId) => {
  for (let i in causeProjects) {
    const item = causeProjects[i];
    if (item.campaignId === causeId) {
      return item.causes[0].value;
    }
  }
};

export const getdefaultHiddenColumnsName = (causeData) => {
  let hiddenColumnsDefault = [];
  for (let i in causeData) {
    const item = causeData[i];
    if (item.status === "active") {
      hiddenColumnsDefault.push(item.title + " Balance");
    }
  }
  return hiddenColumnsDefault;
};

export const getCauseTitles = (causeData) => {
  let hiddenColumnsDefault = [];
  for (let i in causeData) {
    const item = causeData[i];
    if (item.status === "active") {
      hiddenColumnsDefault.push({
        title: item.title,
        active: false,
        id: item.id,
      });
    }
  }
  return hiddenColumnsDefault;
};

export const getCausesIds = (causeData) => {
  let hiddenColumnsDefault = [];
  for (let i in causeData) {
    const item = causeData[i];
    if (item.status === "active") {
      hiddenColumnsDefault.push(item.id);
    }
  }
  return hiddenColumnsDefault;
};

export const getCauseUnit = (causeId, causes) => {
  const cause = causes.find((item) => item.id === causeId);
  return cause ? cause.unit : "";
};
