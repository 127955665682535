import { unAuthPublicPagesWithHeaderNavbarList } from "./authPagesListByGroups";

export const checkPublicPagesWithHeaderClass = (user) => {
  const { authenticated } = user;
  const location = window.location;
  const params = location.pathname.split("/");
  const compareableParam = params[1];
  if (
    unAuthPublicPagesWithHeaderNavbarList.includes(compareableParam) &&
    !authenticated
  ) {
    return true;
  } else {
    return false;
  }
};
