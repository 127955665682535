import React from "react";
import useBrandInfo from "../../custom-hooks/useGetBrandInfoProperties";

import "./menu-bar-logo.styles.scss";

const MenuBarLogo = () => {
  const logo = useBrandInfo("logo");
  const websiteName = useBrandInfo("websiteName");
  return (
    <div className="menu-bar-logo-component">
      <img src={logo} alt={websiteName} loading="lazy" />
    </div>
  );
};

export default MenuBarLogo;
