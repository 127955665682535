// import { firestore } from "firebase-admin";
// import { getFirestore } from "redux-firestore";
// import db from "../../firebase/firebaseConfig";
import { getCauseTitleFromCauses } from "../../utils/causesFunctions";
import { getDateInYYYYMMDDFormat } from "../../utils/dateFunctions";
import { floatValue } from "../../utils/floatValue";
import { getCauseTitleFromCMS } from "../../utils/getDataFromApplicationObjects";
import { getServiceBasedGroupedData, groupBy } from "../../utils/groupArrays";
import { addSnapshotsListners } from "../app-settings/app-settings.action";
// import { sortArrayofObjectByValue } from "../../utils/sortFunctions";

export const setAllUserQueryLength = (value) => ({
  type: "SET_ALL_USER_QUERY_LENGTH",
  payload: value,
});

export const setSeeAllUsers = (value) => ({
  type: "SET_SEE_ALL_USERS",
  payload: value,
});
export const setFilterIsChanged = (value) => ({
  type: "SET_FILTER_IS_CHANGED",
  payload: value,
});
export const setCauseBasedActiveTag = (value) => ({
  type: "SET_CAUSE_BASED_ACTIVE_TAG",
  payload: value,
});
export const setServiceBasedFilters = (value) => ({
  type: "SET_SERVICE_BASED_FILTERS",
  payload: value,
});

export const setTransactionGroupByChecked = (value) => ({
  type: "SET_TRANSACTION_GROUP_BY_CHECKED",
  payload: value,
});
export const setServiceGroupByChecked = (value) => ({
  type: "SET_SERVICE_GROUP_BY_CHECKED",
  payload: value,
});
export const setSearchValueForUserProfile = (value) => ({
  type: "SET_SEARCH_VALUE_FOR_USER_PROFILE",
  payload: value,
});
export const serviceBasedDateSelector = (value) => ({
  type: "SET_SERVICE_BASED_DATE_SELECTOR",
  payload: value,
});
export const projectBasedDateSelector = (value) => ({
  type: "SET_PROJECT_BASED_DATE_SELECTOR",
  payload: value,
});
export const transactionBasedDateSelector = (value) => ({
  type: "SET_TRANSACTION_BASED_DATE_SELECTOR",
  payload: value,
});
export const setCauseBasedDateSelected = (value) => ({
  type: "SET_CAUSE_DATE_FILTER_SELECTED",
  payload: value,
});
export const setProjectBasedDateSelected = (value) => ({
  type: "SET_PROJECT_DATE_FILTER_SELECTED",
  payload: value,
});
export const setTransactionBasedFilterSelected = (value) => ({
  type: "SET_TRANSACTION_DATE_FILTER_SELECTED",
  payload: value,
});
const getTotalTicketCountAndAmount = (userDonationByEvent) => {
  let obj = { amount: 0, count: 0 };
  for (let i in userDonationByEvent) {
    const item = userDonationByEvent[i];
    obj.amount = obj.amount + item.amount;
    obj.count = obj.count + item.totalTickets;
  }
  return obj;
};
export const fetchAllUsers = (seeAll, pageNumberPagination) => {
  return async (dispatch, getState, { getFirestore }) => {
    const basicBrandInfo = getState().InfauqeInstance.basicBrandInfo;
    const causesDataFirebase = getState().causes.causesDataFirebase;
    dispatch({
      type: "FETCH_ALL_USERS_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const userCollectionRef = firestore.collection("users");

    try {
      let allUsersQueryRef = userCollectionRef.orderBy("createdAt", "desc");

      const response = await allUsersQueryRef.get();
      const allUsersArray = [];
      const usersPromisesArray = [];
      const allUserIndexedArray = [];

      if (response.docs.length) {
        for (const doc of response.docs) {
          const ref = doc.ref;
          const data = doc.data();
          const statusDate =
            data.subscriptionData &&
            new Date(
              data.subscriptionData.timestamp
                ? data.subscriptionData.timestamp
                : data.subscriptionData.timeValue
            ).toDateString();

          const totalCauseContribution = floatValue(
            data.userContributionData
              ? data.userContributionData.totalContribution
                ? data.userContributionData.totalContribution
                : 0
              : 0
          );
          const totalCampaignContribution = floatValue(
            data.userContributionData
              ? data.userContributionData.totalCampaignContribution
                ? data.userContributionData.totalCampaignContribution
                : 0
              : 0
          );

          const totalDisbursement = floatValue(
            data.userContributionData
              ? data.userContributionData.totalDisbursement
                ? data.userContributionData.totalDisbursement
                : 0
              : 0
          );

          const totalContribution =
            totalCampaignContribution + totalCauseContribution;

          const remainingBalance = floatValue(
            totalCauseContribution - totalDisbursement
          );
          const finalStatusDate = statusDate
            ? new Date(statusDate).toISOString().split("T")[0]
            : statusDate;

          const Status = !data.userContributionData ? "Potential" : data.status;
          const Subscribed = data.followedCauses
            ? data.followedCauses.join(" ")
            : "";

          const source = data.source ? data.source : "-";
          const sourceType = data.sourceType ? data.sourceType : "-";
          const subsribedAmount =
            Status === "subscribed" && data.subscriptionData
              ? data.subscriptionData.contribution
              : "-";

          const oneTimePaymentPromise = getUserLastOneTimePayment(ref);
          const lastPaymentDatePromise = getUserLastPaymentDate(ref);

          const individualBalances = getUserIndividualBalances(
            ref,
            causesDataFirebase
          );
          const lastLoginTime = data.lastSignInTime ? data.lastSignInTime : "-";
          const userPromises = Promise.all([
            oneTimePaymentPromise,
            lastPaymentDatePromise,
            individualBalances,
          ]);

          usersPromisesArray.push(userPromises);

          const finalDoc = {
            id: doc.id,
            Name: data.displayName,
            email: data.email,
            oneTime: null,
            "One Time Date": null,
            Status,
            "Status Date": finalStatusDate,
            [`Followed ${getCauseTitleFromCMS(basicBrandInfo)}`]: Subscribed,
            "Total Contribution": totalContribution,
            "Wallet Balance": remainingBalance,
            "Subscribed Amount": subsribedAmount,

            tip: data.subscriptionData ? data.subscriptionData.tip : "",
            role: data.role ? data.role : "",
            createdAt: data.createdAt,
            "Last Login On": lastLoginTime !== "-" ? lastLoginTime : "-",
            Source: source,
            "Source Type": sourceType,
          };
          allUsersArray.push(finalDoc);
          allUserIndexedArray[doc.id] = finalDoc;
        }
        const userPromisesResponses = await Promise.all(usersPromisesArray);
        for (const index in allUsersArray) {
          const userResponse = userPromisesResponses[index];
          const oneTimePayment = userResponse[0];
          const lastPaymentDate = userResponse[1];
          const individualBalance = userResponse[2];

          allUsersArray[index].oneTime = oneTimePayment ? "Yes" : "No";
          allUsersArray[index]["One Time Date"] = oneTimePayment
            ? oneTimePayment
            : lastPaymentDate
            ? lastPaymentDate
            : "-";
          for (let i in individualBalance.userIndividualBalance) {
            const item = individualBalance.userIndividualBalance[i];
            allUsersArray[index][i] = floatValue(item);
          }
        }
      }

      dispatch({
        type: "SET_ALL_USERS_DATA",
        payload: allUsersArray,
      });
      dispatch({
        type: "SET_ALL_USERS_UID_INDEXED_ARRAY",
        payload: allUserIndexedArray,
      });

      dispatch({
        type: "FETCH_ALL_USERS_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ALL_USERS_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
export const fetchAllUsersCauseProject = (seeAll, pageNumberPagination) => {
  return async (dispatch, getState, { getFirestore }) => {
    const causesDataFirebase = getState().causes.causesDataFirebase;
    dispatch({
      type: "FETCH_ALL_USERS_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    const userCollectionRef = firestore.collection("users");

    try {
      let allUsersQueryRef = userCollectionRef.orderBy("createdAt", "desc");

      const response = await allUsersQueryRef.get();
      const allUsersArray = [];
      const usersPromisesArray = [];
      const allUserIndexedArray = {};
      let adminCount = 0;
      let oneTimeCount = 0,
        subscriberCount = 0,
        unsubcriberCount = 0,
        potentialCount = 0;
      if (response.docs.length) {
        for (const doc of response.docs) {
          const ref = doc.ref;
          const data = doc.data();
          const statusDate = data.lastStausUpdateTime
            ? getDateInYYYYMMDDFormat(data.lastStausUpdateTime)
            : !data.status
            ? getDateInYYYYMMDDFormat(data.createdAt)
            : "-";

          const totalCauseContribution = floatValue(
            data.userContributionData
              ? data.userContributionData.totalContribution
                ? data.userContributionData.totalContribution
                : 0
              : 0
          );
          const totalCampaignContribution = floatValue(
            data.userContributionData
              ? data.userContributionData.totalCampaignContribution
                ? data.userContributionData.totalCampaignContribution
                : 0
              : 0
          );
          const totalEventContribution = floatValue(
            data.userContributionData
              ? data.userContributionData.totalEventContribution
                ? data.userContributionData.totalEventContribution
                : 0
              : 0
          );

          const totalContribution = floatValue(
            totalCampaignContribution +
              totalCauseContribution +
              totalEventContribution
          ).toFixed(2);

          const Status = !data.userContributionData ? "Potential" : data.status;
          if (Status === "one-time") {
            oneTimeCount = oneTimeCount + 1;
          } else if (Status === "subscribed") {
            subscriberCount = subscriberCount + 1;
          } else if (Status === "unsubscribed") {
            unsubcriberCount = unsubcriberCount + 1;
          } else {
            if (!data.userContributionData) {
              potentialCount = potentialCount + 1;
            } else if (!Status && data.userContributionData) {
              oneTimeCount = oneTimeCount + 1;
            }
          }
          if (data.role && data.role === "admin") {
            adminCount = adminCount + 1;
          }
          const source = data.source ? data.source : "-";
          const sourceType = data.sourceType ? data.sourceType : "-";
          const subsribedAmount =
            Status === "subscribed" && data.subscriptionData
              ? floatValue(data.subscriptionData.contribution).toFixed(2)
              : "-";
          const ticketPurchasedCount = data.userDonationByEvent
            ? getTotalTicketCountAndAmount(data.userDonationByEvent).count
            : "-";
          const ticketPurchasedAmount = data.userDonationByEvent
            ? floatValue(
                getTotalTicketCountAndAmount(data.userDonationByEvent).amount
              ).toFixed(2)
            : "-";

          const oneTimePaymentPromise = getUserLastOneTimePaymentCauseProject(
            firestore,
            doc.id
          );

          const individualBalances = getUserIndividualBalances(
            ref,
            causesDataFirebase
          );
          const lastLoginTime = data.lastSignInTime ? data.lastSignInTime : "-";
          const userPromises = Promise.all([
            oneTimePaymentPromise,
            individualBalances,
          ]);

          usersPromisesArray.push(userPromises);

          const finalDoc = {
            id: doc.id,
            Name: data.displayName,
            email: data.email,
            oneTime: null,
            "One Time Date": null,
            Status,
            "Status Date": statusDate,
            "Total Contribution": totalContribution,
            "Subscribed Amount": subsribedAmount,
            tip: data.subscriptionData
              ? floatValue(data.subscriptionData.tip).toFixed(2)
              : "",
            "Tickets purchased": ticketPurchasedCount,
            "Tickets amount": ticketPurchasedAmount,
            role: data.role ? data.role : "",

            createdAt: data.createdAt,
            "Last Login On": lastLoginTime !== "-" ? lastLoginTime : "-",
            Source: source,
            "Source Type": sourceType,
          };
          allUsersArray.push(finalDoc);
          allUserIndexedArray[doc.id] = finalDoc;
        }
        const userPromisesResponses = await Promise.all(usersPromisesArray);
        for (const index in allUsersArray) {
          const userResponse = userPromisesResponses[index];
          const oneTimePayment = userResponse[0];
          const individualBalance = userResponse[1];

          allUsersArray[index].oneTime = oneTimePayment ? "Yes" : "No";
          allUsersArray[index]["One Time Date"] = oneTimePayment
            ? oneTimePayment
            : "-";
          for (let i in individualBalance.userIndividualBalance) {
            const item = individualBalance.userIndividualBalance[i];
            allUsersArray[index][i] = floatValue(item);
          }
        }
      }
      const userTypes = [
        { title: "Subscribed Users", value: subscriberCount },
        { title: "Unsubscribed Users", value: unsubcriberCount },
        { title: "Potential Users", value: potentialCount },
        { title: "One Time Paid Users", value: oneTimeCount },
        { title: "Admin Users", value: adminCount },
        { title: "Total Users", value: response.docs.length },
      ];
      dispatch({
        type: "SET_USERS_TYPE",
        payload: userTypes,
      });
      dispatch({
        type: "SET_ALL_USERS_DATA",
        payload: allUsersArray,
      });
      dispatch({
        type: "SET_ALL_USERS_UID_INDEXED_ARRAY",
        payload: allUserIndexedArray,
      });

      dispatch({
        type: "FETCH_ALL_USERS_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ALL_USERS_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const saveFilterToDataBase = (data, id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "SAVE_FILTER_TO_DATABASE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const saveFiltersToDatabase = firebase
        .functions()
        .httpsCallable("adminFunctions-saveFiltersToDatabase");
      const newData = { data, id };
      const response = await saveFiltersToDatabase(newData);
      dispatch({
        type: "SAVE_FILTER_TO_DATABASE_LOADING",
        payload: {
          loading: false,
          success: response.data.success,
          error: response.data.error,
        },
      });
    } catch (err) {
      dispatch({
        type: "SAVE_FILTER_TO_DATABASE_LOADING",
        payload: { loading: true, success: false, error: err.message },
      });
      console.log(err.message);
    }
  };
};
export const getFilterFromDatabase = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const db = getFirestore();
    const { success } = getState().allUsers.fetchFilterfromDataBaseLoading;
    try {
      if (success) {
        return;
      }

      const allUsersFiltersSnapshot = db
        .collection("filters")
        .onSnapshot((result) => {
          dispatch({
            type: "FETCH_FILTER_TO_DATABASE_LOADING",
            payload: { loading: true, success: false, error: null },
          });
          let finalData = [];
          if (!result.empty) {
            for (let i in result.docs) {
              const item = result.docs[i];
              const data = item.data();
              finalData.push({ ...data, id: item.id });
            }
            dispatch({
              type: "FILTERS_FROM_DATABASE",
              payload: finalData.length ? finalData : [],
            });
          }
          dispatch({
            type: "FETCH_FILTER_TO_DATABASE_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
            },
          });
        });
      dispatch(
        addSnapshotsListners({
          type: "admin-all-users-filters-snapshot",
          snapshot: allUsersFiltersSnapshot,
        })
      );
    } catch (err) {
      dispatch({
        type: "FETCH_FILTER_TO_DATABASE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
      console.log(err.message);
    }
  };
};
export const deleteFilterFromDatabase = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: "DELETE_FILTER_TO_DATABASE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const deleteFiltersFromDatabase = firebase
        .functions()
        .httpsCallable("adminFunctions-deleteFiltersFromDatabase");
      const result = await deleteFiltersFromDatabase({ id: data });
      if (result.data.success) {
        window.location.reload();
      }
      dispatch({
        type: "DELETE_FILTER_TO_DATABASE_LOADING",
        payload: {
          loading: false,
          success: true,
          error: null,
        },
      });
    } catch (err) {
      dispatch({
        type: "DELETE_FILTER_TO_DATABASE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
      console.log(err.message);
    }
  };
};

export const setSelectedList = (check, value) => {
  return async (dispatch, getState, { getFirestore }) => {
    let currentList = getState().allUsers.selectedList;
    try {
      let selectedListNew = [...currentList];
      if (check) {
        selectedListNew.push(value);
      } else {
        const element = selectedListNew.find(function (element) {
          return element.id === value.id;
        });

        const index = selectedListNew.indexOf(element);
        selectedListNew.splice(index, 1);
      }

      dispatch({
        type: "SET_SELECTED_LIST",
        payload: selectedListNew,
      });
    } catch (err) {
      console.log(err.message);
    }
  };
};
export const setUserPreferences = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_PREFERENCES_LOADING",
      payload: { loading: false, success: false, error: false },
    });

    try {
      const setEmailPreferences = firebase
        .functions()
        .httpsCallable("userFunctions-setEmailPreferences");

      const response = await setEmailPreferences(data);

      if (response.data.success) {
        dispatch({
          type: "SET_PREFERENCES_USER",
          payload: response.data.email,
        });
        dispatch({
          type: "SET_PREFERENCES_LOADING",
          payload: { loading: false, success: true, error: false },
        });
      } else {
        dispatch({
          type: "SET_PREFERENCES_LOADING",
          payload: {
            loading: true,
            success: false,
            error: response.data.message,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "SET_PREFERENCES_LOADING",
        payload: { loading: true, success: false, error: err.message },
      });
      console.log(err.message);
    }
  };
};

export const sendAdhocEmailToUsers =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();

    try {
      let response;
      dispatch({
        type: "SEND_EMAIL_FOR_ALL_USERS_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      const sendAdhocEmails = firebase
        .functions()
        .httpsCallable("adminFunctions-sendAdhocEmailToUsers");

      response = await sendAdhocEmails(data);
      console.log("response", response, data);
      if (response.data.success) {
        dispatch({
          type: "SEND_EMAIL_FOR_ALL_USERS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SEND_EMAIL_FOR_ALL_USERS_LOADING",
          payload: {
            loading: false,
            success: false,
            error: response.data.errorMessage,
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SEND_EMAIL_FOR_ALL_USERS_LOADING",
        payload: { loading: true, success: false, error: err.message },
      });
    }
  };

export const fetchServiceBasedData =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const currentDates = getState().allUsers.serviceBasedDateSelected;
    try {
      if (
        data.startDate !== new Date(currentDates.startDate).getTime() ||
        data.endDate !== new Date(currentDates.endDate).getTime()
      ) {
        dispatch({
          type: "SET_SERVICE_BASED_DATA_CUSTOM_LOADING",
          payload: {
            loading: true,
            success: false,
            error: null,
            message: "fetching Data",
          },
        });
        const db = getFirestore();
        const allUserIndexed = getState().allUsers.allUserUidIndexedArray;
        const contributionReference = await db
          .collectionGroup("Contribution")
          .where("TimeValue", ">=", data.startDate)
          .where("TimeValue", "<=", data.endDate)
          .get();
        let dataService = [];
        for (let i in contributionReference.docs) {
          const item = contributionReference.docs[i];
          dataService.push(item.data());
        }
        const groupedData = groupBy(dataService, "UID");
        const finalData = getServiceBasedGroupedData(
          groupedData,
          allUserIndexed
        );

        dispatch({
          type: "SET_SERVICE_BASED_DATA_CUSTOM",
          payload: finalData,
        });
        dispatch({
          type: "SET_SERVICE_BASED_DATA_CUSTOM_LOADING",
          payload: {
            loading: false,
            success: true,
            error: null,
            message: finalData.length ? "" : "No Data",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_SERVICE_BASED_DATA_CUSTOM_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
          message: "",
        },
      });
    }
  };

export const fetchServiceBasedDataByYear =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "SET_SERVICE_BASED_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const db = getFirestore();
      const allUserIndexed = getState().allUsers.allUserUidIndexedArray;
      const contributionReference = await db
        .collectionGroup("causes_data")
        .get();
      let uidsToGetData = [];
      if (!contributionReference.empty) {
        let finalData = [];
        for (let i in contributionReference.docs) {
          const item = contributionReference.docs[i];
          const data = item.data();
          const Obj = {
            Cause: data.cause,
            Contribution: floatValue(data.totalContributed),
            Allocated: floatValue(data.totalDisbursed),
            "Remaining Balance": floatValue(
              data.totalContributed - data.totalDisbursed
            ),
            Year: data.year,
          };
          finalData.push(Obj);
          uidsToGetData.push(data.uid);
        }
        for (let i in uidsToGetData) {
          const item = uidsToGetData[i];
          const data = allUserIndexed[item];
          finalData[i]["Name"] = data.Name ? data.Name : "";
          finalData[i]["email"] = data.email;
          finalData[i]["Status"] = data.Status;
          finalData[i]["id"] = data.id;
        }
        dispatch({
          type: "SET_SERVICE_BASED_DATA",
          payload: finalData,
        });
      }
      dispatch({
        type: "SET_SERVICE_BASED_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_SERVICE_BASED_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
        },
      });
    }
  };

export const fetchTotalCashFlowAllUsers =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const currentDates = getState().allUsers.transactionBasedDateSelected;
    try {
      if (
        data.time.startTime !== new Date(currentDates.startDate).getTime() ||
        data.time.endTime !== new Date(currentDates.endDate).getTime()
      ) {
        dispatch({
          type: "SET_TOTAL_CASH_FLOW_LOADING",
          payload: {
            loading: true,
            success: false,
            error: null,
            message: "Fetching Data",
          },
        });
        const fetchTotalCashFlowData = firebase
          .functions()
          .httpsCallable("adminFunctions-fetchTotalCashFlowData");
        const response = await fetchTotalCashFlowData(data);
        if (response.data.success) {
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_DATA",
            payload: response.data.totalCashFlow,
          });

          dispatch({
            type: "SET_TOTAL_CASH_FLOW_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              message: response.data.totalCashFlow.length ? "" : "No data",
            },
          });
        } else {
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_LOADING",
            payload: {
              loading: false,
              success: false,
              error: response.data.message,
              message: "",
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
const calculateTip = (contribution, configurations) => {
  const tips = configurations
    ? configurations.tips
    : { tipPercentage: 0, tipAmount: 0 };
  const tip = contribution
    ? contribution * (tips.tipPercentage / 100) + tips.tipAmount
    : 0;
  return tip;
};
export const fetchTotalCashFlowAllUsersCauseProject =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const allUserUidIndexedArray = getState().allUsers.allUserUidIndexedArray;
    const campaignsListIndexed = getState().campaign.activeCampaignsListIndexed;
    const causeProjectsById = getState().campaign.causeProjectsById;
    const eventsById = getState().events.activeEventsIndexed;
    const currentDates = getState().allUsers.transactionBasedDateSelected;

    try {
      if (
        data.time.startTime !== new Date(currentDates.startDate).getTime() ||
        data.time.endTime !== new Date(currentDates.endDate).getTime()
      ) {
        dispatch({
          type: "SET_TOTAL_CASH_FLOW_LOADING",
          payload: {
            loading: true,
            success: false,
            error: null,
            message: "Fetching Data",
          },
        });
        const ref = firebase
          .firestore()
          .collection("Donations")
          .orderBy("TimeValue", "desc");
        const refRes = await ref.get();
        const campaignRefPromises = [];
        if (!refRes.empty) {
          const finalResult = [];
          for (let i in refRes.docs) {
            const item = refRes.docs[i];
            const data = item.data();
            if (!data.type) {
              const campaignDonationRef = firebase
                .firestore()
                .collection("CampaignDonations")
                .where("ChargeId", "==", data.ChargeId)
                .get();
              campaignRefPromises.push(campaignDonationRef);
            } else {
              const campaignDonationRef = firebase
                .firestore()
                .collection("EventTicketsProceeds")
                .where("EventId", "==", data.EventId)
                .get();
              campaignRefPromises.push(campaignDonationRef);
            }
            const userConfigServiceFee =
              data.grossServiceFeeFixedAmount && data.grossServiceFeePercentage
                ? {
                    tips: {
                      tipAmount: data.grossServiceFeeFixedAmount,
                      tipPercentage: data.grossServiceFeePercentage,
                    },
                  }
                : "";
            const finalConfig = userConfigServiceFee
              ? userConfigServiceFee
              : "";
            let advantageAmount = 0;
            if (data.type) {
              const event = eventsById[data.EventId];
              if (event.advantageAmount) {
                advantageAmount = advantageAmount + event.advantageAmount;
              }
              if (event.packages) {
                if (event.packages.length) {
                  for (let i in event.packages) {
                    const item = event.packages[i];
                    if (item.advantageAmount) {
                      advantageAmount = advantageAmount + item.advantageAmount;
                    }
                  }
                }
              }
            }
            finalResult.push({
              donationId: item.id,
              id: data.UID,
              sid: data.SID,
              reverse: data.reverse,
              ChargeId: data.ChargeId,
              "Reversed/Refunded Amount": data.reverse ? data.Total : 0,
              "Contribution Amount": !data.reverse ? data.Contribution : 0,
              timeValue: data.TimeValue,
              Donor: allUserUidIndexedArray[data.UID]
                ? allUserUidIndexedArray[data.UID].Name
                : "",
              status: allUserUidIndexedArray[data.UID]
                ? allUserUidIndexedArray[data.UID].Status
                : "",
              email: allUserUidIndexedArray[data.UID]
                ? allUserUidIndexedArray[data.UID].email
                : "",
              "Platform Charges": !data.reverse ? data.Tip : 0,
              Total: !data.reverse ? data.Total : 0,
              "Transaction source": data.type ? "Event" : "Donation",
              Source: data.Source,
              "Gross Service Fee Variable Rate": !data.reverse
                ? data.grossServiceFeePercentage
                  ? data.grossServiceFeePercentage
                  : ""
                : 0,
              "Gross Service Fee Fixed Portion": !data.reverse
                ? data.grossServiceFeeFixedAmount
                  ? data.grossServiceFeeFixedAmount
                  : ""
                : 0,
              "Gross Service Fee": !data.reverse
                ? finalConfig
                  ? calculateTip(data.Contribution, finalConfig)
                  : "-"
                : 0,
              "Gross Payment Processor Fee": data.Fee,
              "Gross Platform Usage Fee": !data.reverse
                ? finalConfig
                  ? calculateTip(data.Contribution, finalConfig) - data.Fee
                  : "-"
                : 0,
              "Donor Covered Service Fee": !data.reverse
                ? data.Tip
                  ? data.Tip
                  : 0
                : 0,
              "Donor Covered Payment Processor Fee": !data.reverse
                ? data.Tip
                  ? data.Fee
                  : 0
                : 0,
              "Donor Covered Platform Usage Fee": !data.reverse
                ? data.Tip
                  ? floatValue(data.Tip - data.Fee)
                  : 0
                : 0,
              "Fair Market Value": !data.reverse
                ? data.type
                  ? data.advantageAmount
                    ? data.advantageAmount
                    : 0
                  : 0
                : 0,
              "Tax Eligible Amount": !data.reverse
                ? data.Total -
                  (data.type
                    ? data.advantageAmount
                      ? data.advantageAmount
                      : 0
                    : 0)
                : 0,
              projects:
                data.type &&
                (data.Source === "stripe" || data.Source === "free")
                  ? eventsById[data.EventId]
                    ? eventsById[data.EventId].eventName
                    : ""
                  : "",
              "Donor Employer": data.employeeText ? data.employeeText : "-",
              "In memory/honor of": data.dedicatedTo
                ? data.dedicatedTo.name
                  ? data.dedicatedTo.name
                  : data.dedicatedTo.email
                  ? data.dedicatedTo.email
                  : "-"
                : "-",
            });
          }
          const resolveCampaignDonationPromises = await Promise.all(
            campaignRefPromises
          );
          for (let i in resolveCampaignDonationPromises) {
            const campaignsOnWhichTransactionOccured = [];
            const res = resolveCampaignDonationPromises[i];
            for (let j in res.docs) {
              const nItem = res.docs[j];
              const data = nItem.data();
              const campaign =
                finalResult[i]["Transaction source"] === "Donation" &&
                data.Source === "stripe"
                  ? campaignsListIndexed[data.CampaignId]
                    ? campaignsListIndexed[data.CampaignId].campaignName
                    : causeProjectsById[data.CampaignId].campaignName
                  : "";
              if (campaign) {
                campaignsOnWhichTransactionOccured.push(campaign);
              }
            }
            const uniq = [...new Set(campaignsOnWhichTransactionOccured)];

            if (!finalResult[i].projects) {
              finalResult[i] = {
                ...finalResult[i],
                projects: uniq.length ? uniq.join(",") : "-",
              };
            }
          }
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_DATA",
            payload: finalResult,
          });
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_DATA_STRIPE",
            payload: finalResult.filter((item) => item.Source === "stripe"),
          });
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              message: finalResult.length ? "" : "No data",
            },
          });
        } else {
          dispatch({
            type: "SET_TOTAL_CASH_FLOW_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              message: "No Data",
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_TOTAL_CASH_FLOW_LOADING",
        payload: {
          loading: false,
          success: true,
          error: err.message,
          message: "No Data",
        },
      });
    }
  };
export const fetchDonorFeedback =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    try {
      dispatch({
        type: "FETCH_DONORS_FEEDBACK_LOADING",
        payload: { loading: true, error: null, success: false },
      });
      const allUserUidIndexedArray = getState().allUsers.allUserUidIndexedArray;
      const firestore = getFirestore();
      const ref = firestore.collection("feedback").orderBy("timeValue", "desc");
      await ref.onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let resultArray = [];
          for (let i in snapShot.docs) {
            const item = snapShot.docs[i];
            const data = item.data();
            resultArray.push({
              name: allUserUidIndexedArray[data.id]
                ? allUserUidIndexedArray[data.id].Name
                : "-",
              email: allUserUidIndexedArray[data.id]
                ? allUserUidIndexedArray[data.id].email
                : "-",
              rating: data.rating,
              suggestions: data.suggestion,
              "Feedback Date": getDateInYYYYMMDDFormat(
                new Date(data.timeValue)
              ),
            });
          }
          dispatch({
            type: "FETCH_DONORS_FEEDBACK_DATA",
            payload: resultArray,
          });
          dispatch({
            type: "FETCH_DONORS_FEEDBACK_LOADING",
            payload: { loading: false, error: null, success: true },
          });
        } else {
          dispatch({
            type: "FETCH_DONORS_FEEDBACK_LOADING",
            payload: { loading: false, error: null, success: true },
          });
          dispatch({
            type: "FETCH_DONORS_FEEDBACK_DATA",
            payload: [],
          });
        }
      });
    } catch (e) {
      dispatch({
        type: "FETCH_DONORS_FEEDBACK_LOADING",
        payload: { loading: false, error: e.message, success: false },
      });
    }
  };

export const previousWeek = () => {
  var today = new Date();
  var previousWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  return previousWeek;
};

export const resetSendEmailForAllUserLoading = () => ({
  type: "RESET_SEND_EMAIL_FOR_ALL_USERS_LOADING",
  payload: { loading: false, success: false, error: null },
});
export const setEmailSent = (value) => ({
  type: "SET_EMAIL_SENT",
  payload: value,
});

const getUserLastOneTimePayment = async (userRef) => {
  const userPastTransactionQueryRef = userRef
    .collection("PastTransactions")
    .where("SID", "==", null)
    .orderBy("TimeValue", "desc")
    .limit(1);
  try {
    const userPastTransactionQueryRes = await userPastTransactionQueryRef.get();
    for (const doc of userPastTransactionQueryRes.docs) {
      const data = doc.data();
      return new Date(data.TimeValue).getTime();
    }
    return false;
  } catch (err) {
    console.log("Error inside ", err.message);
    return false;
  }
};
const getUserLastOneTimePaymentCauseProject = async (userRef, id) => {
  const userPastTransactionQueryRef = userRef
    .collection("Donations")
    .where("UID", "==", id)
    .orderBy("TimeValue", "desc")
    .limit(1);
  try {
    const userPastTransactionQueryRes = await userPastTransactionQueryRef.get();
    for (const doc of userPastTransactionQueryRes.docs) {
      const data = doc.data();
      return new Date(data.TimeValue).getTime();
    }
    return false;
  } catch (err) {
    console.log("Error inside ", err.message);
    return false;
  }
};

const getUserIndividualBalances = async (userRef, causesData) => {
  const userIndividualDocRef = userRef.collection("IndividualBalance");

  try {
    const userIndividualRefObj = {};
    const userIndividualRes = await userIndividualDocRef.get();
    for (let i in userIndividualRes.docs) {
      const item = userIndividualRes.docs[i];
      const data = item.data();
      userIndividualRefObj[
        getCauseTitleFromCauses(data.Cause, causesData) + " Balance"
      ] = data.Amount;
    }
    return { userIndividualBalance: { ...userIndividualRefObj } };
  } catch (err) {
    console.log("Error inside ", err.message);
    return { userIndividualBalance: null };
  }
};

export const setUserReview =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_FEEDBACK_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const setUserDonationFeedback = firebase
        .functions()
        .httpsCallable("setDonationFeedback");
      const response = await setUserDonationFeedback(data);
      if (response.data.success) {
        dispatch({
          type: "SET_FEEDBACK_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_FEEDBACK_LOADING",
          payload: { loading: false, success: false, error: true },
        });
      }
    } catch (err) {
      dispatch({
        type: "SET_FEEDBACK_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
      console.log("Error inside ", err.message);
    }
  };

const getUserLastPaymentDate = async (userRef) => {
  const userPastTransactionQueryRef = userRef
    .collection("PastTransactions")
    .orderBy("TimeValue", "desc")
    .limit(1);
  try {
    const userPastTransactionQueryRes = await userPastTransactionQueryRef.get();
    for (const doc of userPastTransactionQueryRes.docs) {
      const data = doc.data();
      return new Date(data.TimeValue).getTime();
    }
    return false;
  } catch (err) {
    console.log("Error inside", err.message);
    return false;
  }
};
