import { useState } from "react";

import FloatingHelp from "../floating-help/floating-help.component";
import { ReactComponent as HelpImg } from "../../assets/application-icons/help/help.svg";
import { ReactComponent as Cross } from "../../assets/application-icons/cross/cross.svg";

import "./help.styles.scss";
const Help = ({ ...props }) => {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className="help-container">
      <FloatingHelp
        showHelp={showHelp}
        setShowHelp={setShowHelp}
        onClick={() => setShowHelp(!showHelp)}
      >
        {showHelp ? <Cross className="cross-img" /> : <HelpImg />}
      </FloatingHelp>
    </div>
  );
};

export default Help;
