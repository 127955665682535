export const resetCreateTableIndexLoading = () => ({
  type: "CREATE_TABLE_INDEX_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchGlobalBalanceFromQLDB = (force) => {
  return async (dispatch, getState, { getFirebase }) => {
    const success = getState().qldbReducer.globalBalanceLoading.success;
    const firebase = getFirebase();

    try {
      if (!success || force) {
        dispatch({
          type: "SET_GLOBAL_BALANCE_LOADING",
          payload: { loading: true, success: false, error: null },
        });
        const fetchGlobalBalance = firebase
          .functions()
          .httpsCallable("adminFunctions-fetchGlobalBalance");

        const response = await fetchGlobalBalance();
        if (response.data.success) {
          dispatch({
            type: "SET_GLOBAL_BALANCE",
            payload: response.data.result,
          });
          dispatch({
            type: "SET_GLOBAL_BALANCE_LOADING",
            payload: { loading: false, success: success, error: null },
          });
        } else {
          const errorObject = {
            status: 400,
            message: response.data.errorMessage,
          };
          throw errorObject;
        }
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_GLOBAL_BALANCE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetFetchDataFromQLDBLoading = () => ({
  type: "FETCH_DATA_FROM_QLDB_LOADING",
  payload: { loading: false, success: false, error: null },
});
