const initialState = {
  contributionValue: 0,
  infaqueRunningValue: 0,
  totalAmountValue: 0,
  contributionMonthData: [],
  contributionMonthDataLoading: { loading: false, success: false, error: null },
  contributionYearData: [],
  contributionYearDataLoading: { loading: false, success: false, error: null },
  contributionAllData: [],
  contributionLoading: { loading: false, success: false, error: null },
  contributionMonthEnd: false,
  contributionYearEnd: false,
  contributionEnd: false,
  nextButtonDisabledCheck: false,
  causeProjectContributionData: [],
  agreementCheck: false,
  contibutionOneTime: false,
};

const contributionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTRIBUTION_ONE_TIME_OPTION":
      return { ...state, contibutionOneTime: action.payload };
    case "SET_CONTRIBUTION_SETUP":
      return { ...state, contributionValue: action.payload };
    case "SET_INFAQUE_RUNNING":
      return { ...state, infaqueRunningValue: action.payload };
    case "SET_TOTAL_Amount":
      return { ...state, totalAmountValue: action.payload };
    case "SET_CONTRIBUTION_MONTH_DATA":
      return { ...state, contributionMonthData: action.payload };
    case "SET_AGREEMENT_CHECKBOX":
      return { ...state, agreementCheck: action.payload };
    case "SET_CONTRIBUTION_MONTH_DATA_LOADING":
      return { ...state, contributionMonthDataLoading: action.payload };
    case "SET_CONTRIBUTION_CAUSE_DATA":
      return {
        ...state,
        [action.payload.type]: [
          ...(state[action.payload.type]
            ? [...state[action.payload.type], ...action.payload.data]
            : [...action.payload.data]),
        ],
      };
    case "SET_CAUSE_PROJECT_CONTRIBUTION_DATA":
      return {
        ...state,
        [action.payload.type]: [
          ...(state[action.payload.type]
            ? [...state[action.payload.type], ...action.payload.data]
            : [...action.payload.data]),
        ],
      };
    // case "SET_CONTRIBUTION_END":
    //   return { ...state, contributionEnd: action.payload };
    case "SET_CONTRIBUTION_END":
      return { ...state, [action.payload.type]: action.payload.data };
    case "SET_CONTRIBUTION_LOADING":
      return { ...state, contributionLoading: action.payload };

    default:
      return state;
  }
};

export default contributionReducer;
