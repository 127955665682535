const initialState = {
  boolValue: false,
  indexValue: 0,
};

const chooseCauseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHOOSE_CAUSE_BOOL":
      return { ...state, boolValue: action.payload };
    case "SET_CHOOSE_CAUSE_INDEX":
      return { ...state, indexValue: action.payload };
    default:
      return state;
  }
};

export default chooseCauseReducer;
