import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { hexToRgb } from "../../utils/colorFunctions";

const HelmetComponent = () => {
  const root = document.documentElement;
  const { role } = useSelector((state) => state.user.currentUser);
  const { basicBrandInfo, brandingColors } = useSelector(
    (state) => state.InfauqeInstance
  );

  // Setting application default colors
  useEffect(() => {
    if (brandingColors) {
      root?.style.setProperty(
        "--secondary-color",
        brandingColors.secondaryColor
      );
      root?.style.setProperty("--primary-color", brandingColors.primaryColor);
      const hexPrimary = hexToRgb(brandingColors.primaryColor);
      root?.style.setProperty(
        "--primary-light",
        `${hexPrimary[0]}, ${hexPrimary[1]}, ${hexPrimary[2]} `
      );
    } else {
      root?.style.setProperty("--secondary-color", "#1f5e72");
      root?.style.setProperty("--primary-color", "#faac32");
      const hexPrimary = hexToRgb("#faac32");
      root?.style.setProperty(
        "--primary-light",
        `${hexPrimary[0]}, ${hexPrimary[1]}, ${hexPrimary[2]} `
      );
    }
  }, [brandingColors, root]);
  return (
    <Helmet>
      <title>{`${
        role === "system-admin"
          ? `${basicBrandInfo && basicBrandInfo.websiteName} System Admin`
          : role === "admin"
          ? `${basicBrandInfo && basicBrandInfo.websiteName} Admin`
          : basicBrandInfo && basicBrandInfo.websiteName
      }`}</title>
      <meta
        name='viewport'
        content={`${
          role === "admin" || role === "system-admin"
            ? "width=1024"
            : "width=device-width, initial-scale=1"
        }`}
      />
      {basicBrandInfo && (
        <link
          rel='icon'
          href={
            basicBrandInfo && basicBrandInfo.favicon
              ? basicBrandInfo.favicon
              : basicBrandInfo.dynamicImage
          }
        />
      )}
    </Helmet>
  );
};

export default HelmetComponent;
