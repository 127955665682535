export const getCoords = async () => {
  try {
    const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
      success: true,
      coords: { long: pos.coords.longitude, lat: pos.coords.latitude },
    };
  } catch (err) {
    console.log(err.message);
    return { success: false, message: err.message };
  }
};
