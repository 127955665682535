import React from "react";
import { useHistory } from "react-router";

import "./admin-menu-bar-link.styles.scss";

const MenuBarLink = ({ item }) => {
  const history = useHistory();
  const pathname = history.location.pathname.split("/")[1];
  const { name, link, icon } = item;

  return (
    <div
      data-test={`link-${name}`}
      onClick={() => {
        history.replace(link);
      }}
      className={`admin-menubar-bar-link-component ${
        link.includes(pathname) ? "active-link" : ""
      }`}>
      {icon}
      <div className='name'>{name}</div>
    </div>
  );
};

export default MenuBarLink;
