import { logsOptions } from "../../utils/logs";

const initialState = {
  userProfileData: {},
  eventToDateStats: {},
  userProfileDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  eventToDateLoading: {
    loading: false,
    success: false,
    error: null,
  },
  userProfilePastHistoryData: [],
  userProfilePastHistoryDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  userSubscriptionPastData: [],
  userSubscriptionPastDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  userPastTransactionDataHistory: [],
  userPastTransactionDataHistoryLoading: {
    loading: false,
    success: false,
    error: null,
  },
  usersType: [],
  usersTypeLoading: { loading: false, success: false, error: null },
  validateUsersTypeLoading: { loading: false, success: false, error: null },
  contributionsToDate: [],
  contributionsToDateLoading: { loading: false, success: false, error: null },

  contributionToMonthData: [],
  contributionToMonthDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  sendEmailLoading: { loading: false, success: false, error: null },
  admins: [],
  adminFetchLoading: { loading: false, success: false, error: null },
  campaignContributions: [],
  campaignContributionsLoading: {
    loading: false,
    success: false,
    error: null,
    message: "",
  },
  specificCampaignContribution: {},
  specificEventParticpants: {},
  specificCampaignContributionLoading: {
    loading: false,
    success: false,
    error: null,
  },
  specificEventParticipantsLoading: {
    loading: false,
    success: false,
    error: null,
  },
  uploadUserToPlatformLoading: {
    loading: false,
    success: false,
    error: null,
  },
  logs: {},
  lastLog: {},
  logsLoading: { loading: false, success: false, error: null },
  logsFilter: logsOptions.all, // all | succeeded | failed
};

const adminUserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_PROFILE_DATA":
      return {
        ...state,
        userProfileData: action.payload,
      };
    case "SET_USER_PROFILE_PAST_HISTORY_DATA":
      return {
        ...state,
        userProfilePastHistoryData: action.payload,
      };
    case "SET_USER_PROFILE_DATA_LOADING":
      return {
        ...state,
        userProfileDataLoading: action.payload,
      };
    case "SET_EVENT_TO_DATE_STATS":
      return {
        ...state,
        eventToDateStats: action.payload,
      };
    case "SET_EVENTS_STATS_TO_DATE_LOADING":
      return {
        ...state,
        eventToDateLoading: action.payload,
      };
    case "SET_USER_PROFILE_PAST_HISTORY_DATA_LOADING":
      return {
        ...state,
        userProfilePastHistoryDataLoading: action.payload,
      };
    case "SET_SUBSCRIPTION_PAST_DATA":
      return {
        ...state,
        userSubscriptionPastData: action.payload,
      };
    case "SET_SUBSCRIPTION_PAST_DATA_LOADING":
      return {
        ...state,
        userSubscriptionPastDataLoading: action.payload,
      };
    case "SET_PAST_TRANSACTION_DATA_HISTORY":
      return {
        ...state,
        userPastTransactionDataHistory: action.payload,
      };
    case "SET_PAST_TRANSACTION_DATA_HISTORY_LOADING":
      return {
        ...state,
        userPastTransactionDataHistoryLoading: action.payload,
      };
    case "FETCH_CONTRIBUTION_TO_DATE":
      return {
        ...state,
        contributionsToDate: action.payload,
      };
    case "FETCH_ADMIN_DASHBOARD_TO_DATE_LOADING":
      return {
        ...state,
        contributionsToDateLoading: action.payload,
      };

    case "FETCH_CONTRIBUTION_TO_MONTH":
      return {
        ...state,
        contributionToMonthData: action.payload,
      };
    case "FETCH_ADMIN_DASHBOARD_MONTHLY_DATA_LOADING":
      return {
        ...state,
        contributionToMonthDataLoading: action.payload,
      };
    case "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA":
      return {
        ...state,
        campaignContributions: action.payload,
      };
    case "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID":
      return { ...state, specificCampaignContribution: action.payload };
    case "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID":
      return { ...state, specificEventParticpants: action.payload };
    case "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING":
      return {
        ...state,
        campaignContributionsLoading: action.payload,
      };
    case "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading":
      return { ...state, specificCampaignContributionLoading: action.payload };
    case "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID_Loading":
      return { ...state, specificEventParticipantsLoading: action.payload };
    case "SET_USERS_TYPE":
      return {
        ...state,
        usersType: action.payload,
      };
    case "SET_USERS_TYPE_LOADING":
      return {
        ...state,
        usersTypeLoading: action.payload,
      };
    case "SET_ADMIN_USERS":
      return { ...state, admins: action.payload };
    case "SET_ADMIN_USERS_FETCH_LOADING":
      return { ...state, adminFetchLoading: action.payload };
    case "VALIDATE_USER_COUNTS_LOADING":
      return {
        ...state,
        validateUsersTypeLoading: action.payload,
      };
    case "SET_UPLOAD_USERS_TO_PLATFORM_LOADING":
      return {
        ...state,
        uploadUserToPlatformLoading: action.payload,
      };

    case "SET_SEND_POTENTIAL_USERS_EMAIL_LOADING":
      return { ...state, sendEmailLoading: action.payload };

    case "RESET_LOGS":
      return {
        ...state,
        logs: {},
        lastLog: {},
        logsLoading: { loading: false, success: false, error: null },
      };
    case "SET_LOGS_FILTER":
      return { ...state, logsFilter: action.payload };
    case "SET_LOGS":
      return { ...state, logs: { ...state.logs, ...action.payload } };
    case "SET_LAST_LOG":
      return { ...state, lastLog: action.payload };
    case "SET_LOGS_LOADING":
      return { ...state, logsLoading: action.payload };
    case "RESET_SEND_EMAIL_LOADING":
      return {
        ...state,
        sendEmailLoading: action.payload,
      };

    default:
      return state;
  }
};

export default adminUserProfileReducer;
