import { logOutFunction } from "../../firebase/firebaseConfig";
import { getDateInYYYYMMDDFormat } from "../../utils/dateFunctions";
import { smallMonthToLongMonth } from "../../utils/timeDifference";
import { analytics } from "../../firebase/firebaseConfig";
import {
  addSnapshotsListners,
  reloadApplicationLoading,
} from "../app-settings/app-settings.action";

export const setCurrentUser = (user) => ({
  type: "SET_CURRENT_USER",
  payload: user,
});

export const setCheckIfUserIsFromEmailLink = (id) => ({
  type: "SET_USER_IS_FROM_EMAIL_LINK",
  payload: id,
});

export const getUserSubscriptionData =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_CURRENT_USER_SUBSCRIPTION_DATA_LOADING",
      payload: { loading: true, error: null, success: false },
    });
    const user = getState().user.currentUser;
    const firebase = getFirebase();
    try {
      if (user.subscription) {
        const subsEndDate = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("subscriptions")
          .doc(user.subscriptionData.SID)
          .get();
        const subscriptionData = subsEndDate.data();
        const endDate = subscriptionData.current_period_end.toDate();
        const newDate = new Date(endDate).toDateString().split(" ");
        dispatch({
          type: "SET_CURRENT_USER_SUBSCRIPTION_DATA",
          payload: {
            ...subscriptionData,
            nextDate: `${smallMonthToLongMonth(newDate[1])} ${newDate[2]}, ${
              newDate[3]
            }`,
            amount: user.subscriptionData.total,
            subscriptionLoaded: true,
          },
        });
        dispatch({
          type: "SET_CURRENT_USER_SUBSCRIPTION_DATA_LOADING",
          payload: { loading: false, error: null, success: true },
        });
      } else {
        analytics.logEvent("6_stripe_back_pressed");
        const errorObject = {
          status: 400,
          message: "User have no active subscription",
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CURRENT_USER_SUBSCRIPTION_DATA",
        payload: {
          subscriptionLoaded: true,
        },
      });
      dispatch({
        type: "SET_CURRENT_USER_SUBSCRIPTION_DATA_LOADING",
        payload: { loading: false, error: err.message, success: false },
      });
    }
  };
export const getTempBillingInformation =
  (docId) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_TEMP_BILLING_INFORMATION_LOADING",
      payload: { loading: true, error: null, success: false },
    });
    const firebase = getFirebase();
    try {
      await firebase
        .firestore()
        .collection("tempBillingInformation")
        .doc(docId)
        .onSnapshot((snap) => {
          if (snap.exists) {
            const dataTempBillingData = snap.data();
            dispatch({
              type: "SET_TEMP_BILLING_INFORMATION_LOADING",
              payload: { loading: false, error: null, success: true },
            });
            dispatch({
              type: "SET_TEMP_BILLING_INFORMATION",
              payload: {
                ...dataTempBillingData,
                tempBillingInformationId: snap.id,
              },
            });
          } else {
            dispatch({
              type: "SET_TEMP_BILLING_INFORMATION_LOADING",
              payload: { loading: false, error: null, success: false },
            });
            dispatch({
              type: "SET_TEMP_BILLING_INFORMATION",
              payload: {},
            });
          }
        });
    } catch (err) {
      console.log(err.message);

      dispatch({
        type: "SET_CURRENT_USER_SUBSCRIPTION_DATA_LOADING",
        payload: { loading: false, error: err.message, success: false },
      });
    }
  };

export const updateUserExplorePlatform =
  () =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "USER_EXPLORE_PLATFORM_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    const firebase = getFirebase();
    try {
      const updateUserExplorePlatform = firebase
        .functions()
        .httpsCallable("userFunctions-updateUserExplorePlatform");

      const result = await updateUserExplorePlatform();
      if (result.data.success) {
        dispatch({
          type: "USER_EXPLORE_PLATFORM_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        throw new Error(result.data.message);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "USER_EXPLORE_PLATFORM_LOADING",
        payload: {
          loading: false,
          success: false,
          error: error.message,
        },
      });
    }
  };
export const resetUserExplorePlatformLoading = () => ({
  type: "USER_EXPLORE_PLATFORM_LOADING",
  payload: {
    loading: false,
    success: false,
    error: null,
  },
});

export const signOutAction = () => {
  return async (dispatch, getState) => {
    const snapshots = getState().appSettings.snapshots;
    try {
      for (const snapshot of snapshots) {
        snapshot.snapshot();
      }
      await logOutFunction();
      window.location.replace(`${window.location.origin}/login`);
      dispatch(reloadApplicationLoading(true));
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const checkVerification = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const auth = firebase.auth();
    try {
      await auth.currentUser.reload();
      const verified = auth.currentUser.emailVerified;
      if (verified) {
        dispatch({
          type: "CHECK_VERIFICATION_EMAIL_SUCCESS",
          payload: verified,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUserUploadLoading = (data) => ({
  type: "UPDATE_USER_UPLOAD_LOADING",
  payload: data,
});

export const updateUserInfo = (data) => {
  console.log(data);
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_USER_PERSONAL_UPDATE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const updateInfo = firebase
        .functions()
        .httpsCallable("userFunctions-updatePersonalInfo");
      const response = await updateInfo(data);
      if (response.data.success) {
        dispatch({
          type: "SET_USER_PERSONAL_UPDATE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { state: 500, error: response.error };
        throw errorObject;
      }
    } catch (err) {
      dispatch({
        type: "SET_USER_PERSONAL_UPDATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const updateUserSubscriptionCauses = (causesArray) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "UPDATE_USER_SUBSCRIPTION_CAUSES_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    const updateUserCauses = firebase
      .functions()
      .httpsCallable("userFunctions-updateUserSubscriptionCauses");

    try {
      console.log(causesArray);
      const response = await updateUserCauses(causesArray);
      if (response.data.success) {
        dispatch({
          type: "UPDATE_USER_SUBSCRIPTION_CAUSES_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = {
          status: 400,
          message: response.data.errorMessage,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "UPDATE_USER_SUBSCRIPTION_CAUSES_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetUpdateUserSubscriptionCausesLoading = () => ({
  type: "UPDATE_USER_SUBSCRIPTION_CAUSES_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchUserLastPayment =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const UID = getState().user.currentUser.uid;
    const userContributionData =
      !!getState().user.currentUser.userContributionData;
    const userSubscriptionData = !getState().user.currentUser.subscriptionData;
    const userRef = firestore.collection("users").doc(UID);
    try {
      if (userContributionData && userSubscriptionData) {
        const pastTransactionRef = await userRef
          .collection("PastTransactions")
          .orderBy("TimeValue", "desc")
          .limit(1)
          .get();
        if (!pastTransactionRef.empty) {
          const date = pastTransactionRef.docs[0].data().TimeValue;
          dispatch({
            type: "SET_USER_LAST_FUNDED_DATE",
            payload: getDateInYYYYMMDDFormat(date),
          });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

export const uploadImpactShareImage = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "UPDATE_USER_IMPACT_IMAGE_UPLOAD_LOADING",
      payload: {
        loading: true,
        cause: data.id,
        success: false,
        error: null,
        url: null,
      },
    });
    const storage = firebase.storage();
    const shareableImpactImages =
      getState().user.currentUser.shareableImpactImages;

    try {
      let filePath;
      if (shareableImpactImages) {
        if (shareableImpactImages[data.id]) {
          filePath = storage.refFromURL(shareableImpactImages[data.id]).name;
        }
      }

      const uploadUserImpactShareImage = firebase
        .functions()
        .httpsCallable("userFunctions-uploadUserImpactShareImage");
      const resp = await uploadUserImpactShareImage({ ...data, filePath });
      if (resp.data.success) {
        dispatch({
          type: "UPDATE_USER_IMPACT_IMAGE_UPLOAD_LOADING",
          payload: {
            loading: false,
            cause: data.id,
            success: true,
            error: null,
            url: resp.data.url,
          },
        });
      } else {
        const errorObject = { status: 400, message: resp.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "UPDATE_USER_IMPACT_IMAGE_UPLOAD_LOADING",
        payload: {
          loading: false,
          cause: data.id,
          success: false,
          error: err.message,
          url: null,
        },
      });
    }
  };
};

export const setUserNameSignUp = (name) => ({
  type: "SET_USERNAME_SIGNUP",
  payload: name,
});

export const resetUserImpactImageUploadLoading = () => ({
  type: "UPDATE_USER_IMPACT_IMAGE_UPLOAD_LOADING",
  payload: {
    loading: false,
    cause: null,
    success: false,
    error: null,
    url: null,
  },
});

export const fetchUserExcludedCampaigns = (uid) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const excludedCampaignsCollectionRef =
      firestore.collection("excluded-campaigns");
    try {
      const excludedCampaignsQueryRef = excludedCampaignsCollectionRef
        .where("uid", "==", uid)
        .where("excluded", "==", true);
      let excludedCampaignsSnapshot = excludedCampaignsQueryRef.onSnapshot(
        (snapshot) => {
          let excludedCampaigns = {};
          for (const doc of snapshot.docs) {
            const data = doc.data();
            const campaignId = data.campaignId;
            excludedCampaigns[campaignId] = { ...doc.data(), id: doc.id };
          }
          dispatch({
            type: "UPDATE_USER_EXCLUDED_CAMPAIGNS",
            payload: excludedCampaigns,
          });
          dispatch({
            type: "UPDATE_USER_EXCLUDED_CAMPAIGNS_LOADED_STATUS",
            payload: true,
          });
        }
      );
      dispatch(
        addSnapshotsListners({
          type: "excluded-campaigns-snapshot",
          snapshot: excludedCampaignsSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const getAllEventStatuses = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const uid = getState().user.currentUser.uid;
    const firestore = getFirestore();
    const cloudLoadingsRef = firestore.collection("event_statuses").doc(uid);
    try {
      const cloudLoadingSnapshots = cloudLoadingsRef.onSnapshot((res) => {
        const data = res.data();
        if (data) {
          if (data.campaignPaymentLoading) {
            dispatch({
              type: "UPDATE_CAMPAIGN_PAYMENT_LOADING",
              payload: data.campaignPaymentLoading,
            });
          }
          if (data.causePaymentLoading) {
            dispatch({
              type: "UPDATE_CAUSE_PAYMENT_LOADING",
              payload: data.causePaymentLoading,
            });
          }
          if (data.causeMovementLoading) {
            dispatch({
              type: "UPDATE_CAUSE_MOVEMENT_LOADING",
              payload: data.causeMovementLoading,
            });
          }
        }
      });
      dispatch(
        addSnapshotsListners({
          type: "users-cloud-loading-snapshot",
          snapshot: cloudLoadingSnapshots,
        })
      );
    } catch (err) {
      console.log(err.message);
    }
  };
};

export const updateEventSuccessStatus = (loadingType) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "UPDATE_EVENT_STATUSES_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const cloudFunctionRef = firebase
        .functions()
        .httpsCallable("userFunctions-updateUserEventSuccessStatuses");
      const response = await cloudFunctionRef({ loadingType });
      console.log(response.data);
      dispatch({
        type: "UPDATE_EVENT_STATUSES_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "UPDATE_EVENT_STATUSES_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetEventStatusesLoading = () => ({
  type: "UPDATE_EVENT_STATUSES_LOADING",
  payload: { loading: false, success: false, error: null },
});
