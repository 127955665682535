const initialState = {
  organizationData: {},
  allOrganizations: [],
  allOrganizationsAsObject: {},
  organizationDataLoading: { loading: false, error: null, success: false },
  allOrganizationLoading: { loading: false, error: null, success: false },
  campaignDataLoading: { loading: false, error: null, success: false },
  updateOrgDataLoading: { loading: false, error: null, success: false },
  executiveInfo: [],
  directorInfo: [],
  financeRev: [],
  financeExp: [],
  organizationSearchData: [],
  createOrganizationDataLoading: {
    error: null,
    success: false,
  },
  resetOrganizationModal: true,
  fetchOrganizationLoading: {
    loading: false,
    success: false,
    error: null,
  },
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORGANIZATION_DATA":
      return { ...state, organizationData: action.payload };
    case "SET_ALL_ORGANIZATIONS":
      return { ...state, allOrganizations: action.payload };
    case "SET_ALL_ORGANIZATIONS_AS_OBJECTS":
      return { ...state, allOrganizationsAsObject: action.payload };
    case "SET_ALL_ORGANIZATION_LOADING":
      return { ...state, allOrganizationLoading: action.payload };
    case "SET_ORGANIZATION_SEARCH_DATA":
      return { ...state, organizationSearchData: action.payload };
    case "SET_CREATE_ORGANIZATION_DATA_LOADING":
      return { ...state, createOrganizationDataLoading: action.payload };
    case "SET_ORGANIZATION_DATA_LOADING":
      return { ...state, organizationDataLoading: action.payload };
    case "SET_CAMPAIGN_DATA_LOADING":
      return { ...state, campaignDataLoading: action.payload };
    case "SET_UPDATE_ORGANIZATION_DATA_LOADING":
      return { ...state, updateOrgDataLoading: action.payload };
    case "RESET_ORGANIZATION_MODAL":
      return { ...state, resetOrganizationModal: action.payload };
    case "SET_FETCH_ORGANIZATION_DATA_LOADING":
      return { ...state, fetchOrganizationLoading: action.payload };

    case "SET_EXECUTIVE_INFO":
      return {
        ...state,
        executiveInfo: action.payload,
      };

    case "SET_DIRECTOR_INFO":
      return {
        ...state,
        directorInfo: action.payload,
      };
    case "SET_REVENUE_FINANCE":
      return {
        ...state,
        financeRev: action.payload,
      };

    case "SET_EXPENSE_FINANCE":
      return {
        ...state,
        financeExp: action.payload,
      };
    case "SET_RESET_ORG_REDUCERS":
      return {
        ...state,
        organizationData: {},
        organizationDataLoading: {
          loading: false,
          error: null,
          success: false,
        },
        campaignLoadingAfterSubmission: {
          loading: false,
          error: null,
          success: false,
        },
        campaignDataLoading: { loading: false, error: null, success: false },
        updateOrgDataLoading: { loading: false, error: null, success: false },
        resetFetchOrganizationLoading: {
          loading: false,
          error: null,
          success: false,
        },
        executiveInfo: [],
        directorInfo: [],
        financeRev: [],
        financeExp: [],
        createOrganizationDataLoading: {
          error: null,
          success: false,
        },
        resetOrganizationModal: true,
      };
    default:
      return state;
  }
};

export default organizationReducer;
