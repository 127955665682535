import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import logo_white from "../../assets/logo_white.svg";
import infaqueALogo from "../../assets/a-transparent.svg";
import PrimaryButton from "../primary-button/primary-button.component";
import { SidebarData } from "../../utils/mobileSidebarData";
import "./mobile_sidebar.styles.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { signOutAction } from "../../redux/auth/auth.action";
import { useDispatch } from "react-redux";
import { getValueFromUrl } from "../../utils/linkFunctions";

const MobSidebar = ({ menuOption }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [sidebar, setSidebar] = useState(false);
  const [sidebarDataNew, setSidebarDataNew] = useState(null);
  const [homeUrl, setHomeUrl] = useState("/");

  const mobileTitle = useSelector((state) => state.appSettings.mobileTitle);
  const { basicBrandInfo, applicationMode, features } = useSelector(
    (state) => state.InfauqeInstance
  );
  const { events } = features;
  const { currentUser } = useSelector((state) => state.user);
  const searchValues = getValueFromUrl(location.search, "header");

  useEffect(() => {
    let sidebarObj = [...SidebarData];
    if (applicationMode !== "cause-project" || !events.active) {
      sidebarObj.splice(3, 1);
    }
    for (let i in sidebarObj) {
      const item = sidebarObj[i];
      if (item.title === "Campaigns") {
        item.title = basicBrandInfo.campaignName + "s";
      }
    }
    setSidebarDataNew(sidebarObj);
  }, [basicBrandInfo, applicationMode, events.active]);

  useEffect(() => {
    if (!currentUser.authenticated) {
      setHomeUrl("/login");
    } else {
      setHomeUrl("/dashboard");
    }
  }, [currentUser]);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div
      className={`mobile-sidebar-component ${searchValues ? "no-header" : ""}
    `}>
      <div className={`mobnav ${sidebar ? "background" : ""} `}>
        <div className={`mobnav-container ${sidebar ? "background" : ""} `}>
          <div className='icon'>
            <img
              onClick={() => history.push(homeUrl)}
              src={basicBrandInfo ? basicBrandInfo.mobileLogo : infaqueALogo}
              alt='Logo'
            />
          </div>
          <div className='title'>
            <p>{mobileTitle}</p>
          </div>
          <div className='menu-button'>
            {menuOption && (
              <Link to='#' className='menu-bars'>
                <i className='fas fa-bars' onClick={showSidebar}></i>
              </Link>
            )}
          </div>
        </div>
      </div>
      {menuOption && (
        <div
          onClick={showSidebar}
          className={`nav-menu-container ${sidebar ? "background" : ""}`}>
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className='nav-menu-items'>
              <li
                className={`${
                  basicBrandInfo ? "logo-infaque-instance" : "logo-infaque"
                }`}>
                <Link to={"/"}>
                  <img
                    alt='logo'
                    src={basicBrandInfo ? basicBrandInfo.logo : ""}
                  />
                </Link>
              </li>
              {sidebarDataNew &&
                sidebarDataNew.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path}>
                        <img src={item.icon} alt={item.title} />
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              <PrimaryButton
                handleClick={() => dispatch(signOutAction())}
                type='button'
                className='sign-out-nav-item'
                data-test='sign-out'>
                Sign Out
              </PrimaryButton>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default MobSidebar;
