import { convertTimeToCompleteTimeWithDateAndTimeZoneFormat } from "../../utils/dateFunctions";
import {
  removeSpaceFunction,
  firstCharLowerCase,
} from "../../utils/stringFormatting";
import { floatValue } from "../../utils/floatValue";
import { checkForErrors } from "../../utils/errorFunctions";
import {
  getCampaignTitleFromCMS,
  getCauseTitleFromCMS,
} from "../../utils/getDataFromApplicationObjects";
import {
  addLogsSnapshots,
  addSnapshotsListners,
} from "../app-settings/app-settings.action";
import { logsOptions } from "../../utils/logs";

export const fetchUserProfileDataForAdmin = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_USER_PROFILE_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const fetchUserProfileData = firebase
        .functions()
        .httpsCallable("adminFunctions-fetchUserProfileData");
      const resp = await fetchUserProfileData(data);
      if (resp.data.success) {
        dispatch({
          type: "SET_USER_PROFILE_DATA",
          payload: resp.data.userData,
        });
        dispatch({
          type: "SET_USER_PROFILE_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: resp.data.errorMessage };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_USER_PROFILE_DATA",
        payload: {},
      });
      dispatch({
        type: "SET_USER_PROFILE_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchEventStatsToDate = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_EVENTS_STATS_TO_DATE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const ref = firebase
        .firestore()
        .collection("metaData")
        .where("type", "==", "event-to-date-values");
      const eventRefRes = await ref.get();
      if (!eventRefRes.empty) {
        const document = eventRefRes.docs[0];
        const data = document.data();
        dispatch({
          type: "SET_EVENT_TO_DATE_STATS",
          payload: data,
        });
        dispatch({
          type: "SET_EVENTS_STATS_TO_DATE_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: "No Data" };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_EVENT_TO_DATE_STATS",
        payload: {},
      });
      dispatch({
        type: "SET_EVENTS_STATS_TO_DATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchUserProfileHistoryForAdmin = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userUid = getState().adminUserProfile.userProfileData.uid;
    const startDate = data.startDate;
    const endDate = data.endDate;
    try {
      if (userUid) {
        dispatch({
          type: "SET_USER_PROFILE_PAST_HISTORY_DATA_LOADING",
          payload: { loading: true, success: false, error: null },
        });
        let userPastHistoryData = [];
        const user = await firestore
          .collection("users")
          .doc(userUid)
          .collection("userProfileHistory")
          .where("timeStamp", ">", startDate)
          .where("timeStamp", "<", endDate)
          .orderBy("timeStamp", "desc")
          .get();
        if (!user.empty) {
          user.forEach((doc) => {
            const userData = { ...doc.data() };
            userPastHistoryData.push(userData);
          });
        }
        dispatch({
          type: "SET_USER_PROFILE_PAST_HISTORY_DATA",
          payload: userPastHistoryData,
        });
        dispatch({
          type: "SET_USER_PROFILE_PAST_HISTORY_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_USER_PROFILE_PAST_HISTORY_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchSubscriptionDataForAdmin = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userUid = getState().adminUserProfile.userProfileData.uid;
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    dispatch({
      type: "SET_SUBSCRIPTION_PAST_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      let userSubscriptionPastData = [];
      const userRef = firestore.collection("users").doc(userUid);
      const userData = await userRef
        .collection("subscriptions")
        .where("created", ">", startDate)
        .where("created", "<", endDate)
        .orderBy("created", "desc")
        .get();

      if (!userData.empty) {
        for (const doc of userData.docs) {
          const userDoc = {
            Status: doc.data().status,
            Canceled: doc.data().canceled_at
              ? convertTimeToCompleteTimeWithDateAndTimeZoneFormat(
                  doc.data().canceled_at.toDate()
                )
              : "Null",
            Created: convertTimeToCompleteTimeWithDateAndTimeZoneFormat(
              doc.data().created.toDate()
            ),
            CurrentPeriodEnd:
              convertTimeToCompleteTimeWithDateAndTimeZoneFormat(
                doc.data().current_period_end.toDate()
              ),
            CurrentPeriodStart:
              convertTimeToCompleteTimeWithDateAndTimeZoneFormat(
                doc.data().current_period_start.toDate()
              ),
            Unit_Amount: doc.data().items[0].plan.amount / 100,
            SID: doc.id,
          };
          userSubscriptionPastData.push(userDoc);
        }

        dispatch({
          type: "SET_SUBSCRIPTION_PAST_DATA",
          payload: userSubscriptionPastData,
        });
        dispatch({
          type: "SET_SUBSCRIPTION_PAST_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_SUBSCRIPTION_PAST_DATA",
          payload: [],
        });
        const errorObject = {
          state: 400,
          message: "Data is not Found",
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "SET_SUBSCRIPTION_PAST_DATA_LOADING",
        payload: { loading: false, success: true, error: err.message },
      });
    }
  };
};

export const fetchPastTransactionDataForAdmin = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userUid = getState().adminUserProfile.userProfileData.uid;
    const applicationMode = getState().InfauqeInstance.applicationMode;
    console.log(applicationMode);
    const startDate = data.startDate;
    const endDate = data.endDate;
    dispatch({
      type: "SET_PAST_TRANSACTION_DATA_HISTORY_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      let userPastTransactionDataHistory = [];
      const userRef = firestore.collection("users").doc(userUid);

      const userData =
        applicationMode !== "cause-project"
          ? await userRef
              .collection("PastTransactions")
              .where("TimeValue", ">", startDate)
              .where("TimeValue", "<", endDate)
              .orderBy("TimeValue", "desc")
              .get()
          : await firestore
              .collection("Donations")
              .where("TimeValue", ">", startDate)
              .where("TimeValue", "<", endDate)
              .where("UID", "==", userUid)
              .orderBy("TimeValue", "desc")
              .get();
      console.log(userData);

      if (!userData.empty) {
        for (const doc of userData.docs) {
          let userDoc = {};
          if (applicationMode !== "cause-project") {
            userDoc = {
              Causes_Amount: doc.data().Causes_Amount,
              InvoicePdf: doc.data().InvoicePdf,
              SID: doc.data().SID,
              Tip: doc.data().Tip,
              Total: doc.data().Total,
              TimeValue: new Date(doc.data().TimeValue).toString(),
            };
          } else {
            userDoc = {
              Causes_Amount: doc.data().Contribution,
              InvoicePdf: doc.data().receiptLink,
              SID: doc.data().SID,
              Tip: doc.data().Tip,
              Total: doc.data().Total,
              TimeValue: new Date(doc.data().TimeValue).toString(),
            };
          }
          userPastTransactionDataHistory.push(userDoc);
        }

        dispatch({
          type: "SET_PAST_TRANSACTION_DATA_HISTORY",
          payload: userPastTransactionDataHistory,
        });
        dispatch({
          type: "SET_PAST_TRANSACTION_DATA_HISTORY_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_PAST_TRANSACTION_DATA_HISTORY",
          payload: [],
        });
        const errorObject = { status: 400, message: "No result " };
        throw errorObject;
      }
    } catch (err) {
      dispatch({
        type: "SET_PAST_TRANSACTION_DATA_HISTORY_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchContributionToDate = (force) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const contributionsToDateLoading =
      getState().adminUserProfile.contributionsToDateLoading;
    const featuresTip = getState().InfauqeInstance.features.tips;
    const { causesDataFirebase } = getState().causes;

    if (!force) {
      if (contributionsToDateLoading.success) return;
    }
    dispatch({
      type: "FETCH_ADMIN_DASHBOARD_TO_DATE_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firestore = getFirestore();
      let userTypes = [
        "Total Cash In Flow To Date",
        "Refunds To Date",
        "Total Contribution To Date",
        "Stripe Expenses To Date",
        "Subscription To Date",
        "Total Disbursment To Date",
      ];
      if (featuresTip.active) {
        userTypes.push("Total Tip To Date");
      }
      let toDateAmounts;
      const result = await userTypeDataRecord(firestore, userTypes);
      const causesResult = await getCausesFromFirebase(
        firestore,
        "causesToDate",
        causesDataFirebase
      );

      const impactResult = await getImpactFromFirebase(
        firestore,
        "totalImpactToDate"
      );
      if (impactResult) {
        toDateAmounts = [...result, ...impactResult, ...causesResult];
      } else {
        toDateAmounts = [...result, ...causesResult];
      }
      dispatch({
        type: "FETCH_CONTRIBUTION_TO_DATE",
        payload: toDateAmounts.map((item) => ({
          ...item,
          value: floatValue(item.value),
        })),
      });
      dispatch({
        type: "FETCH_ADMIN_DASHBOARD_TO_DATE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_DASHBOARD_TO_DATE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchContributionToMonth = (data, initialData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firebase = getFirebase();
      const featuresTip = getState().InfauqeInstance.features.tips;
      const contributionToMonthDataLoading =
        getState().adminUserProfile.contributionToMonthDataLoading;
      if (contributionToMonthDataLoading.success && initialData) {
        return;
      }
      dispatch({
        type: "FETCH_ADMIN_DASHBOARD_MONTHLY_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      const fetchMonthlyAdminDashboardData = firebase
        .functions()
        .httpsCallable("adminFunctions-fetchMonthlyAdminDashboardData");

      const resp = await fetchMonthlyAdminDashboardData(data);

      checkForErrors(resp.data);
      const contributionByCause = resp.data.contributionByCause.map((item) => ({
        ...item,
        value: floatValue(item.value),
      }));
      const newObj = [
        {
          title: "Total Monthly InFlow",
          value: floatValue(
            (
              floatValue(resp.data.totalMonthlyContributions.toFixed(2)) +
              floatValue(resp.data.totalMonthlyTips)
            ).toFixed(2)
          ),
        },
        {
          title: "Total Refunds",
          value: floatValue(
            resp.data.totalRefunds ? resp.data.totalRefunds.toFixed(2) : 0
          ),
        },
        {
          title: "Total Monthly Contributions",
          value: floatValue(resp.data.totalContribution.toFixed(2)),
        },
        {
          title: "Stripe Monthly Expense",
          value: floatValue(resp.data.stripeArray.toFixed(2)),
        },

        ...contributionByCause,
        ...resp.data.totalContributionByCauses,
      ];
      if (featuresTip.active) {
        newObj.push({
          title: "Total Monthly Tips",
          value: floatValue(resp.data.totalMonthlyTips.toFixed(2)),
        });
      }

      dispatch({
        type: "FETCH_CONTRIBUTION_TO_MONTH",
        payload: newObj,
      });
      dispatch({
        type: "FETCH_ADMIN_DASHBOARD_MONTHLY_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_DASHBOARD_MONTHLY_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const validateAdminUserCounts = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: "VALIDATE_USER_COUNTS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();

    try {
      const validateAdminDashboardUserCounts = firebase
        .functions()
        .httpsCallable("adminFunctions-validateAdminDashboardUserCounts");
      const response = await validateAdminDashboardUserCounts();
      if (response.data.success) {
        dispatch(fetchCountNumberOfUsers(true));
        dispatch({
          type: "VALIDATE_USER_COUNTS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: response.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "VALIDATE_USER_COUNTS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchCountNumberOfUsers = (force) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const usersTypesLoading = getState().adminUserProfile.usersTypeLoading;
    const tips = getState().InfauqeInstance.features.tips;
    if (!force) {
      if (usersTypesLoading.success) return;
    }
    dispatch({
      type: "SET_USERS_TYPE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const userTypes = [
        "Subscribed Users",
        "Unsubscribed Users",
        "Potential Users",
        "One Time Paid Users",
        "Total One Time Payments",
        "Admin Users",
        "Total Users",
      ];
      if (tips.active) {
        userTypes.splice(5, 0, "Users No KIR");
      }

      const result = await userTypeDataRecord(firestore, userTypes);
      dispatch({
        type: "SET_USERS_TYPE",
        payload: result,
      });
      dispatch({
        type: "SET_USERS_TYPE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_USERS_TYPE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAdminUser = () => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_ADMIN_USERS_FETCH_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const firestore = getFirestore();
      const adminUsersSnapshot = firestore
        .collection("users")
        .where("role", "==", "admin")
        .onSnapshot(async (snapShotData) => {
          let adminsArray = [];
          if (!snapShotData.empty) {
            snapShotData.forEach(async (doc) => {
              adminsArray.push({ ...doc.data(), uid: doc.id });
            });
            dispatch({
              type: "SET_ADMIN_USERS_FETCH_LOADING",
              payload: { loading: false, success: true, error: null },
            });
            dispatch({
              type: "SET_ADMIN_USERS",
              payload: adminsArray,
            });
          } else {
            dispatch({
              type: "SET_ADMIN_USERS",
              payload: [],
            });
          }
        });
      dispatch(
        addSnapshotsListners({
          type: "admin-users-snapshot",
          snapshot: adminUsersSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ADMIN_USERS_FETCH_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchCampaignContributionsData = (dates) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const currentDates = getState().allUsers.projectBasedDateSelected;
    const firebase = getFirebase();

    try {
      if (
        dates.startDate !== new Date(currentDates.startDate).getTime() ||
        dates.endDate !== new Date(currentDates.endDate).getTime()
      ) {
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
          payload: {
            loading: true,
            success: false,
            error: null,
            message: "Fetching Data",
          },
        });
        const fetchCampaignContributionDataFunction = firebase
          .functions()
          .httpsCallable("adminFunctions-fetchCampaignContributionData");
        const response = await fetchCampaignContributionDataFunction(dates);
        const result = response.data;
        console.log(result);
        checkForErrors(result);
        // if (result.contributions.length) {
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA",
          payload: result.contributions,
        });
        // }
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
          payload: {
            loading: false,
            success: true,
            error: null,
            message: result.contributions.length ? "" : "No Data",
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
          message: "",
        },
      });
    }
  };
};

export const fetchCampaignContributionsDataCauseProject = (dates) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const currentDates = getState().allUsers.projectBasedDateSelected;
    const firebase = getFirebase();
    const allUserUidIndexedArray = getState().allUsers.allUserUidIndexedArray;
    const activeCampaignsListIndexed =
      getState().campaign.activeCampaignsListIndexed;
    const causeProjectsById = getState().campaign.causeProjectsById;
    const basicBrandInfo = getState().InfauqeInstance.basicBrandInfo;
    try {
      if (
        dates.startDate !== new Date(currentDates.startDate).getTime() ||
        dates.endDate !== new Date(currentDates.endDate).getTime()
      ) {
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
          payload: {
            loading: true,
            success: false,
            error: null,
            message: "Fetching Data",
          },
        });
        const ref = firebase
          .firestore()
          .collection("CampaignDonations")
          .where("TimeValue", ">=", dates.startDate)
          .where("TimeValue", "<=", dates.endDate);
        const refResult = await ref.get();
        if (!refResult.empty) {
          let finalData = [];
          for (let i in refResult.docs) {
            const item = refResult.docs[i];
            const data = item.data();

            const UID = data.UID;
            const TimeValue = data.TimeValue;
            const CampaignId = data.CampaignId;
            const OrganizationId = data.OrganizationId;
            const Amount = data.Contribution;
            const type = data.type;
            const reverse = data.reverse;
            if (CampaignId) {
              finalData.push({
                id: UID,
                TimeValue,
                CampaignId,
                OrganizationId,
                Amount: reverse ? 0 : Amount,
                Cause: activeCampaignsListIndexed[CampaignId]
                  ? activeCampaignsListIndexed[CampaignId].causes[0].label
                  : causeProjectsById[CampaignId].causes[0].label,

                campaign: activeCampaignsListIndexed[CampaignId]
                  ? activeCampaignsListIndexed[CampaignId]
                  : causeProjectsById[CampaignId],
                organization: {},
                user: allUserUidIndexedArray[UID],
                Donor: allUserUidIndexedArray[UID].Name
                  ? allUserUidIndexedArray[UID].Name
                  : "",
                Email: allUserUidIndexedArray[UID].email
                  ? allUserUidIndexedArray[UID].email
                  : "",
                Status: allUserUidIndexedArray[UID].Status
                  ? allUserUidIndexedArray[UID].Status
                  : "",
                Project: activeCampaignsListIndexed[CampaignId]
                  ? activeCampaignsListIndexed[CampaignId].campaignName
                  : causeProjectsById[CampaignId].campaignName,
                type:
                  type === "cause"
                    ? getCauseTitleFromCMS(basicBrandInfo, true)
                    : getCampaignTitleFromCMS(basicBrandInfo, true),
                "Reversed/Refunded Amount": reverse ? Amount : 0,
              });
            }
          }
          dispatch({
            type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA",
            payload: finalData,
          });
          dispatch({
            type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              message: finalData.length ? "" : "No Data",
            },
          });
        } else {
          dispatch({
            type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA",
            payload: [],
          });
          dispatch({
            type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              message: "No Data",
            },
          });
        }
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: err.message,
          message: "",
        },
      });
    }
  };
};

export const fetchContributionsDataBySpecificCampaignCauseProject = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const { specificCampaignContribution } = getState().adminUserProfile;
    const allUserUidIndexedArray = getState().allUsers.allUserUidIndexedArray;
    const activeCampaignsListIndexed =
      getState().campaign.activeCampaignsListIndexed;
    const causeProjectsById = getState().campaign.causeProjectsById;
    const basicBrandInfo = getState().InfauqeInstance.basicBrandInfo;
    if (specificCampaignContribution[id]) {
      return;
    }
    dispatch({
      type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const ref = firebase
        .firestore()
        .collection("CampaignDonations")
        .where("CampaignId", "==", id);
      const refResult = await ref.get();
      console.log(refResult);
      if (!refResult.empty) {
        let finalData = [];
        for (let i in refResult.docs) {
          const item = refResult.docs[i];
          const data = item.data();
          const UID = data.UID;
          const TimeValue = data.TimeValue;
          const CampaignId = data.CampaignId;
          const OrganizationId = data.OrganizationId;
          const Amount = data.Contribution;
          const type = data.type;
          const reverse = data.reverse;
          if (CampaignId) {
            finalData.push({
              id: UID,
              TimeValue,
              CampaignId,
              OrganizationId,
              Amount: reverse ? 0 : Amount,
              Cause: activeCampaignsListIndexed[CampaignId]
                ? activeCampaignsListIndexed[CampaignId].causes[0].label
                : causeProjectsById[CampaignId].causes[0].label,

              campaign: activeCampaignsListIndexed[CampaignId]
                ? activeCampaignsListIndexed[CampaignId]
                : causeProjectsById[CampaignId],
              organization: {},
              user: allUserUidIndexedArray[UID],
              Donor: allUserUidIndexedArray[UID]
                ? allUserUidIndexedArray[UID].Name
                : "",
              Email: allUserUidIndexedArray[UID]
                ? allUserUidIndexedArray[UID].email
                : "",
              Status: allUserUidIndexedArray[UID].Status
                ? allUserUidIndexedArray[UID].Status
                : "",
              Project: activeCampaignsListIndexed[CampaignId]
                ? activeCampaignsListIndexed[CampaignId].campaignName
                : causeProjectsById[CampaignId].campaignName,
              type:
                type === "cause"
                  ? getCauseTitleFromCMS(basicBrandInfo, true)
                  : getCampaignTitleFromCMS(basicBrandInfo, true),
              "Reversed/Refunded Amount": reverse ? Amount : 0,
            });
          }
        }

        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID",
          payload: {
            ...specificCampaignContribution,
            [id]: finalData,
          },
        });
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
          payload: { loading: false, success: true, error: null },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchEventParticipantsDataBySpecificEvent = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const { specificEventParticpants } = getState().adminUserProfile;
    const allUserUidIndexedArray = getState().allUsers.allUserUidIndexedArray;

    if (specificEventParticpants[id]) {
      return;
    }
    dispatch({
      type: "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID_Loading",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const ref = firebase
        .firestore()
        .collection("EventTicketsProceeds")
        .where("EventId", "==", id);
      const refResult = await ref.get();
      console.log(refResult);
      if (!refResult.empty) {
        let finalData = [];
        for (let i in refResult.docs) {
          const item = refResult.docs[i];
          const data = item.data();
          const UID = data.UID;
          const TimeValue = data.TimeValue;
          const Amount = data.Amount;
          const reverse = data.reverse;
          const Timestamp =
            TimeValue.seconds + TimeValue.nanoseconds / 1000000000;
          const Datea = new Date(Timestamp * 1000);
          finalData.push({
            id: UID,
            "Service Charges": !reverse ? (data.Tip ? data.Tip : 0) : 0,
            "Tickets Purchased": data.TotalTickets,
            Amount: !reverse ? Amount : 0,
            Total: !reverse ? (data.Total ? data.Total : 0) : 0,
            "Purchased On": new Date(Datea).getTime(),
            Name: allUserUidIndexedArray[UID].Name
              ? allUserUidIndexedArray[UID].Name
              : "",
            "Reversed/Refunded Amount": reverse
              ? data.Total
                ? data.Total
                : 0
              : 0,
            Email: data.Email,
            Status: allUserUidIndexedArray[UID].Status
              ? allUserUidIndexedArray[UID].Status
              : "",
          });
        }

        dispatch({
          type: "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID",
          payload: {
            ...specificEventParticpants,
            [id]: finalData,
          },
        });
        dispatch({
          type: "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID_Loading",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID_Loading",
          payload: { loading: false, success: true, error: null },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_EVENT_PARTICIPANTS_DATA_BY_ID_Loading",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchContributionsDataBySpecificCampaign = (id) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const { specificCampaignContribution } = getState().adminUserProfile;
    if (specificCampaignContribution[id]) {
      return;
    }
    dispatch({
      type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const fetchContributionDataByCampaignId = firebase
        .functions()
        .httpsCallable("adminFunctions-fetchContributionDataByCampaignId");
      const response = await fetchContributionDataByCampaignId({ id });
      console.log(response);
      const result = response.data;
      checkForErrors(result);

      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID",
        payload: {
          ...specificCampaignContribution,
          [id]: result.contributions,
        },
      });
      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_BY_ID_Loading",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const uploadUserToPlatform = (data) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_UPLOAD_USERS_TO_PLATFORM_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const uploadCsvData = firebase
        .functions()
        .httpsCallable("adminFunctions-uploadCsvData");
      const response = await uploadCsvData(data);
      const result = response.data;
      checkForErrors(result);
      console.log(result);
      dispatch({
        type: "SET_UPLOAD_USERS_TO_PLATFORM_LOADING",
        payload: {
          loading: false,
          success: true,
          error: null,
          errors: result.errors,
          anyError: result.anyError,
        },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_UPLOAD_USERS_TO_PLATFORM_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchLogs = ({ initial, logsFilter }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const { lastLog } = getState().adminUserProfile;
    const lastLogExist = Object.keys(lastLog).length ? true : false;
    if (lastLogExist && initial) return;
    try {
      dispatch({
        type: "SET_LOGS_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const firestore = getFirestore();
      let logsRef = firestore.collection("event_logs");

      if (logsFilter === logsOptions.all) {
        logsRef = logsRef.orderBy("createdAt", "desc");
      } else if (logsFilter === logsOptions.pass) {
        logsRef = logsRef
          .where("success", "==", true)
          .orderBy("createdAt", "desc");
      } else if (logsFilter === logsOptions.fail) {
        logsRef = logsRef
          .where("error", "!=", null)
          .orderBy("error", "desc")
          .orderBy("createdAt", "desc");
      }
      let finalQuery;

      if (lastLogExist) {
        finalQuery = logsRef.startAfter(lastLog);
      } else {
        finalQuery = logsRef;
      }
      finalQuery = finalQuery.limit(10);

      const snapshot = finalQuery.onSnapshot((queryRes) => {
        if (queryRes.docs.length) {
          const docs = queryRes.docChanges();
          let shouldUpdateLastLog = true;
          const newLogs = {};
          for (const doc of queryRes.docs) {
            newLogs[doc.id] = { ...doc.data(), id: doc.id };
          }

          dispatch({
            type: "SET_LOGS",
            payload: newLogs,
          });

          for (const doc of docs) {
            if (doc.type === "modified") {
              shouldUpdateLastLog = false;
            }
          }

          if (shouldUpdateLastLog) {
            dispatch({
              type: "SET_LAST_LOG",
              payload: queryRes.docs[queryRes.docs.length - 1],
            });
          }
        }
      });
      dispatch({
        type: "SET_LOGS_LOADING",
        payload: { loading: false, success: true, error: null },
      });
      dispatch(addLogsSnapshots({ type: "log-snapshot", snapshot }));
    } catch (err) {
      console.log(err.message);

      dispatch({
        type: "SET_LOGS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetLogs = () => ({ type: "RESET_LOGS" });

export const setLogsFilter = (value) => ({
  type: "SET_LOGS_FILTER",
  payload: value,
});

export const resetUploadUsersToPlatformLoading = () => ({
  type: "SET_UPLOAD_USERS_TO_PLATFORM_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetCampaignContributionsDataLoading = () => ({
  type: "FETCH_ADMIN_CAMPAIGN_CONTRIBUTIONS_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetSendEmailLoading = () => ({
  type: "RESET_SEND_EMAIL_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetUserProfileDataLoading = () => ({
  type: "SET_USER_PROFILE_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});

const userTypeDataRecord = async (firestore, userTypes) => {
  let userStatusArray = [];
  try {
    for (let i in userTypes) {
      const doc = firstCharLowerCase(removeSpaceFunction(userTypes[i]));

      const result = await firestore.collection("metaData").doc(doc).get();
      if (result.exists) {
        userStatusArray.push({
          title: userTypes[i],
          value:
            doc === "totalTipToDate"
              ? result.data().totalTip
              : doc === "totalContributionToDate"
              ? result.data().totalContribution
              : doc === "refundsToDate"
              ? result.data().totalRefunds
                ? result.data().totalRefunds
                : 0
              : doc === "oneTimePaidUsers"
              ? result.data().count
              : doc === "totalOneTimePayments"
              ? result.data().count
              : doc === "totalCashInFlowToDate"
              ? result.data().totalCashInFlow
              : doc === "subscriptionToDate"
              ? result.data().totalSubscription
              : doc === "stripeExpensesToDate"
              ? result.data().stripeExpenses
              : doc === "totalDisbursmentToDate"
              ? result.exists
                ? result.data().totalDisbursedToDate
                : 0
              : doc === "Refunds To Date"
              ? result.data()
              : result.exists && result.data().counter,
        });
      } else {
        userStatusArray.push({
          title: userTypes[i],
          value: 0,
        });
      }
    }
    return userStatusArray;
  } catch (err) {
    console.log(err.message);
    return { success: false, message: err.message };
  }
};

const getCausesFromFirebase = async (firestore, doc, causesDataFirebase) => {
  try {
    const causesArray = [];
    const metaDataRef = await firestore.collection("metaData").doc(doc).get();
    if (metaDataRef.exists) {
      const result = metaDataRef.data();

      if (causesDataFirebase.length) {
        for (let i in result) {
          for (let j in causesDataFirebase) {
            const causesData = causesDataFirebase[j];
            if (
              causesData.status === "active" ||
              causesData.status === "general"
            ) {
              if (causesData.id === i) {
                causesArray.push({
                  title: causesData.title,
                  value: result[i].toFixed(2),
                });
              }
            }
          }
        }
      }
    } else {
      const errorObject = {
        message: "No Causes Exists",
      };
      throw errorObject;
    }

    return causesArray;
  } catch (err) {
    console.log(err.message);
  }
};

const getImpactFromFirebase = async (firestore, doc) => {
  try {
    let causesArray = [];
    const metaDataRef = await firestore.collection("metaData").doc(doc).get();
    if (metaDataRef.exists) {
      const result = metaDataRef.data();
      for (let i in result) {
        const item = result[i];
        if (item) {
          causesArray.push({ title: i + " Impact To Date", value: item });
        }
      }
    } else {
      const errorObject = {
        message: "No impact Exists",
      };
      throw errorObject;
    }

    return causesArray;
  } catch (err) {
    console.log(err.message);
    return [];
  }
};
