import React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import { ReactComponent as SettingsIcon } from "../../assets/menu-icons/selectedIcons/SettingsIcon.svg";
import { ReactComponent as CampaignIcon } from "../../assets/menu-icons/selectedIcons/Campaigns.svg";
import { ReactComponent as Contribution } from "../../assets/menu-icons/selectedIcons/Contributions.svg";
import { ReactComponent as Dashboard } from "../../assets/menu-icons/selectedIcons/Dashboard.svg";
import { ReactComponent as Event } from "../../assets/menu-icons/selectedIcons/eventIcon.svg";
import { ReactComponent as TaxReciept } from "../../assets/menu-icons/selectedIcons/tareceipt.svg";
import useBrandInfo from "../../custom-hooks/useGetBrandInfoProperties";

import { useDispatch, useSelector } from "react-redux";
import "./sidebar.styles.scss";
import PrimaryButton from "../primary-button/primary-button.component";
import { signOutAction } from "../../redux/auth/auth.action";
import { checkFormatingOfLabel } from "../../utils/stringFormatting";

const checkActiveItem = (item, location) => {
  if (item.startsWith(location)) {
    return true;
  }
  return false;
};

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const campaignName = useBrandInfo("campaignName");
  const { basicBrandInfo, applicationMode, features } = useSelector(
    (state) => state.InfauqeInstance
  );
  const { events, taxReciepts } = features;
  const user = useSelector((state) => state.user.currentUser);

  return (
    <Nav className="sidebar-component" activeKey="dashboard">
      <div className="parent_side">
        <div className="sidebar-sticky">
          <div className="logo">
            <Link to="/dashboard" data-test="dashboard-button">
              <img src={basicBrandInfo ? basicBrandInfo.logo : ""} alt="logo" />
            </Link>
          </div>
          <div className="options">
            <Nav.Item
              className={
                checkActiveItem("/dashboard", location.pathname) ? "active" : ""
              }
            >
              <Link
                to="/dashboard"
                className="dashboard-button"
                data-test="dashboard-button"
              >
                <Dashboard />
                <p>Dashboard</p>
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                checkActiveItem("/contributions/allCauses", location.pathname)
                  ? "active"
                  : ""
              }
            >
              <Link to="/contributions/allCauses" data-test="contributions">
                <Contribution />
                <p>Contributions</p>
              </Link>
            </Nav.Item>
            <Nav.Item
              className={
                checkActiveItem("/active-campaigns", location.pathname)
                  ? "active"
                  : ""
              }
            >
              <Link to="/active-campaigns" data-test="active-campaigns">
                <CampaignIcon />
                <p>{checkFormatingOfLabel(campaignName)}</p>
              </Link>
            </Nav.Item>
            {applicationMode === "cause-project" && events.active ? (
              <Nav.Item
                className={
                  checkActiveItem("/events", location.pathname) ? "active" : ""
                }
              >
                <Link
                  to="/events"
                  className="events-button"
                  data-test="events-button"
                >
                  <Event className="event-icon" />
                  <p>Events</p>
                </Link>
              </Nav.Item>
            ) : (
              ""
            )}
            {applicationMode === "cause-project" && taxReciepts?.active ? (
              <Nav.Item
                className={
                  checkActiveItem("/user-tax-reciepts", location.pathname)
                    ? "active"
                    : ""
                }
              >
                <Link
                  to="/user-tax-reciepts"
                  className="tax-reciept-button"
                  data-test="tax-reciept-button"
                >
                  <TaxReciept className="tax-reciept-icon" />
                  <p>Tax Receipts</p>
                </Link>
              </Nav.Item>
            ) : (
              ""
            )}
            <Nav.Item
              className={
                checkActiveItem("/settings/personal", location.pathname)
                  ? "active"
                  : ""
              }
            >
              <span className="mandatory-field">
                {!user.birthYear ||
                !user.gender ||
                !user.employeeText ||
                !user.displayName
                  ? "*"
                  : ""}
              </span>{" "}
              <Link to="/settings/personal" data-test="settings">
                <SettingsIcon />

                <p>Settings</p>
              </Link>
            </Nav.Item>
          </div>
          <PrimaryButton
            handleClick={async () => dispatch(signOutAction())}
            type="button"
            className="sign-out-nav-item"
            data-test="sign-out"
          >
            Sign Out
          </PrimaryButton>
        </div>
      </div>
    </Nav>
  );
};

export default SideBar;
