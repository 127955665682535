import firebase from "../firebase/firebaseConfig";
import { setCheckIfUserIsFromEmailLink } from "../redux/auth/auth.action";

export const checkSignInMethodAndUpdateRedux = async (dispatch) => {
  const resp = await firebase
    .auth()
    .fetchSignInMethodsForEmail(firebase.auth().currentUser.email);
  if (resp.includes("emailLink")) {
    dispatch(setCheckIfUserIsFromEmailLink(resp[resp.indexOf("emailLink")]));
  }
};
