import React from "react";

import { ReactComponent as CaretDown } from "../../assets/caret-down.svg";
import { ReactComponent as CaretUp } from "../../assets/caret-up.svg";
import MenuBarLink from "../admin-menu-bar-link/admin-menu-bar-link.component";

import "./admin-menu-bar-category.styles.scss";

const MenuBarCategory = ({ item, handleClick, active }) => {
  const { category, links } = item;
  return (
    <div
      className={`admin-menubar-category-component ${
        active ? "active-category" : ""
      }`}
      data-test={`category-${category}`}
      onClick={handleClick}
    >
      <div className="category-title">
        <span>{category}</span>
        {links ? active ? <CaretUp /> : <CaretDown /> : ""}
      </div>
      <div className={`${active ? "active-category" : ""} inactive-category`}>
        {links &&
          links.map((item, index) =>
            item.permission ? <MenuBarLink key={index} item={item} /> : ""
          )}
      </div>
    </div>
  );
};

export default MenuBarCategory;
