const initialState = {
  emailDesignLoading: { loading: false, success: false, error: false },
  emailConfigurationsLoading: { loading: false, success: false, error: false },
  toggleLoading: { loading: false, success: false, error: false },
  emailConfigurations: [],
  emailConfigurationsById: {},
  emailHistory: [],
  emailHistoryLoading: { loading: false, success: false, error: false },
};

const emailCMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EMAIL_DESIGN_LOADING":
      return { ...state, emailDesignLoading: action.payload };
    case "SET_EMAIL_CONFIGURATION_LOADING":
      return { ...state, emailConfigurationsLoading: action.payload };
    case "SET_TOGGLE_LOADING":
      return { ...state, toggleLoading: action.payload };
    case "SET_EMAIL_CONFIGURATIONS":
      return { ...state, emailConfigurations: action.payload };
    case "SET_EMAIL_CONFIGURATIONS_BY_ID":
      return { ...state, emailConfigurationsById: action.payload };
    case "SET_EMAIL_HISTORY_LOADING":
      return { ...state, emailHistoryLoading: action.payload };
    case "SET_EMAIL_HISTORY":
      return { ...state, emailHistory: action.payload };
    default:
      return state;
  }
};

export default emailCMSReducer;
