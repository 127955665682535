const initialState = {
  tabValue: "Sign In",
};

const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SIGN_IN_TAB":
      return { ...state, tabValue: action.payload };
    default:
      return state;
  }
};

export default tabReducer;
