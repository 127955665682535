import React from "react";

import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import SmallLoader from "../small-loader/small-loader.component";

import "./primary-button.styles.scss";

const PrimaryButton = ({
  handleClick,
  children,
  small,
  cancel,
  disabled,
  gray,
  skipCheck,
  dataTest,
  loading,
  primaryShade,
  redOrange,
  containerClass,
  to,
  ...rest
}) => {
  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (to) {
          history.push(to);
        }
      }}
      className={`primary-button-component ${
        containerClass ? containerClass : ""
      }`}
    >
      <Button
        className={`${skipCheck ? "skip" : ""} ${gray ? "gray" : ""}${
          cancel ? "cancel" : ""
        } ${small ? "small" : ""} ${primaryShade ? "primaryShade" : ""}${
          redOrange ? "redOrange" : ""
        }`}
        disabled={disabled || loading}
        onClick={handleClick}
        data-test={dataTest}
        {...rest}
      >
        <div className="children">
          {loading ? <SmallLoader size="20px" borderSize={"1px"} /> : ""}
          {children}
        </div>
      </Button>
    </div>
  );
};

export default PrimaryButton;
