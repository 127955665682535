import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { signOutAction } from "../../redux/auth/auth.action";

import useFetchFeaturesAndPermissions from "../../custom-hooks/useFetchFeaturesAndPermissions";
import MenuBarCategory from "../admin-menu-bar-category/admin-menu-bar-category.component";
import MenuBarLogo from "../menu-bar-logo/menu-bar-logo.component";
import PrimaryButton from "../primary-button/primary-button.component";

import "./admin-menu-bar.styles.scss";

const AdminMenuBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const menubar = useFetchFeaturesAndPermissions();
  const [activeCategory, setActiveCategory] = useState(-1);

  useEffect(() => {
    const path = history.location.pathname.split("/")[1];
    let selectedIndex = "";
    menubar.forEach((menu, index) => {
      if (menu.links) {
        menu.links.forEach((item) => {
          if (item.link.includes(path)) {
            selectedIndex = index;
          }
        });
      } else {
        if (menu.link.includes(path)) {
          selectedIndex = index;
        }
      }
    });
    setActiveCategory(selectedIndex);
  }, [history.location.pathname, menubar]);

  const handleClick = (index, item) => {
    if (item.link) {
      setActiveCategory(index);
      history.push(item.link);
    } else {
      setActiveCategory(index);
      activeCategory === index
        ? setActiveCategory(-1)
        : setActiveCategory(index);
    }
  };

  const signOutClickHandler = () => {
    dispatch(signOutAction());
  };

  return (
    <div className="admin-menubar-component">
      <div className="admin-menubar-content-container">
        <div className="logo-and-menu-container">
          <MenuBarLogo />

          <div className="admin-menubar-list-container">
            {menubar.map((item, index) =>
              item.links || item.permission ? (
                <MenuBarCategory
                  handleClick={() => handleClick(index, item)}
                  active={activeCategory === index}
                 key={index} 
                  item={item}
                />
              ) : (
                ""
              )
            )}
          </div>
        </div>
        <div className="logout-btn">
          <PrimaryButton
            handleClick={() => signOutClickHandler()}
            type="button"
            className="sign-out-nav-item"
            data-test="log-out"
          >
            Sign Out
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AdminMenuBar;
