export function openInNewTab(href) {
  Object.assign(document.createElement("a"), {
    href: href,
  }).click();
}

export const getValueFromUrl = (search, value) => {
  const onlyValues = search.replaceAll("?", "");
  const arrayByValues = onlyValues.split("&").filter((item) => item);
  const finalObject = {};

  for (const value of arrayByValues) {
    const splitProperties = value.split("=");
    const property = splitProperties[0];
    const propetyValue = splitProperties[1];

    finalObject[property] = propetyValue;
  }

  if (value) {
    return finalObject[value] ? finalObject[value] : null;
  } else {
    return finalObject;
  }
};
