const initialState = {
  paymentLoading: {
    loading: false,
    error: null,
    success: false,
    errorType: null,
  },
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAYMENT_LOADING":
      return {
        ...state,
        paymentLoading: action.payload,
      };

    default:
      return state;
  }
};

export default paymentReducer;
