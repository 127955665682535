const initialState = {
  mobileTitle: "",
  snapshots: [],
  logsSnapshots: [],
  reloadApplicationLoading: false,
  setupApplicationAndDatabsesLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fromCampaignPage: null,
  backArrowRoute: null,
  applciationStartTime: 0,
};

const appSetingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SETUP_APPLICATION_AND_DATABASES_LOADING":
      return { ...state, setupApplicationAndDatabsesLoading: action.payload };
    case "SET_MOBILE_HEADER_TITLE":
      return { ...state, mobileTitle: action.payload };
    case "ADD_FIREBASE_SNAPSHOT_LISTENER":
      return { ...state, snapshots: [...state.snapshots, action.payload] };
    case "ADD_FIREBASE_LOGS_SNAPSHOT":
      return {
        ...state,
        logsSnapshots: [...state.logsSnapshots, action.payload],
      };
    case "RESET_LOGS_SNAPSHOTS":
      return {
        ...state,
        logsSnapshots: [],
      };
    case "RELOAD_APPLICATION_LOADING":
      return { ...state, reloadApplicationLoading: action.payload };
    case "REMOVE_SNAPSHOT_BY_TYPE":
      let newSnapshots = state.snapshots.filter(
        (item) => item.type !== action.payload
      );
      return { ...state, snapshots: newSnapshots };
    case "SET_BACK_ROUTE":
      return { ...state, backArrowRoute: action.payload };
    case "SET_FROM_CAMPAIGN_PAGE":
      return { ...state, fromCampaignPage: action.payload };
    case "SET_APPLICATION_START_TIME":
      return { ...state, applciationStartTime: action.payload };
    default:
      return state;
  }
};

export default appSetingsReducer;
