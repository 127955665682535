export const defaultCampaignChartColorArray = [
  "#F5DE98",
  "#95ADC6",
  "#BED79F",
  "#D8CAAD",
  "#BAD6E1",
  "#A4C9BE",
  "#DABCD5",
  "#FBD3B0",
  "#F0AA94",
  "#b2d1cb",
  "#C3C3BB",
];

export function hexToRgb(hex) {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand;

  return null;
}

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + l + "%)";
};

export const hslToHex = (hsl) => {
  const newHsl = hsl
    .replace("hsl", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("%", "")
    .split(",");
  let h = parseInt(newHsl[0]);
  let s = parseInt(newHsl[1]);
  let l = parseInt(newHsl[2]);
  console.log(h, s, l);
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const giveRandomColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const giveCampaignFundAllocationChartColors = (allocations) => {
  const colorsArray = [];
  for (let i = 0; i < allocations.length; i++) {
    if (defaultCampaignChartColorArray[i]) {
      colorsArray.push(defaultCampaignChartColorArray[i]);
    } else {
      colorsArray.push(giveRandomColor());
    }
  }
  return colorsArray;
};
