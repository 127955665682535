import firebase from "../../firebase/firebaseConfig";

export const setBackRouteEvent = (event) => ({
  type: "SET_SELECTED_EVENT_BACK_ROUTE",
  payload: event,
});

export const fetchAllEvents = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const eventListCollectionRef = firestore
      .collection("Events")
      .orderBy("createdAt", "desc");

    dispatch({
      type: "SET_EVENTS_LIST_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      eventListCollectionRef.onSnapshot(async (eventRef) => {
        const events = [];
        const activeEventsIndexed = {};

        for (const eventDoc of eventRef.docs) {
          const event = {
            ...eventDoc.data(),
            eventId: eventDoc.id,
          };
          events.push({ ...event });
          activeEventsIndexed[eventDoc.id] = { ...event };
        }
        const activeEvents = [];
        for (let i in events) {
          const item = events[i];
          if (item.status && item.status !== "archived") {
            activeEvents.push(item);
          }
        }

        dispatch({
          type: "SET_ALL_EVENTS_LIST",
          payload: [...events],
        });
        dispatch({
          type: "SET_ACTIVE_EVENTS_LIST",
          payload: activeEvents,
        });
        dispatch({
          type: "SET_ACTIVE_EVENTS_LIST_INDEXED",
          payload: activeEventsIndexed,
        });
        dispatch({
          type: "SET_INACTIVE_EVENTS_LIST",
          payload: events.filter((item) => !item.status),
        });
        dispatch({
          type: "SET_ARCHIVED_EVENTS_LIST",
          payload: events.filter((item) => item.status === "archived"),
        });

        dispatch({
          type: "SET_EVENTS_LIST_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_EVENTS_LIST_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchAllEventDraft = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    const eventListCollectionRef = firestore.collection("EventDrafts");

    dispatch({
      type: "SET_EVENT_DRAFTS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      eventListCollectionRef.onSnapshot(async (eventRef) => {
        const events = [];
        for (const eventDoc of eventRef.docs) {
          const event = {
            ...eventDoc.data(),
            eventId: eventDoc.id,
          };
          events.push(event);
        }
        console.log("events-drafts", events);
        dispatch({
          type: "SET_ALL_EVENT_DRAFTS",
          payload: events,
        });

        dispatch({
          type: "SET_EVENT_DRAFTS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_EVENT_DRAFTS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetCreateEventDataLoading = () => ({
  type: "SET_EVENT_CREATION_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const createEvent = (data, draft, emailData) => async (dispatch) => {
  try {
    if (emailData) {
      data = { ...data, emailData };
    }
    data = { ...data, draft };

    console.log("actionData", data);
    let response;
    dispatch({
      type: "SET_EVENT_CREATION_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    const createEventToFirebase = firebase
      .functions()
      .httpsCallable("createEventToFirebase");
    response = await createEventToFirebase(data);
    console.log("response", response);
    if (response.data.success) {
      dispatch({
        type: "SET_EVENT_CREATION_LOADING",
        payload: {
          loading: false,
          success: { ...response.data },
          error: null,
          draft,
        },
      });
    } else {
      const errorObject = {
        status: 400,
        message: response.data.errorMessage,
      };
      throw errorObject;
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: "SET_EVENT_CREATION_LOADING",
      payload: { loading: false, success: false, error: err.message },
    });
  }
};

export const fetchUserTickets = (uid) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log("uidCheck", uid);

    const eventTicketsRef = firestore
      .collection("EventTicketsProceeds")
      .where("UID", "==", uid)
      .orderBy("TimeValue", "desc");

    console.log("why didn't run");
    dispatch({
      type: "SET_EVENT_TICKET_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      eventTicketsRef.onSnapshot(async (eventRef) => {
        const eventTickets = [];
        for (const eventDoc of eventRef.docs) {
          const event = {
            ...eventDoc.data(),
            id: eventDoc.id,
          };
          if (event.reverse) {
            continue;
          }

          eventTickets.push(event);
        }
        dispatch({
          type: "SET_ALL_TICKETS",
          payload: eventTickets,
        });
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_EVENT_TICKET_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};
