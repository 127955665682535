const initialState = {
  choosenCategory: null,
  personalInfo: { name: "", email: "" },
  seletecProject: null,
  pageNumberPagination: null,
  personalEmail: "",
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHOOSEN_CATEGORY":
      return { ...state, choosenCategory: action.payload };
    case "SET_PERSONAL_INFO":
      return { ...state, personalInfo: action.payload };
    case "SET_PERSONAL_EMAIL":
      return { ...state, personalEmail: action.payload };
    case "SET_SELECTED_PROJECT":
      return { ...state, seletecProject: action.payload };
    case "SET_PAGE_INDEX_PAGINATION":
      return { ...state, pageNumberPagination: action.payload };

    default:
      return state;
  }
};

export default categoryReducer;
