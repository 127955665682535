import React from "react";

const AdminLogs = React.lazy(() =>
  import("./pages/admin-page/admin-logs/admin-logs.page")
);
const Contributions = React.lazy(() =>
  import("./pages/user-pages/contributions/contributions.component")
);
const DisbursementDetailPage = React.lazy(() =>
  import("./pages/disbursement-detail-page/disbursement-detail-page.component")
);

const DashboardPage = React.lazy(() =>
  import("./pages/dashboard-page/dashboard-page.page")
);

const UploadCsv = React.lazy(() =>
  import("./pages/admin-page/uploadCsv/uploadCsvForUser.component")
);

const UserCampaign = React.lazy(() =>
  import("./pages/user-pages/user-campaign/user-campaign.page")
);
const UserEvent = React.lazy(() =>
  import("./pages/user-event/user-event.component")
);
const CauseProject = React.lazy(() =>
  import("./pages/user-pages/cause-project/cause-project.page")
);
const AdminCampaign = React.lazy(() =>
  import("./pages/admin-page/admin-campaign/admin-campaign.page")
);
const AdminEvents = React.lazy(() =>
  import("./pages/admin-page/admin-events/admin-events.component")
);
const AdminCauseProjects = React.lazy(() =>
  import("./pages/admin-page/admin-cause-projects/admin-cause-projects.page")
);

const Organization = React.lazy(() =>
  import("./pages/user-pages/organization/organization.component")
);
const UserSettings = React.lazy(() =>
  import("./pages/user-pages/user-dashboard/userProfile/userProfile.component")
);
const UnsubscriptionFromMails = React.lazy(() =>
  import(
    "./pages/unsubscription-from-mails/unsubscription-from-mails.component"
  )
);
const AdminDashboard = React.lazy(() =>
  import("./pages/admin-page/admin-page")
);
const UserProfileDashboard = React.lazy(() =>
  import("./pages/admin-page/user-profile-page/user-profile.page")
);

const EmailType = React.lazy(() =>
  import("./pages/email-cms/email-types/email-types.component")
);
const EmailHistory = React.lazy(() =>
  import("./pages/admin-page/admin-email-history/admin-email-history.component")
);
const EmailCMS = React.lazy(() =>
  import("./pages/email-cms/email-cms.component")
);
const TransferPage = React.lazy(() =>
  import("./pages/transfer-page/transfer-page")
);
const TransferDetailPage = React.lazy(() =>
  import("./pages/transfer-detail-page/transfer-detail-page")
);
const WithdrawPage = React.lazy(() =>
  import("./pages/withdraw-page/withdraw-page")
);
const TipsPage = React.lazy(() => import("./pages/tips-page/tips-page"));
const OrganizationPage = React.lazy(() =>
  import("./pages/organization-page/organization-page")
);
const Login = React.lazy(() =>
  import("./pages/sign-in-sign-up-page/sign-in-sign-up-page")
);

const Confirmation = React.lazy(() =>
  import("./pages/confirmation-page/confirmation")
);
const ManageCausePage = React.lazy(() =>
  import("./pages/manageCause-page/manageCause-page")
);
const ActiveCampaigns = React.lazy(() =>
  import("./pages/active-campaigns-page/active-campaigns.page")
);

const EventsPage = React.lazy(() => import("./pages/events-page/events-page"));
const CMSBasicInfo = React.lazy(() =>
  import("./pages/cms-pages/cms-basic-info/cms-basicInfo.component")
);

const BrandingColors = React.lazy(() =>
  import("./pages/admin-page/brandingColors/brandingColors.component")
);
const ToggleFeature = React.lazy(() =>
  import("./pages/admin-page/toggle-features/toggle-features.component")
);
const AdminCausePage = React.lazy(() =>
  import("./pages/manageCause-page/manageCause-modal/admin-cause.page")
);
const BrandingFeatures = React.lazy(() =>
  import("./pages/admin-page/brandingFeatures/brandingFeatures.component")
);
const AllAdmins = React.lazy(() =>
  import("./pages/admin-page/allAdmins/allAdmins.component")
);
const DonationPage = React.lazy(() =>
  import("./pages/donation-page/donation.page")
);

const signUpWithEmailPass = React.lazy(() =>
  import("./pages/sign-up-with-emailPass/signUp-with-emailPass.component")
);
const AfterSignUpVerify = React.lazy(() =>
  import(
    "./components/after-sign-up-resend-email/after-signUp-verify.component"
  )
);
const EmailAction = React.lazy(() =>
  import("./components/emailAction/emailAction.component")
);
const ForgetPasswordForm = React.lazy(() =>
  import("./components/forget-password-form/forget-password-form.component")
);
const SignInWithMagic = React.lazy(() =>
  import("./components/email-magic-link/sign-in-with-magic.component")
);
const TicketConfirmation = React.lazy(() =>
  import("./components/ticket-confirmation/ticket-confirmation.component")
);
const DisbursementPageParent = React.lazy(() =>
  import(
    "./pages/admin-page/disbursement-parent-page/disbursement-parent.component"
  )
);
const AllUsersParent = React.lazy(() =>
  import("./pages/admin-page/all-users-parent/all-users-parent.component")
);
const UserFeedbackTable = React.lazy(() =>
  import("./pages/admin-page/user-feedback-table/user-feedback-table.component")
);

const EventPayment = React.lazy(() =>
  import("./pages/event-payment/event-payment.component")
);
const AllUserTransactionParent = React.lazy(() =>
  import(
    "./pages/all-users-page/all-users-transaction-based-parent/all-users-transaction-based-parent.component"
  )
);
const UserTaxReciepts = React.lazy(() =>
  import("./pages/users-tax-reciepts/users-tax-reciepts.component")
);
const publicRoutes = [
  {
    path: "/campaign/:organizationId/:campaignId",
    component: UserCampaign,
    name: "UserCampaign",
  },
  {
    path: "/events/:eventId",
    component: UserEvent,
    name: "UserEvent",
  },
  {
    path: "/cause-project/:campaignId",
    component: CauseProject,
    name: "CauseProject",
  },
  {
    path: "/organization/:organizationId",
    component: Organization,
    name: "Organization",
  },
  {
    path: "/active-campaigns",
    component: ActiveCampaigns,
    name: "ActiveCampaigns",
    exact: true,
  },
  {
    path: "/active-campaigns/:causeId",
    component: ActiveCampaigns,
    name: "ActiveCampaigns",
    exact: true,
  },

  {
    path: "/event-payment/:eventId",
    component: EventPayment,
    name: "EventPayment",
    exact: true,
  },
  {
    path: "/events",
    component: EventsPage,
    name: "Events",
    exact: true,
  },
  {
    path: "/events:type",
    component: EventsPage,
    name: "Events",
    exact: true,
  },
  {
    path: "/donate-directly/contribution",
    component: DonationPage,
    name: "DonationPage",
  },
];

const commonPaths = [
  {
    path: "/unsubscribe",
    component: UnsubscriptionFromMails,
    name: "Unsubscribe",
  },
  {
    path: "/confirmation",
    component: Confirmation,
    name: "Confirmation",
    exact: true,
  },
];

export const loggedOutRoutes = [
  {
    path: "/password-less-signIn",
    component: SignInWithMagic,
    name: "SignInWithMagic",
    exact: true,
  },

  { path: "/action", component: EmailAction, name: "EmailAction" },
  {
    path: "/sign-up-with-emailpass",
    component: signUpWithEmailPass,
    name: "signUpWithEmailPass",
    exact: true,
  },
  {
    path: "/recover-password",
    component: ForgetPasswordForm,
    name: "ForgetPasswordForm",
    exact: true,
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  ...publicRoutes,
  ...commonPaths,
];

export const nonVerifiedEmail = [
  {
    path: "/verify-email",
    component: AfterSignUpVerify,
    name: "AfterSignUpVerify",
    exact: true,
  },
  { path: "/action", component: EmailAction, name: "EmailAction" },
];

export const explorPlatformTrue = [
  {
    path: "/contributions/:causeId",
    component: Contributions,
    name: "Contributions",
  },
  {
    path: "/settings/:pageName",
    component: UserSettings,
    name: "UserSettingsPage",
  },
  {
    path: "/dashboard",
    component: DashboardPage,
    name: "DashboardPage",
    exact: true,
  },
  {
    path: "/ticket-confirmation",
    component: TicketConfirmation,
    name: "TicketConfirmation",
    exact: true,
  },
  {
    path: "/user-tax-reciepts",
    component: UserTaxReciepts,
    name: "UserTaxReciepts",
    exact: true,
  },
  ...publicRoutes,
  ...commonPaths,
];

export const explorPlatformFalse = [
  ...commonPaths,
  {
    path: "/donate-directly/contribution",
    component: DonationPage,
    name: "DonationPage",
  },
];

export const adminRoutes = [
  {
    path: "/web-basicInfo",
    component: CMSBasicInfo,
    name: "CMSBasicInfo",
    exact: true,
  },
  {
    path: "/web-branding",
    component: BrandingColors,
    name: "BrandingColors",
    exact: true,
  },

  {
    path: `/campaigns/:campaignPage/:id`,
    component: AdminCampaign,
    name: "AdminCampaignPage",
  },
  {
    path: `/events/:eventPage/:id`,
    component: AdminEvents,
    name: "AdminEventPage",
  },
  {
    path: `/events/:eventPage`,
    component: AdminEvents,
    name: "AdminEventPage",
  },
  {
    path: `/campaigns/:campaignPage`,
    component: AdminCampaign,
    name: "AdminCampaignPage",
    exact: true,
  },
  {
    path: "/transfer-page",
    component: TransferPage,
    name: "TransferPage",
    exact: true,
  },
  {
    path: "/users-transactions/:id",
    component: AllUserTransactionParent,
    name: "AllUserTransactionParent",
    exact: true,
  },
  {
    path: "/transfer-detail-page/:id",
    component: TransferDetailPage,
    name: "TransferDetailPage",
  },
  {
    path: "/email-cms",
    component: EmailType,
    name: "EmailType",
    exact: true,
  },
  {
    path: "/email-cms/:id",
    component: EmailCMS,
    name: "EmailCMS",
  },
  {
    path: "/donor-feedback",
    component: UserFeedbackTable,
    name: "UserFeedbackTable",
  },
  {
    path: "/sent-emails",
    component: EmailHistory,
    name: "EmailHistory",
  },
  {
    path: "/all-admins",
    component: AllAdmins,
    name: "AllAdmins",
    exact: true,
  },
  {
    path: "/upload-user-data",
    component: UploadCsv,
    name: "UploadCsv",
    exact: true,
  },
  {
    path: "/manage-causes/:action",
    component: AdminCausePage,
    name: "AdminCausePage",
    exact: true,
  },
  {
    path: "/manage-causes",
    component: ManageCausePage,
    name: "ManageCausePage",
    exact: true,
  },
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
    name: "AdminDashboard",
    exact: true,
  },
  {
    path: "/project-page",
    component: OrganizationPage,
    name: "OrganizationPage",
    exact: true,
  },
  {
    path: "/disbursement-page",
    component: DisbursementPageParent,
    name: "DisbursementPage",
    exact: true,
  },
  {
    path: "/withdraw-page",
    component: WithdrawPage,
    name: "WithdrawPage",
    exact: true,
  },
  {
    path: "/disbursement-detail-page/:id",
    component: DisbursementDetailPage,
    name: "DisbursementDetailPage",
  },
  {
    path: "/tips-page",
    component: TipsPage,
    name: "TipsPage",
    exact: true,
  },
  {
    path: "/all-users/:id",
    component: AllUsersParent,
    name: "AllUsers",
  },
  {
    path: "/user-profile-dashboard",
    component: UserProfileDashboard,
    name: "UserProfileDashboard",
    exact: true,
  },
  // {
  //   path: "/web-configuration",
  //   component: Configuration,
  //   name: "ConfigurationPage",
  //   exact: true,
  // },
  {
    path: "/cause-projects/:id",
    component: AdminCauseProjects,
    name: "CauseProjects",
    exact: true,
  },
  {
    path: "/logs",
    component: AdminLogs,
    name: "AdminLogs",
    exact: true,
  },

  ...commonPaths,
];

export const systemAdminRoutes = [
  {
    path: "/admin-features",
    component: ToggleFeature,
    name: "ToggleFeature",
    exact: true,
  },
  {
    path: "/donor-feedback",
    component: UserFeedbackTable,
    name: "UserFeedbackTable",
  },
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
    name: "AdminDashboard",
    exact: true,
  },
  {
    path: "/organization-page",
    component: OrganizationPage,
    name: "OrganizationPage",
    exact: true,
  },
  {
    path: "/brandingFeatures",
    component: BrandingFeatures,
    name: "BrandingFeatures",
    exact: true,
  },

  ...commonPaths,
];
