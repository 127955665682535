import { checkForErrors } from "../../utils/errorFunctions";
import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const setCausesFirebaseData = (causesData) => ({
  type: "SET_CAUSES_DATA_FIREBASE",
  payload: causesData,
});

export const setCausesFirestoreData =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({
      type: "SET_CAUSE_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const createCause = firebase.functions().httpsCallable("createCause");
      console.log(data);
      const result = await createCause(data);
      const res = result.data;
      checkForErrors(res);
      dispatch({
        type: "SET_CAUSE_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "SET_CAUSE_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: error.message,
        },
      });
    }
  };

export const resetCauseDataLoading = () => ({
  type: "SET_CAUSE_DATA_LOADING",
  payload: {
    loading: false,
    success: false,
    error: null,
  },
});

export const getFollowedCauses =
  (data) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const user = getState().user.currentUser;
    const firestore = getFirestore();
    try {
      if (user.uid) {
        const docRef = firestore.collection("users").doc(user.uid);
        await docRef.onSnapshot((snapshot) => {
          let causeData = getState().causes.causesDataFirebase;
          const followedCauses = snapshot.data().followedCauses
            ? snapshot.data().followedCauses
            : [];
          if (followedCauses.length > 0) {
            let causesDataFirebase = [...causeData];
            for (let i in followedCauses) {
              const item = followedCauses[i];
              for (let j in causesDataFirebase) {
                const Nitem = causesDataFirebase[j];
                if (Nitem.id === item) {
                  Nitem.selected = true;
                }
              }
            }
            dispatch(setCausesFirebaseData(causesDataFirebase));
          }
          dispatch({
            type: "SET_FOLLOWED_CAUSES",
            payload: followedCauses,
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const fetchTransferDataFromFirebase = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const transferCollectionRef = firestore
      .collection("transfer-funds")
      .orderBy("lastUpdated", "desc");
    dispatch({
      type: "SET_TRANSFER_DATA_FIRESTORE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      await transferCollectionRef.onSnapshot((snapshot) => {
        let transferRecord = [];
        for (const index in snapshot.docs) {
          const doc = snapshot.docs[index];
          transferRecord.push({ id: doc.id, ...doc.data() });
        }
        if (snapshot.size === transferRecord.length) {
          dispatch({
            type: "SET_TRANSFER_DATA_FIRESTORE",
            payload: transferRecord,
          });

          dispatch({
            type: "SET_TRANSFER_DATA_FIRESTORE_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        }
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_TRANSFER_DATA_FIRESTORE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchTransferDetailFromFirebase = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    console.log("transferData", id);
    const firestore = getFirestore();
    const transferDocRef = firestore.collection("transfer-funds").doc(id);
    const globalTransfersRef = transferDocRef.collection("global-transfers");
    const individualTransferRef = transferDocRef.collection(
      "individual-transfers"
    );

    dispatch({
      type: "SET_TRANSFER_DETAIL_FIRESTORE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      let transferUnsubscribe = transferDocRef.onSnapshot((transferDocRes) => {
        let transferData = {};
        const transferDocData = transferDocRes.data();
        transferData = {
          id: transferDocRes.id,
          ...transferDocData,
        };
        console.log("transferData", transferData);
        dispatch({
          type: "SET_TRANSFER_DETAIL_FIRESTORE",
          payload: { data: transferData },
        });
      });
      let globalTransferUnsubscribe = globalTransfersRef.onSnapshot(
        (transferGlobalRes) => {
          let globalTransferList = [];
          for (let index in transferGlobalRes.docs) {
            const doc = transferGlobalRes.docs[index];
            const data = doc.data();

            globalTransferList.push({
              ...data,
              status: returnStatuses(data.status),

              globalBalanceDataQLDB: returnStatuses(
                data.globalBalanceDataQLDB.status
              ),
              id: doc.id,
            });
          }
          dispatch({
            type: "SET_TRANSFER_DETAIL_FIRESTORE",
            payload: { global: globalTransferList },
          });
        }
      );

      let individualTransferUnsubscribe = individualTransferRef.onSnapshot(
        (transferIndividualRes) => {
          let individualTransferList = [];
          for (let index in transferIndividualRes.docs) {
            const doc = transferIndividualRes.docs[index];
            const data = doc.data();

            individualTransferList.push({
              ...data,
              status: returnStatuses(data.status),
              id: doc.id,
              debitCauseData: {
                status: returnStatuses(data.debitCauseData.status),
              },
              debitCauseDataQLDB: {
                status: returnStatuses(data.debitCauseDataQLDB.status),
              },
              creditCauseData: {
                status: returnStatuses(data.creditCauseData.status),
              },
              creditCauseDataQLDB: {
                status: returnStatuses(data.creditCauseDataQLDB.status),
              },
            });
          }
          dispatch({
            type: "SET_TRANSFER_DETAIL_FIRESTORE",
            payload: { individual: individualTransferList },
          });
        }
      );
      dispatch({
        type: "SET_TRANSFER_DETAIL_FIRESTORE_LOADING",
        payload: { loading: false, success: true, error: null },
      });

      dispatch({
        type: "SET_TRANSFER_DETAIL_FIRESTORE_SNAPSHOT_SUBSCRIPTIONS",
        payload: {
          data: transferUnsubscribe,
          global: globalTransferUnsubscribe,
          individual: individualTransferUnsubscribe,
        },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_TRANSFER_DETAIL_FIRESTORE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchCausesData =
  () =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch({
        type: "FETCH_CAUSE_DATA_LOADING",
        payload: {
          loading: true,
          success: false,
          error: null,
          causeLoaded: false,
        },
      });
      const firestore = getFirestore();
      let causesSnapshot = firestore
        .collection("Causes")
        .onSnapshot(async (querySnapshot) => {
          const causesDataFirebase = [];
          querySnapshot.forEach((doc) => {
            if (!doc.empty) {
              const causesDataFirebaseObject = {
                id: doc.id,
                selected: false,
                ...doc.data(),
              };
              causesDataFirebase.push(causesDataFirebaseObject);
            } else {
              const errorObject = "No Cause Exists";
              throw errorObject;
            }
          });
          const activeCauses = causesDataFirebase.filter(
            (cause) => cause.status === "active"
          );
          const disableCause = causesDataFirebase.filter(
            (item) => item.status === "disabled"
          );
          const inActiveCauses = causesDataFirebase.filter(
            (item) => item.status === "inactive"
          );
          const privateCauses = causesDataFirebase.filter(
            (item) => item.status === "general"
          );
          const newCauseInfo = [...activeCauses, ...privateCauses];

          dispatch({
            type: "SET_CAUSES_DATA_FIREBASE",
            payload: newCauseInfo,
          });
          dispatch({
            type: "SET_ARCHIVED_CAUSES",
            payload: disableCause,
          });
          dispatch({
            type: "SET_INACTIVE_CAUSES",
            payload: inActiveCauses,
          });
          dispatch({
            type: "SET_ACTIVE_CAUSES",
            payload: activeCauses,
          });
          dispatch(setPrivateCauses(privateCauses));
          dispatch({
            type: "SET_ALL_CAUSES",
            payload: [...newCauseInfo, ...disableCause, ...inActiveCauses],
          });

          dispatch({
            type: "FETCH_CAUSE_DATA_LOADING",
            payload: {
              loading: false,
              success: true,
              error: null,
              causeLoaded: true,
            },
          });
        });
      dispatch(
        addSnapshotsListners({
          type: "causes-snapshot",
          snapshot: causesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "FETCH_CAUSE_DATA_LOADING",
        payload: {
          loading: false,
          success: true,
          error: err.message,
          causeLoaded: true,
        },
      });
    }
  };

export const setPrivateCauses = (causes) => ({
  type: "SET_PRIVATE_CAUSES",
  payload: causes,
});

const returnStatuses = (status) => {
  if (status.error) {
    return "Error";
  } else if (status.success) {
    return "Success";
  } else if (status.inProgress) {
    return "In Progress";
  } else {
    return "idle";
  }
};
