import React from "react";

import PrimaryButton from "../primary-button/primary-button.component";

import { useSelector } from "react-redux";

import "./public-page-header.styles.scss";
import { useLocation } from "react-router";
import { useState } from "react";

const PublicPageHeader = () => {
  const location = useLocation();
  const { publicPageHeadersLogo, websiteName, logo, websiteLink } = useSelector(
    (state) => state.InfauqeInstance.basicBrandInfo
  );
  const [path, setPath] = useState("/login");

  React.useEffect(() => {
    if (location.pathname.startsWith("/campaign")) {
      setPath(`/login?redirectUrl=${location.pathname}`);
    } else {
      setPath("/login");
    }
  }, [location]);

  return (
    <div className='public-page-header-component'>
      <div className='public-page-header-logo-container'>
        <a href={websiteLink}>
          <img
            src={publicPageHeadersLogo ? publicPageHeadersLogo : logo}
            alt={websiteName}
          />
        </a>
      </div>
      <div className='public-page-header-join-us-container'>
        <PrimaryButton to={path}>Sign Up</PrimaryButton>
      </div>
    </div>
  );
};

export default PublicPageHeader;
