import { expressionToCheckHTMLTags } from "./regularExpressions";

export const reverse = (s) => {
  return [...s].reverse().join("");
};

export const lastNameSplitFunction = (value) => {
  const result = value.split(" ").slice(-1).join(" ").toLowerCase();
  return result;
};

export const getAmountWithCommas = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
  const formattedAmount = formatter.format(amount);
  return formattedAmount;
};

export const removeSpaceFunction = (value) => {
  const result = value.replace(/\s+/g, "");
  return result;
};

export const firstCharLowerCase = (value) => {
  const result = value.charAt(0).toLowerCase() + value.slice(1);
  return result;
};

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const splitWithSpace = (value) => {
  const result = value.split(" ");
  return result;
};
export const addSpaceAndCapitalize = (value) => {
  const result = value.replace(/([A-Z])/g, " $1").trim();

  return capitalizeFirstLetter(result);
};

export function getRandomId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const filterTextFromHtmlTags = (value) => {
  return value.replace(expressionToCheckHTMLTags, "");
};

export const checkFormatingOfLabel = (text, noS) => {
  let finalText = text;
  if (noS) {
    if (text.endsWith("s")) {
      finalText = text.slice(0, -1);
    }
  } else if (!text.endsWith("s")) {
    finalText = finalText + "s";
  }
  return capitalizeFirstLetter(finalText);
};
