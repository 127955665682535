const initialState = {
  allUsers: [],
  unsubscribers: [],
  subscribers: [],
  potentialUsers: [],
  newDonors: [],
  oneTimeDonors: [],
  topDonors: [],

  allUsersDataLoading: {
    loading: true,
    error: null,
    success: false,
  },
  unsubscribersLastDoc: "",
  sendEmailForAllUsersLoading: { loading: false, error: null, success: false },
  preferencesLoading: { loading: false, error: null, success: false },
  allUserQueryLength: null,
  selectedList: [],
  seeAllUsers: false,
  serviceBasedLoading: { loading: false, error: null, success: false },
  serviceBasedData: [],
  serviceBasedDataCustom: [],
  serviceBasedDataCustomLoading: {
    loading: false,
    error: null,
    success: false,
  },
  feedbackLoading: { loading: false, error: null, success: false },
  serviceBasedDataDisbursed: [],
  serviceBasedDataNonDisbursed: [],
  serviceBasedFilter: null,
  preferencesUser: "",
  totalCashFlowLoading: {
    loading: false,
    error: null,
    success: false,
    message: "",
  },
  totalCashFlowData: [],
  totalCashFlowDataStripe: [],
  transactionGroupByChecked: null,
  ServiceGroupByChecked: null,
  emailSent: false,
  allUserUidIndexedArray: {},
  searchValue: "",
  serviceBasedDateSelected: { startDate: "", endDate: "" },
  projectBasedDateSelected: { startDate: "", endDate: "" },
  transactionBasedDateSelected: { startDate: "", endDate: "" },
  saveFilterToDataBaseLoading: { loading: false, error: null, success: false },
  donorFeedbackLoading: { loading: false, error: null, success: false },
  donorFeedbackData: [],
  fetchFilterfromDataBaseLoading: {
    loading: false,
    error: null,
    success: false,
  },
  deleteFilterFromDatabaseLoading: {
    loading: false,
    error: null,
    success: false,
  },
  filterFromDatabse: [],
  filterIsChanged: false,
  causeBasedActiveTag: "",
  causeDateFilterSelected: false,
  projectDateFilterSelected: true,
  transactionDateFilterSelected: true,
  lastFilteredData: [],
  lastFilteredDataTransaction: [],
};
const allUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ALL_USERS_DATA_LOADING":
      return { ...state, allUsersDataLoading: action.payload };
    case "SAVE_FILTER_TO_DATABASE_LOADING":
      return { ...state, saveFilterToDataBaseLoading: action.payload };
    case "FETCH_DONORS_FEEDBACK_LOADING":
      return { ...state, donorFeedbackLoading: action.payload };
    case "FETCH_DONORS_FEEDBACK_DATA":
      return { ...state, donorFeedbackData: action.payload };
    case "SET_LAST_FILTERED_DATA":
      return { ...state, lastFilteredData: action.payload };
    case "SET_LAST_FILTERED_DATA_TRANSACTIONS":
      return { ...state, lastFilteredDataTransaction: action.payload };
    case "FETCH_FILTER_TO_DATABASE_LOADING":
      return { ...state, fetchFilterfromDataBaseLoading: action.payload };
    case "DELETE_FILTER_TO_DATABASE_LOADING":
      return { ...state, deleteFilterFromDatabaseLoading: action.payload };
    case "SET_CAUSE_DATE_FILTER_SELECTED":
      return { ...state, causeDateFilterSelected: action.payload };
    case "SET_PROJECT_DATE_FILTER_SELECTED":
      return { ...state, projectDateFilterSelected: action.payload };
    case "SET_TRANSACTION_DATE_FILTER_SELECTED":
      return { ...state, transactionDateFilterSelected: action.payload };
    case "FILTERS_FROM_DATABASE":
      return { ...state, filterFromDatabse: action.payload };
    case "SET_CAUSE_BASED_ACTIVE_TAG":
      return { ...state, causeBasedActiveTag: action.payload };

    case "SET_ALL_USERS_DATA":
      return { ...state, allUsers: action.payload };
    case "SET_FILTER_IS_CHANGED":
      return { ...state, filterIsChanged: action.payload };

    case "SET_PREFERENCES_USER":
      return { ...state, preferencesUser: action.payload };
    case "SET_ALL_USERS_UID_INDEXED_ARRAY":
      return { ...state, allUserUidIndexedArray: action.payload };
    case "RESET_ALL_USERS_DATA":
      return { ...state, allUsers: [] };
    case "SET_ALL_USERS_COUNT":
      return { ...state, allUsersCount: action.payload };
    case "SET_ALL_USERS_LIST_LAST_DOC":
      return { ...state, allUsersLastDoc: action.payload };
    case "SET_PREFERENCES_LOADING":
      return { ...state, preferencesLoading: action.payload };
    case "SET_FEEDBACK_LOADING":
      return { ...state, feedbackLoading: action.payload };

    //unsubscribers
    case "SET_ALL_UNSUBSCRIBERS_DATA":
      return {
        ...state,
        unsubscribers: [...state.unsubscribers, ...action.payload],
      };
    case "RESET_ALL_UNSUBSCRIBERS_DATA":
      return { ...state, unsubscribers: [] };
    case "SET_ALL_UNSUBSRIBERS_LIST_LAST_DOC":
      return { ...state, unsubscribersLastDoc: action.payload };

    //subscribers
    case "SET_ALL_SUBSCRIBERS_DATA":
      return {
        ...state,
        subscribers: [...state.subscribers, ...action.payload],
      };
    case "RESET_ALL_SUBSCRIBERS_DATA":
      return { ...state, subscribers: [] };

    //potentialUsers
    case "SET_ALL_POTENTIAL_USERS_DATA":
      return {
        ...state,
        potentialUsers: [...state.potentialUsers, ...action.payload],
      };
    case "RESET_ALL_POTENTIAL_USERS_DATA":
      return { ...state, potentialUsers: [] };

    //newDonors
    case "SET_ALL_DONORS_DATA":
      return { ...state, newDonors: [...state.newDonors, ...action.payload] };
    case "RESET_ALL_DONORS_DATA":
      return { ...state, newDonors: [] };

    //oneTimeDonors
    case "SET_ALL_ONE_TIME_DONORS":
      return {
        ...state,
        oneTimeDonors: [...state.oneTimeDonors, ...action.payload],
      };
    case "RESET_ALL_ONE_TIME_DONORS":
      return { ...state, oneTimeDonors: [] };

    //topDonors
    case "SET_ALL_TOP_DONORS":
      return { ...state, topDonors: [...state.topDonors, ...action.payload] };
    case "RESET_ALL_TOP_DONORS":
      return { ...state, topDonors: [] };

    case "SEND_EMAIL_FOR_ALL_USERS_LOADING":
      return { ...state, sendEmailForAllUsersLoading: action.payload };
    case "RESET_SEND_EMAIL_FOR_ALL_USERS_LOADING":
      return { ...state, sendEmailForAllUsersLoading: action.payload };
    case "SET_ALL_USER_QUERY_LENGTH":
      return { ...state, allUserQueryLength: action.payload };
    case "SET_SELECTED_LIST":
      return { ...state, selectedList: action.payload };
    case "SET_SEE_ALL_USERS":
      return { ...state, seeAllUsers: action.payload };
    case "SET_SERVICE_BASED_LOADING":
      return { ...state, serviceBasedLoading: action.payload };
    case "SET_SERVICE_BASED_DATA":
      return { ...state, serviceBasedData: action.payload };
    case "SET_SERVICE_BASED_DATA_CUSTOM":
      return { ...state, serviceBasedDataCustom: action.payload };
    case "SET_SERVICE_BASED_DATA_CUSTOM_LOADING":
      return { ...state, serviceBasedDataCustomLoading: action.payload };
    case "SET_SERVICE_BASED_DISBURSED":
      return { ...state, serviceBasedDataDisbursed: action.payload };
    case "SET_SERVICE_BASED_NON_DISBURSED":
      return { ...state, serviceBasedDataNonDisbursed: action.payload };
    case "SET_SERVICE_BASED_FILTERS":
      return { ...state, serviceBasedFilter: action.payload };
    case "SET_TOTAL_CASH_FLOW_LOADING":
      return { ...state, totalCashFlowLoading: action.payload };
    case "SET_TOTAL_CASH_FLOW_DATA":
      return { ...state, totalCashFlowData: action.payload };
    case "SET_TOTAL_CASH_FLOW_DATA_STRIPE":
      return { ...state, totalCashFlowDataStripe: action.payload };
    case "SET_TRANSACTION_GROUP_BY_CHECKED":
      return { ...state, transactionGroupByChecked: action.payload };
    case "SET_SERVICE_GROUP_BY_CHECKED":
      return { ...state, ServiceGroupByChecked: action.payload };
    case "SET_EMAIL_SENT":
      return { ...state, emailSent: action.payload };
    case "SET_SEARCH_VALUE_FOR_USER_PROFILE":
      return { ...state, searchValue: action.payload };
    case "SET_SERVICE_BASED_DATE_SELECTOR":
      return { ...state, serviceBasedDateSelected: action.payload };
    case "SET_PROJECT_BASED_DATE_SELECTOR":
      return { ...state, projectBasedDateSelected: action.payload };
    case "SET_TRANSACTION_BASED_DATE_SELECTOR":
      return { ...state, transactionBasedDateSelected: action.payload };
    default:
      return state;
  }
};

export default allUsersReducer;
