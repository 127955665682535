const initialState = {
  formError: null,
  execError: null,
};

const formErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FORM_ERROR":
      return { ...state, formError: action.payload };
    case "SET_EXECANDDIREC_ERROR":
      return { ...state, execError: action.payload };
    case "RESET_ORGANIZATION_ERRORS":
      return { ...state, formError: null, execError: null };
    default:
      return state;
  }
};

export default formErrorReducer;
