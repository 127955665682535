import moment from "moment";

const monthsArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const converTimeValueToDateObject = (value) => new Date(value);
export const convertDateObjectToTimeValue = (date) => new Date(date).getTime();

export const convertTimeValueToMonthAndYearFormat = (timeValue) => {
  const date = new Date(timeValue);
  const returnDate = `${monthsArray[date.getMonth()]} ${date.getFullYear()}`;
  return returnDate;
};

export const convertTimeToCompleteTimeWithDateAndTimeZoneFormat = (time) => {
  var x = new Date(time);

  var month = x.getMonth() + 1;
  month = month < 10 ? "0" + month : month.toString();

  var date = x.getDate() < 10 ? "0" + x.getDate() : x.getDate().toString();

  var hour = x.getHours() < 10 ? "0" + x.getHours() : x.getHours().toString();

  var min =
    x.getMinutes() < 10 ? "0" + x.getMinutes() : x.getMinutes().toString();

  var sec =
    x.getSeconds() < 10 ? "0" + x.getSeconds() : x.getSeconds().toString();

  return (
    x.getFullYear() +
    "/" +
    month +
    "/" +
    date +
    ", " +
    hour +
    ":" +
    min +
    ":" +
    sec
  );
};

export const getDateInMONYYYYFormat = (value) => {
  const date = new Date(value);
  const monthIndex = date.getMonth();
  const monthName = monthsArray[monthIndex];

  const year = date.getFullYear();

  return `${monthName} ${year}`;
};

export const getDateInYYYYMMDDFormat = (value) => {
  const date = new Date(value);
  const dayRaw = date.getDate();
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw;
  const monthRaw = date.getMonth() + 1;
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw;
  const year = date.getFullYear();

  return `${year}/${month}/${day}`;
};

export const dateIsValid = (dateStr) => {
  let newDate = moment(dateStr);
  if (!newDate.isValid()) {
    let splitString = "";
    if (dateStr.indexOf("-") >= 0) {
      splitString = "-";
    } else if (dateStr.indexOf("/" >= 0)) {
      splitString = "/";
    } else {
      return false;
    }
    newDate = dateStr.split(splitString).reverse().join().replaceAll(",", "/");
  }

  const date = new Date(newDate);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }
  return getDateInYYYYMMDDFormat(date);
};

export const getCurrentMonthStartAndCurrentDates = (getTime) => {
  const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const endDate = new Date();
  return {
    startDate: getTime ? startDate.getTime() : startDate,
    endDate: getTime ? endDate.getTime() : endDate,
  };
};
export const getLastYearStartAndCurrentDates = (getTime) => {
  const startDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    1
  );
  const endDate = new Date();
  return {
    startDate: getTime ? startDate.getTime() : startDate,
    endDate: getTime ? endDate.getTime() : endDate,
  };
};

export const checkDateChangeFromInitials = (initalDate, newDate) => {
  if (
    new Date(newDate.startDate).getTime() !==
      new Date(initalDate.startDate).getTime() ||
    new Date(newDate.endDate).getTime() !==
      new Date(initalDate.endDate).getTime()
  ) {
    return false;
  } else {
    return true;
  }
};

export const getCurrentMonthStartDate = () =>
  new Date(new Date().getFullYear(), new Date().getMonth(), 1);

export const convertReactCalendarsOnChangeValueToDates = (value) => {
  return new Date(
    new Date(value).getFullYear(),
    new Date(value).getMonth(),
    new Date(value).getDay()
  ).getTime();
};

export const verifyDateInYYYYMMDDFormat = (date) => {
  const newDate = date.replaceAll(" ", "/");
  const finalDate = new Date(newDate); // noon eastern time 12pm (toronto)
  if (!finalDate.getTime()) {
    return false;
  } else {
    return getDateInYYYYMMDDFormat(date);
  }
};

export const getMonthsAndYearsBetweenDates = (startDate, endDate) => {
  let months = new Set();
  let years = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    months.add(currentDate.toLocaleString("default", { month: "long" }));
    years.push(currentDate.getFullYear());
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return { months: [...months], years: [...new Set(years)] };
};
