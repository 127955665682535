const initialState = {
  currentUser: {
    email: "",
    displayName: "",
    authLoaded: false,
    authenticated: false,
    lastFunded: "",
  },
  userCausesUpdateLoading: {
    loading: false,
    success: false,
    error: null,
  },
  updateUserPersonalLoading: {
    loading: false,
    success: false,
    error: null,
  },
  userExcludedCampaigns: {},
  userExcludedCampaignsLoaded: false,
  userImpactImageUploadLoading: {
    loading: false,
    cause: null,
    success: false,
    error: null,
    url: null,
  },
  userExplorePlatformLoading: {
    loading: false,
    success: false,
    error: null,
  },
  userUploadLoading: true,
  subscription: { subscriptionLoaded: false },
  subscriptionLoading: { laoding: false, success: false, error: null },
  userNameSignUp: null,
  campaignPaymentLoading: { loading: false, success: false, error: null },
  causePaymentLoading: { loading: false, success: false, error: null },
  causeMovementLoading: { loading: false, success: false, error: null },
  tempBillingInformationLoading: {
    loading: false,
    success: false,
    error: null,
  },
  tempBillingInformation: {},
  updateEventSuccessStatusLoading: {
    loading: false,
    success: false,
    error: null,
  },
  checkEmailLink: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return { ...state, currentUser: action.payload };

    case "SET_CURRENT_USER_SUBSCRIPTION_DATA":
      return {
        ...state,
        subscription: { ...state.subscription, ...action.payload },
      };
    case "SET_CURRENT_USER_SUBSCRIPTION_DATA_LOADING":
      return {
        ...state,
        subscriptionLoading: action.payload,
      };
    case "CHECK_VERIFICATION_EMAIL_SUCCESS":
      return {
        ...state,
        currentUser: { ...state.currentUser, verified: true },
      };
    case "UPDATE_USER_UPLOAD_LOADING":
      return {
        ...state,
        userUploadLoading: action.payload,
      };
    case "SET_USER_PERSONAL_UPDATE_LOADING":
      return { ...state, updateUserPersonalLoading: action.payload };
    case "SET_TEMP_BILLING_INFORMATION_LOADING":
      return { ...state, tempBillingInformationLoading: action.payload };
    case "SET_TEMP_BILLING_INFORMATION":
      return { ...state, tempBillingInformation: action.payload };
    case "SET_USER_LAST_FUNDED_DATE":
      return {
        ...state,
        currentUser: { ...state.currentUser, lastFunded: action.payload },
      };
    case "UPDATE_USER_SUBSCRIPTION_CAUSES_LOADING":
      return { ...state, userCausesUpdateLoading: action.payload };
    case "UPDATE_USER_EXCLUDED_CAMPAIGNS":
      return { ...state, userExcludedCampaigns: action.payload };
    case "UPDATE_USER_EXCLUDED_CAMPAIGNS_LOADED_STATUS":
      return { ...state, userExcludedCampaignsLoaded: action.payload };
    case "UPDATE_USER_IMPACT_IMAGE_UPLOAD_LOADING":
      return { ...state, userImpactImageUploadLoading: action.payload };
    case "SET_USERNAME_SIGNUP":
      return { ...state, userNameSignUp: action.payload };
    case "UPDATE_CAMPAIGN_PAYMENT_LOADING":
      return { ...state, campaignPaymentLoading: action.payload };
    case "UPDATE_CAUSE_MOVEMENT_LOADING":
      return { ...state, causeMovementLoading: action.payload };
    case "UPDATE_CAUSE_PAYMENT_LOADING":
      return { ...state, causePaymentLoading: action.payload };
    case "UPDATE_EVENT_STATUSES_LOADING":
      return { ...state, updateEventSuccessStatusLoading: action.payload };
    case "SET_USER_IS_FROM_EMAIL_LINK":
      return { ...state, checkEmailLink: action.payload };
    case "USER_EXPLORE_PLATFORM_LOADING":
      return { ...state, userExplorePlatformLoading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
