import firebase from "../../firebase/firebaseConfig";
import {
  fetchOrganizationCampaigns,
  fetchOrganizationDraftCampaigns,
} from "../campaign/campaign.action";
import { capitalizeFirstLetter } from "../../utils/stringFormatting";
import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const createOrganizationData =
  (data) =>
  async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_CREATE_ORGANIZATION_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const createOrganizationWithImage = firebase
        .functions()
        .httpsCallable("createOrganizationWithImage");
      const result = await createOrganizationWithImage(data);
      console.log(result);
      if (result.data.success) {
        dispatch({
          type: "SET_CREATE_ORGANIZATION_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        dispatch({
          type: "SET_CREATE_ORGANIZATION_DATA_LOADING",
          payload: {
            loading: false,
            success: false,
            error: result.data.message,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

export const fetchAllOrganizations =
  () =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch({
        type: "SET_ALL_ORGANIZATION_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const db = getFirestore();
      const organizationSnapshot = db
        .collection("organization")
        .orderBy("organizationInfo.orgName", "asc")
        .onSnapshot((organizationRef) => {
          const organizationsArray = [];
          const organizationsObject = {};
          for (let i in organizationRef.docs) {
            let doc = organizationRef.docs[i];
            organizationsArray.push({
              name: doc.data().organizationInfo.orgName,
              ...doc.data(),
              id: doc.id,
            });
            organizationsObject[doc.id] = doc.data();
          }
          dispatch({
            type: "SET_ALL_ORGANIZATIONS",
            payload: organizationsArray,
          });
          dispatch({
            type: "SET_ALL_ORGANIZATIONS_AS_OBJECTS",
            payload: organizationsObject,
          });
          dispatch({
            type: "SET_ALL_ORGANIZATION_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        });
      dispatch(
        addSnapshotsListners({
          type: "organization-snapshot",
          snapshot: organizationSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ALL_ORGANIZATION_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const fetchOrganizationData =
  (data, organizationDispatch) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch({
        type: "SET_ORGANIZATION_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const db = getFirestore();
      const organizationRef = await db
        .collection("organization")
        .where("organizationInfo.orgEmail", "==", data)
        .get();
      if (!organizationRef.empty) {
        const organizationDataFirebase = {
          ...organizationRef.docs[0].data(),
          id: organizationRef.docs[0].id,
        };

        const orgId = organizationRef.docs[0].id;
        if (!organizationDispatch) {
          dispatch(fetchOrganizationCampaigns(orgId));
          dispatch(fetchOrganizationDraftCampaigns(orgId));
        }

        dispatch({
          type: "SET_ORGANIZATION_DATA",
          payload: organizationDataFirebase,
        });
        dispatch({
          type: "SET_ORGANIZATION_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
        return true;
      } else {
        dispatch({
          type: "SET_ORGANIZATION_DATA_LOADING",
          payload: {
            loading: false,
            success: false,
            error: "There is no organization with this email",
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_ORGANIZATION_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const fetchCampaignDataForCampaignPage =
  (data) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch({
        type: "SET_CAMPAIGN_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      const db = getFirestore();
      if (data) {
        const organizationRef = await db
          .collection("organization")
          .where("organizationInfo.orgEmail", "==", data)
          .get();
        if (!organizationRef.empty) {
          const orgId = organizationRef.docs[0].id;
          dispatch(fetchOrganizationCampaigns(orgId));
          dispatch(fetchOrganizationDraftCampaigns(orgId));
        }
      } else {
        dispatch(fetchOrganizationCampaigns(false));
        dispatch(fetchOrganizationDraftCampaigns(false));
      }
      dispatch({
        type: "SET_CAMPAIGN_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAMPAIGN_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const setExecData = (obj) => ({
  type: "SET_EXECUTIVE_INFO",
  payload: obj,
});
export const setDirecData = (Newobj) => ({
  type: "SET_DIRECTOR_INFO",
  payload: Newobj,
});

export const setRevDataFinance = (Newobj) => ({
  type: "SET_REVENUE_FINANCE",
  payload: Newobj,
});
export const setExpDataFinance = (Newobj) => ({
  type: "SET_EXPENSE_FINANCE",
  payload: Newobj,
});

export const fetchOrganizationEmailNameSearch =
  (modal) =>
  async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch({
        type: "SET_FETCH_ORGANIZATION_DATA_LOADING",
        payload: {
          loading: true,
          success: false,
          error: null,
        },
      });
      const organizationData = [];
      const db = getFirestore();

      const response = await db.collection("organization").get();
      if (!response.empty) {
        const result = response.docs;
        result.forEach((doc) => {
          const organizationEmailNameData = {
            orgEmail: doc.data().organizationInfo.orgEmail,
            orgName: capitalizeFirstLetter(doc.data().organizationInfo.orgName),
            image: doc.data().signatureData.image,
            orgDescription: doc.data().organizationInfo.orgDescription,
          };
          organizationData.push(organizationEmailNameData);
        });
      } else {
        const errorObject = "No Organization Exists";
        throw errorObject;
      }
      if (organizationData) {
        dispatch({
          type: "SET_ORGANIZATION_SEARCH_DATA",
          payload: organizationData,
        });
        dispatch({
          type: "SET_FETCH_ORGANIZATION_DATA_LOADING",
          payload: {
            loading: false,
            success: true,
            error: null,
          },
        });
      } else {
        dispatch({
          type: "SET_FETCH_ORGANIZATION_DATA_LOADING",
          payload: {
            loading: false,
            success: false,
            error: "No Organization Exists",
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_FETCH_ORGANIZATION_DATA_LOADING",
        payload: {
          loading: false,
          success: false,
          error: "No Organization Exists",
        },
      });
    }
  };
export const resetOrganizationModalAction = (boolean) => ({
  type: "RESET_ORGANIZATION_MODAL",
  payload: boolean,
});
export const resetFetchDataFromQLDBLoading = () => ({
  type: "SET_CREATE_ORGANIZATION_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const restOrgReducer = () => ({
  type: "SET_RESET_ORG_REDUCERS",
});

export const resetFetchOrganizationDataLoading = () => ({
  type: "SET_FETCH_ORGANIZATION_DATA_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const resetFetchOrganizationEmailNameSearch = () => (dispatch) => {
  dispatch({
    type: "SET_ORGANIZATION_SEARCH_DATA",
    payload: [],
  });
};
