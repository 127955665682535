const initialState = {
  compoundCollectionData: [],
  compoundCampaignsDataEmpty: false,
  compoundCollectionDataLoading: {
    loading: false,
    success: false,
    error: null,
  },
  amountStatisticDataLoading: { loading: false, success: false, error: null },
  donorStatisticDataLoading: { loading: false, success: false, error: null },
  eventStats:{},
  amountStatisticData: [],
  oneTimeCount: 0,
  stripeExpense: 0,
  subscriptionCount: 0,
  unsubscriptionCount: 0,
  serviceFee: 0,
};

const compoundCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CAMPAIGNS_WITH_THEIR_AVAILABLE_BALANCE":
      return { ...state, compoundCollectionData: action.payload };
    case "RESET_COMPOUND_CAMPAIGN_DATA":
      return { ...state, compoundCollectionData: [] };
    case "SET_CAMPAIGNS_WITH_AVAILABLE_BALANCE_EMPTY":
      return { ...state, compoundCampaignsDataEmpty: action.payload };
    case "SET_COMPOUND_COLLECTION_DATA_LOADING":
      return { ...state, compoundCollectionDataLoading: action.payload };
    case "SET_AMOUNT_STATISTIC_DATA_LOADING":
      return { ...state, amountStatisticDataLoading: action.payload };
    case "SET_AMOUNT_STATISTIC_DATA":
      return { ...state, amountStatisticData: action.payload };
    case "SET_STRIPE_EXPENSE":
      return { ...state, stripeExpense: action.payload };
    case "SET_SERVICE_FEE":
      return { ...state, serviceFee: action.payload };
    case "SET_DONOR_STATISTIC_DATA_LOADING":
      return { ...state, donorStatisticDataLoading: action.payload };
    case "SET_DONOR_ONETIME_COUNT":
      return { ...state, oneTimeCount: action.payload };
    case "SET_DONOR_SUBSCRIBED_COUNT":
      return { ...state, subscriptionCount: action.payload };
    case "SET_DONOR_UNSUBSCRIBED_COUNT":
      return { ...state, unsubscriptionCount: action.payload };
      case "SET_EVENT_STATS":
        return { ...state, eventStats: action.payload };

    default:
      return state;
  }
};

export default compoundCollectionReducer;
