import React from "react";
import { useSelector } from "react-redux";

const useApplicationLoading = () => {
  const [loading, setLoading] = React.useState(true);
  const { allOrganizationLoading } = useSelector((state) => state.organization);
  const { campaignDraftsLoading, campaignsListLoading } = useSelector(
    (state) => state.campaign
  );
  const { usersTypeLoading } = useSelector((state) => state.adminUserProfile);
  const { authLoaded, subscription, authenticated, role } = useSelector(
    (state) => state.user.currentUser
  );
  const realSubscription = useSelector((state) => state.user.realSubscription);
  const { causeLoaded } = useSelector(
    (state) => state.causes.fetchCauseDataLoading
  );
  const userExcludedCampaignsLoaded = useSelector(
    (state) => state.user.userExcludedCampaignsLoaded
  );
  const pastTransactionsDataLoading = useSelector(
    (state) => state.billing.causeProjectsDonationDataLoading
  );
  const {
    featuresLoading,
    basicInfoLoading,
    brandingColorInfoLoading,
    brandingFeaturesLoading,
    configurationsLoading,
    applicationModeLoading,
  } = useSelector((state) => state.InfauqeInstance);

  const reloadApplicationLoading = useSelector(
    (state) => state.appSettings.reloadApplicationLoading
  );
  const allUsersDataLoading = useSelector(
    (state) => state.allUsers.allUsersDataLoading
  );
  const eventListLoading = useSelector(
    (state) => state.events.eventListLoading
  );

  React.useEffect(() => {
    let count = 0;
    if (campaignsListLoading.loading) {
      count++;
    }

    if (configurationsLoading.loading) {
      count++;
    }
    if (allOrganizationLoading.loading) {
      count++;
    }
    if (eventListLoading.loading) {
      count++;
    }
    if (pastTransactionsDataLoading.loading) {
      count++;
    }

    if (reloadApplicationLoading) {
      count++;
    }
    if (authenticated) {
      if (featuresLoading.loading) {
        count++;
      }
      if (brandingFeaturesLoading.loading) {
        count++;
      }
    }
    if (basicInfoLoading.loading) {
      count++;
    }
    if (applicationModeLoading.loading) {
      count++;
    }

    if (brandingColorInfoLoading.loading) {
      count++;
    }

    if (!authLoaded) {
      count++;
    }
    if (!causeLoaded) {
      count++;
    }
    if (subscription) {
      if (realSubscription) {
        if (!realSubscription.subscriptionLoaded) count++;
      }
    }
    if (authenticated && !role) {
      if (!userExcludedCampaignsLoaded) {
        count++;
      }
    }

    if (role) {
      if (campaignDraftsLoading.loading) {
        count++;
      }
      if (usersTypeLoading.loading) {
        count++;
      }
    }
    if (count > 0) setLoading(true);
    else setLoading(false);
  }, [
    authLoaded,
    authenticated,
    realSubscription,
    reloadApplicationLoading,
    role,
    subscription,
    userExcludedCampaignsLoaded,
    causeLoaded,
    featuresLoading.loading,
    basicInfoLoading.loading,
    brandingColorInfoLoading.loading,
    brandingFeaturesLoading.loading,
    allOrganizationLoading.loading,
    campaignsListLoading.loading,
    campaignDraftsLoading.loading,
    configurationsLoading.loading,
    usersTypeLoading.loading,
    applicationModeLoading.loading,
    allUsersDataLoading.loading,
    eventListLoading.loading,
    pastTransactionsDataLoading.loading,
  ]);

  return loading;
};

export default useApplicationLoading;
