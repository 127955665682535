import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const fetchPastTransactionsData =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const success = getState().billing.pastTransactionsDataLoading.success;
    const firestore = getFirestore();
    const uid = getState().user.currentUser.uid;
    const userRef = firestore.collection("users").doc(uid);
    if (!success) {
      dispatch({
        type: "SET_PAST_TRANSACTIONS_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });

      try {
        const snapShotData = await userRef
          .collection("PastTransactions")
          .orderBy("TimeValue", "desc")
          .get();
        let firstData = [];
        snapShotData.docs.forEach((doc) => {
          firstData.push(doc.data());
        });

        dispatch({
          type: "SET_PAST_TRANSACTIONS_DATA",
          payload: firstData,
        });
        const last = snapShotData.docs[snapShotData.docs.length - 1];
        dispatch({
          type: "SET_LAST_TRANSACTIONS_DATA",
          payload: last,
        });
        dispatch({
          type: "SET_PAST_TRANSACTIONS_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } catch (err) {
        console.log(err.message);
        dispatch({
          type: "SET_PAST_TRANSACTIONS_DATA_LOADING",
          payload: { loading: false, success: false, error: err.message },
        });
      }
    }
  };

export const fetchCauseProjectsPastTransactionsData =
  (userId) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    try {
      let uid;
      const { authenticated } = getState().user.currentUser;
      if (authenticated) {
        uid = getState().user.currentUser.uid;
      } else {
        uid = userId;
      }
      console.log(uid);
      const causeProjectsDonationDataLoading =
        getState().billing.causeProjectsDonationDataLoading;
      if (causeProjectsDonationDataLoading.success) {
        return;
      }

      dispatch({
        type: "SET_CAUSE_PROJECTS_DONATION_DATA_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      firestore
        .collection("Donations")
        .where("UID", "==", uid)
        .orderBy("TimeValue", "desc")
        .onSnapshot((donationCollectionRef) => {
          const causeProjectDonations = [];
          for (const i in donationCollectionRef.docs) {
            const doc = donationCollectionRef.docs[i];
            if (doc.data().reverse || !doc.data().Total) {
              continue;
            }
            causeProjectDonations.push({ ...doc.data(), id: doc.id });
          }

          dispatch({
            type: "SET_CAUSE_PROJECTS_DONATION_DATA",
            payload: causeProjectDonations,
          });
        });

      dispatch({
        type: "SET_CAUSE_PROJECTS_DONATION_DATA_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CAUSE_PROJECTS_DONATION_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const fetchNextTransactionsData =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const uid = getState().user.currentUser.uid;
    const userRef = firestore.collection("users").doc(uid);
    const pastData = getState().billing.pastTransactionsData;
    const lastDoc = getState().billing.lastTransactionsData;
    try {
      const snapShotData = await userRef
        .collection("PastTransactions")
        .orderBy("TimeValue", "asc")
        .startAfter(lastDoc.data().TimeValue)
        .limit(5)
        .get();

      if (!snapShotData.empty) {
        let nextData = [];
        snapShotData.docs.forEach((doc) => {
          nextData.push(doc.data());
        });
        const newData = [...pastData, ...nextData];
        const last = snapShotData.docs[snapShotData.docs.length - 1];
        dispatch({
          type: "SET_LAST_TRANSACTIONS_DATA",
          payload: last,
        });
        dispatch({
          type: "SET_PAST_TRANSACTIONS_DATA",
          payload: newData,
        });
      } else {
        dispatch({
          type: "SET_EMPTY_TRANSACTIONS_DATA",
          payload: true,
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

export const fetchUserIndividualBalanceData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const success = getState().billing.individualBalanceLoading.success;
    const firestore = getFirestore();
    const uid = getState().user.currentUser.uid;
    const userRef = firestore.collection("users").doc(uid);
    if (!success) {
      dispatch({
        type: "SET_PAST_INDIVIDUAL_BALANCE_LOADING",
        payload: { loading: true, success: false, error: null },
      });
      try {
        const userIndividualBalanceSnapshot = userRef
          .collection("IndividualBalance")
          .onSnapshot(async (snapShotData) => {
            let tempState = [];
            snapShotData.forEach(async (doc) => {
              tempState.push(doc.data());
            });
            tempState.push({
              Amount: tempState.reduce((acc, obj) => {
                return acc + obj.Amount;
              }, 0),
              Cause: "Total",
            });
            dispatch({
              type: "SET_PAST_INDIVIDUAL_BALANCE",
              payload: tempState,
            });

            dispatch({
              type: "SET_PAST_INDIVIDUAL_BALANCE_LOADING",
              payload: { loading: false, success: true, error: null },
            });
          });
        dispatch(
          addSnapshotsListners({
            type: "user-individual-balance-snapshot",
            snapshot: userIndividualBalanceSnapshot,
          })
        );
      } catch (err) {
        console.log(err.message);
        dispatch({
          type: "SET_PAST_INDIVIDUAL_BALANCE_LOADING",
          payload: { loading: false, success: false, error: err.message },
        });
      }
    }
  };
};

export const setAddFundOnSubscription = (value) => ({
  type: "SET_ADD_FUNDS_ON_SUBSCRIPTION",
  payload: value,
});
