import React from "react";

import { useSelector } from "react-redux";
import {
  filterEventRoutes,
  filterLoggedOutRoutes,
  filterRoutes,
} from "../utils/groupArrays";

import {
  loggedOutRoutes,
  adminRoutes,
  systemAdminRoutes,
  nonVerifiedEmail,
  explorPlatformFalse,
  explorPlatformTrue,
} from "../routes";

const useFinalRoutes = () => {
  const [finalRoutes, setFinalRoutes] = React.useState([]);

  const { features, brandingFeatures, applicationMode } = useSelector(
    (state) => state.InfauqeInstance
  );
  const {
    role,
    subscription,
    authenticated,
    userContributionData,
    explorePlatform,
    verified,
  } = useSelector((state) => state.user.currentUser);

  const previouslySubscribed = !!userContributionData;

  React.useEffect(() => {
    switch (role) {
      case "admin":
        if (verified) {
          const newRoutes = filterRoutes(
            adminRoutes,
            features,
            applicationMode
          );
          const adminCampaignroute = adminRoutes.find(
            (item) => item.name === "AdminCampaignPage"
          );
          setFinalRoutes([...newRoutes, adminCampaignroute]);
        } else {
          setFinalRoutes([...nonVerifiedEmail]);
        }
        break;

      case "system-admin":
        if (verified) {
          const newRoutes = [...systemAdminRoutes];
          setFinalRoutes([...newRoutes]);
        } else {
          setFinalRoutes([...nonVerifiedEmail]);
        }
        break;
      default:
        switch (authenticated) {
          case true:
            if (verified) {
              switch (explorePlatform) {
                case true:
                  const newRoutes = filterEventRoutes(
                    explorPlatformTrue,
                    features,
                    applicationMode
                  );
                  setFinalRoutes([...newRoutes]);
                  break;
                case false:
                  setFinalRoutes([...explorPlatformFalse]);
                  break;
                default:
                  setFinalRoutes([]);
                  break;
              }
            } else {
              setFinalRoutes([...nonVerifiedEmail]);
            }
            break;
          case false:
            const newRoutes = filterLoggedOutRoutes(
              loggedOutRoutes,
              applicationMode
            );
            console.log(loggedOutRoutes, newRoutes);
            setFinalRoutes([...newRoutes]);
            break;
          default:
            setFinalRoutes([]);
            break;
        }
    }
  }, [
    authenticated,
    features,
    previouslySubscribed,
    role,
    subscription,
    brandingFeatures,
    explorePlatform,
    verified,
    applicationMode,
  ]);

  return finalRoutes;
};

export default useFinalRoutes;
