import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalBalanceFromQLDB } from "../redux/qldb/qldb.action";
import {
  fetchCauseProjectsPastTransactionsData,
  fetchUserIndividualBalanceData,
} from "../redux/billing/billing.action";
import { fetchDisbursementDataFromFirebase } from "../redux/disbursement/disbursement.action";
import {
  // fetchCountNumberOfUsers,
  fetchEventStatsToDate,
} from "../redux/admin-user-profile/admin-user-profile.action";
import { fetchBankBalanceQLDB } from "../redux/withdraw/withdraw.action";
import { checkPublicPagesWithHeaderClass } from "../utils/determineApplicationCssClasses";
import { fetchEmailConfigurations } from "../redux/emailCMS/emailCMS.action";
import { fetchAllOrganizations } from "../redux/organizationdata/organizationData.action";
import { setApplicationStartTime } from "../redux/app-settings/app-settings.action";

import {
  fetchAllCampaigns,
  fetchAllCampaignsDrafts,
  fetchAllCauseProjects,
} from "../redux/campaign/campaign.action";
import {
  fetchCausesData,
  getFollowedCauses,
} from "../redux/causes/causes.action";
import {
  fetchUserExcludedCampaigns,
  getAllEventStatuses,
  getUserSubscriptionData,
} from "../redux/auth/auth.action";
import {
  getApplicationMode,
  getBrandignColorInfo,
  getBrandingBasicInfo,
  getBrandingFeatureInfo,
  getConfigurations,
  getFeatureInfo,
} from "../redux/newInfaqueInstances/newInfaqueInstances.action";

import Loader from "../components/loader/loader.component";
import HelmetComponent from "../components/helmet/helmet.component";
import RoutesHandling from "./layoutUtils/routesHandling";
import useApplicationLoading from "./layoutUtils/checkApplicationLoading";
import ErrorToast from "../atoms/error-toast/error-toast.atom";
import ApplicationSideBars from "./layoutUtils/returnSideBars";

import "./layout.styles.scss";
import "../index.scss";
import {
  fetchAllUsers,
  fetchAllUsersCauseProject,
  getFilterFromDatabase,
} from "../redux/allUsers/allUsers.action";
import {
  fetchAllEvents,
  fetchAllEventDraft,
  fetchUserTickets,
} from "../redux/events/events.action";
import Help from "../components/help/help.component";

const Layout = () => {
  const dispatch = useDispatch();
  const applicationLoading = useApplicationLoading();
  const { applicationMode, applicationModeLoaded } = useSelector(
    (state) => state.InfauqeInstance
  );
  const { currentUser } = useSelector((state) => state.user);
  const { allUsers } = useSelector((state) => state.allUsers);
  const { authenticated, role, uid } = currentUser;

  // Fetch website basic data
  useEffect(() => {
    dispatch(getApplicationMode());
    dispatch(getBrandingBasicInfo());
    dispatch(getBrandignColorInfo());
    dispatch(getBrandingFeatureInfo());
    dispatch(getFeatureInfo());
    dispatch(getConfigurations());
    dispatch(fetchCausesData());
    dispatch(fetchAllCampaigns());
    dispatch(fetchAllCauseProjects());
    dispatch(fetchAllOrganizations());
    dispatch(setApplicationStartTime());
  }, [dispatch]);

  //fetch Events Data

  useEffect(() => {
    if (applicationModeLoaded) {
      if (applicationMode === "cause-project") {
        dispatch(fetchAllEvents());
        if (role) {
          if (role === "admin") {
            dispatch(fetchEventStatsToDate());
            dispatch(fetchAllEventDraft());
            dispatch(getFilterFromDatabase());
          }
        } else {
          if (authenticated) {
            dispatch(fetchCauseProjectsPastTransactionsData());
            dispatch(fetchUserTickets(uid));
          }
        }
      }
    }
  }, [
    applicationModeLoaded,
    applicationMode,
    role,
    dispatch,
    uid,
    authenticated,
  ]);

  // Fetch admin dashbaord data
  useEffect(() => {
    if (role) {
      // dispatch(fetchCountNumberOfUsers());
      dispatch(fetchAllCampaignsDrafts());
      if (role === "admin") {
        dispatch(fetchEmailConfigurations());
        if (applicationModeLoaded) {
          if (applicationMode === "cause-project") {
            if (!allUsers.length) {
              dispatch(fetchAllUsersCauseProject(false));
            }
          } else {
            dispatch(fetchDisbursementDataFromFirebase());
            dispatch(fetchGlobalBalanceFromQLDB());
            dispatch(fetchBankBalanceQLDB());
            if (!allUsers.length) {
              dispatch(fetchAllUsers(false));
            }
          }
        }
      }
    }
  }, [dispatch, role, allUsers.length, applicationModeLoaded, applicationMode]);

  // Fetch auth user data
  useEffect(() => {
    if (authenticated && !role) {
      dispatch(fetchUserIndividualBalanceData());
      dispatch(fetchUserExcludedCampaigns(uid));
      dispatch(getUserSubscriptionData());
      dispatch(getAllEventStatuses());
      dispatch(getFollowedCauses());
    }
  }, [dispatch, authenticated, role, uid]);

  useEffect(() => {
    window.localStorage.setItem("donateDirectly", false);
  }, []);

  if (applicationLoading) return <Loader />;
  else {
    return (
      <div
        id="main"
        className={`layout-component ${
          role ? "admin" : ""
        } ${applicationMode} ${
          checkPublicPagesWithHeaderClass(currentUser) ? "public-pages" : ""
        }`}
      >
        <Help />
        <HelmetComponent />
        <ApplicationSideBars />
        <RoutesHandling />
        <ErrorToast />
      </div>
    );
  }
};

export default Layout;
