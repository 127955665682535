export const setMobileHeaderTitle = (title) => ({
  type: "SET_MOBILE_HEADER_TITLE",
  payload: title,
});

export const addSnapshotsListners = (snapshot) => {
  return (dispatch, getState) => {
    dispatch({
      type: "ADD_FIREBASE_SNAPSHOT_LISTENER",
      payload: snapshot,
    });
  };
};

export const addLogsSnapshots = (snapshot) => ({
  type: "ADD_FIREBASE_LOGS_SNAPSHOT",
  payload: snapshot,
});

export const resetLogsSnapshots = () => {
  return async (dispatch, getState) => {
    const snapshots = getState().appSettings.logsSnapshots;
    for (const snapshot of snapshots) {
      await snapshot.snapshot();
    }
    dispatch({ type: "RESET_LOGS_SNAPSHOTS" });
  };
};

export const reloadApplicationLoading = (loading) => ({
  type: "RELOAD_APPLICATION_LOADING",
  payload: loading,
});

export const setBackRoute = (value) => ({
  type: "SET_BACK_ROUTE",
  payload: value,
});

export const setFromCampaignPage = (value) => ({
  type: "SET_FROM_CAMPAIGN_PAGE",
  payload: value,
});

export const setupApplicationAndDatabases =
  () =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SETUP_APPLICATION_AND_DATABASES_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    try {
      const setupApplicationAndDatabsesFunction = firebase
        .functions()
        .httpsCallable("setupApplicationAndDatabsesFunction");
      const resp = await setupApplicationAndDatabsesFunction();
      const response = resp.data;
      if (response.success) {
        dispatch({
          type: "SETUP_APPLICATION_AND_DATABASES_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 500, message: response.error };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SETUP_APPLICATION_AND_DATABASES_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const resetCreateTableLoading = () => ({
  type: "SETUP_APPLICATION_AND_DATABASES_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const setApplicationStartTime = () => ({
  type: "SET_APPLICATION_START_TIME",
  payload: new Date().getTime(),
});
