import { addSnapshotsListners } from "../app-settings/app-settings.action";

export const setBasicBrandingInfo = (value) => ({
  type: "SET_BASIC_BRANDING_INFO",
  payload: value,
});
export const setSystemAdminFeatures = (value) => ({
  type: "SET_SYSTEM_ADMIN_FEATURES_INFO",
  payload: value,
});

export const getBrandingBasicInfo = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_BASIC_INFO_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("basicInfo");
      docRef.onSnapshot((brandingInfoResult) => {
        if (brandingInfoResult.exists) {
          const brandingBasicInfoData = brandingInfoResult.data();
          const causeProjectName = `${brandingBasicInfoData.causeName} ${brandingBasicInfoData.campaignName}`;
          dispatch(
            setBasicBrandingInfo({ ...brandingBasicInfoData, causeProjectName })
          );
          dispatch({
            type: "SET_BASIC_INFO_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_BASIC_INFO_LOADING",
            payload: { loading: false, success: false, error: null },
          });
        }
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_BASIC_INFO_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const getApplicationMode = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_FETCH_APPLICATION_MODE_SETTING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("applicationMode");
      docRef.onSnapshot((applicationModeResult) => {
        if (applicationModeResult.exists) {
          const applicationModeData = applicationModeResult.data();
          dispatch({
            type: "SET_APPLICATION_MODE",
            payload: applicationModeData.applicationMode,
          });
        } else {
          dispatch({
            type: "SET_APPLICATION_MODE",
            payload: "",
          });
        }
        dispatch({
          type: "SET_FETCH_APPLICATION_MODE_SETTING",
          payload: { loading: false, success: true, error: null },
        });
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_FETCH_APPLICATION_MODE_SETTING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const getBrandignColorInfo = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_BRANDING_COLOR_INFO_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("brandingColors");
      docRef.onSnapshot((brandingColorResult) => {
        if (brandingColorResult.exists) {
          const brandingColorInfoData = brandingColorResult.data();
          dispatch({
            type: "SET_BRANDING_COLORS",
            payload: brandingColorInfoData,
          });
          dispatch({
            type: "SET_BRANDING_COLOR_INFO_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_BRANDING_COLOR_INFO_LOADING",
            payload: { loading: false, success: false, error: null },
          });
        }
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_BRANDING_COLOR_INFO_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const getFeatureInfo = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const snapshotType = "system-admin-features-snapshot";
    dispatch({
      type: "SET_FEATURES_INFO_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("features");
      let systemAdminFeaturesSnapshot = docRef.onSnapshot((snap) => {
        if (snap.exists) {
          const data = snap.data();
          dispatch(setSystemAdminFeatures(data));
          dispatch({
            type: "SET_FEATURES_INFO_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_FEATURES_INFO_LOADING",
            payload: { loading: false, success: false, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: snapshotType,
          snapshot: systemAdminFeaturesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_FEATURES_INFO_LOADING",
        payload: { loading: false, success: true, error: err.message },
      });
    }
  };
};

export const getBrandingFeatureInfo = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const snapshotType = "system-admin-branding-features-snapshot";
    dispatch({
      type: "SET_BRANDING_FEATURES_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("brandingFeatures");
      let systemAdminBrandingFeaturesSnapshot = docRef.onSnapshot((snap) => {
        if (snap.exists) {
          const data = snap.data();
          dispatch({ type: "SET_BRANDING_FEATURES", payload: data });
          dispatch({
            type: "SET_BRANDING_FEATURES_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_BRANDING_FEATURES_LOADING",
            payload: { loading: false, success: false, error: null },
          });
        }
      });
      dispatch(
        addSnapshotsListners({
          type: snapshotType,
          snapshot: systemAdminBrandingFeaturesSnapshot,
        })
      );
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_BRANDING_FEATURES_LOADING",
        payload: { loading: false, success: true, error: err.message },
      });
    }
  };
};

export const getConfigurations = () => {
  return async (dispatch, getState, { getFirestore }) => {
    dispatch({
      type: "SET_CONFIGURATIONS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firestore = getFirestore();
    try {
      const docRef = firestore
        .collection("applicationSettings")
        .doc("configurations");
      docRef.onSnapshot((configurations) => {
        if (configurations.exists) {
          const configurationsData = configurations.data();
          dispatch({
            type: "SET_CONFIGURATIONS",
            payload: configurationsData,
          });
          dispatch({
            type: "SET_CONFIGURATIONS_LOADING",
            payload: { loading: false, success: true, error: null },
          });
        } else {
          dispatch({
            type: "SET_CONFIGURATIONS_LOADING",
            payload: { loading: false, success: false, error: null },
          });
        }
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_CONFIGURATIONS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const addConfigurations =
  (data) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({
      type: "ADD_CONFIGURATIONS_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    console.log("dataInFront", data);
    try {
      const addConfigurations = firebase
        .functions()
        .httpsCallable("addConfigurations");
      const resp = await addConfigurations(data);
      if (resp.data.success) {
        dispatch({
          type: "ADD_CONFIGURATIONS_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: resp.data.errorMessage };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "ADD_CONFIGURATIONS_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };

export const removeSnapshotFromType = (type) => ({
  type: "REMOVE_SNAPSHOT_BY_TYPE",
  payload: type,
});

export const resetAddConfigurationsLoading = () => ({
  type: "ADD_CONFIGURATIONS_LOADING",
  payload: { loading: false, success: false, error: null },
});
