import { combineReducers } from "redux";
import qldbReducer from "./qldb/qldb.reducer";
import { firestoreReducer } from "redux-firestore";

import authReducer from "./auth/auth.reducer";
import tabReducer from "./tab/tab.reducer";
import chooseCauseReducer from "./switchChooseCause/switchChooseCause.reducer";
import contributionReducer from "./contribution/contribution.reducer";
import causesReducer from "./causes/causes.reducer";
import paymentReducer from "./payment/payment.reducer";
import signUpReducer from "./signup/signUp.reducer";
import campaignReducer from "./campaign/campaign.reducer";
import organizationReducer from "./organizationdata/organizationData.reducer";
import compoundCollectionReducer from "./compound-collection/compoundCollection.reducer";
import withdrawReducer from "./withdraw/withdraw.reducer";
import tipsReducer from "./tips/tips.reducer";
import billingReducer from "./billing/billing.reducer";
import disbursementReducer from "./disbursement/disbursement.reducer";
import adminUserProfileReducer from "./admin-user-profile/admin-user-profile.reducer";
import formErrorReducer from "./creatOrgError/createOrgError.reducer";
import allUsersReducer from "./allUsers/allUsers.reducer";
import appSetingsReducer from "./app-settings/app-settings.reducer";
import InfauqeInstanceReducer from "./newInfaqueInstances/newInfaqueInstances.reducer";
import emailCMSReducer from "./emailCMS/emailCMS.reducer";
import categoryReducer from "./categories/categories.reducer";
import eventsReducer from "./events/events.reducer";

const appReducer = combineReducers({
  user: authReducer,
  InfauqeInstance: InfauqeInstanceReducer,
  categories: categoryReducer,
  qldbReducer: qldbReducer,
  tab: tabReducer,
  firestore: firestoreReducer,
  chooseCause: chooseCauseReducer,
  contribution: contributionReducer,
  causes: causesReducer,
  payment: paymentReducer,
  signUp: signUpReducer,
  campaign: campaignReducer,
  organization: organizationReducer,
  compoundCollection: compoundCollectionReducer,
  withdraw: withdrawReducer,
  tips: tipsReducer,
  billing: billingReducer,
  emailCMS: emailCMSReducer,
  disbursement: disbursementReducer,
  adminUserProfile: adminUserProfileReducer,
  formErrorReducer: formErrorReducer,
  allUsers: allUsersReducer,
  appSettings: appSetingsReducer,
  events: eventsReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "RESET_REDUX_STORE") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
