import { createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import {
  createFirestoreInstance,
  reduxFirestore,
  getFirestore,
} from "redux-firestore";
import { getFirebase } from "react-redux-firebase";
import { firebaseConfig } from "../firebase/firebaseConfig";
import firebase from "firebase/app";
import rootReducer from "./rootReducer";

const env = process.env.REACT_APP_ENVIRONMENT;

const logger = createLogger({
  predicate: () => (env === "prod" ? false : true),
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      logger
    ),
    reduxFirestore(firebase, firebaseConfig)
  )
);

store.subscribe(() => {
  //this is just a function that saves state to localStorage
  const oldState = store.getState().billing.addFundsOnSubscription;
  if (oldState) {
    localStorage.setItem("addFundsOnSubscription", oldState);
  }
});

export const rrfProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
