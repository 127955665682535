import React from "react";

import "./site-under-construction.styles.scss";

const title = process.env.REACT_APP_WEB_TITLE;

const SiteUnderConstructionPage = () => {
  return (
    <div className="site-under-construction-page">
      <div class="overlay"></div>
      <div class="stars" aria-hidden="true"></div>
      <div class="starts2" aria-hidden="true"></div>
      <div class="stars3" aria-hidden="true"></div>
      <main class="main">
        <section class="contact">
          <h1 class="title">{title}</h1>
          <h2 class="sub-title">Site Under Construction</h2>
        </section>
      </main>
    </div>
  );
};

export default SiteUnderConstructionPage;
