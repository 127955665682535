const initialState = {
  bankBalance: { Bank: 0 },

  withdrawBalanceFromBankLoading: {
    loading: false,
    success: false,
    error: null,
  },
  fetchBankBalanceLoading: { loading: false, success: false, error: null },
  fetchWithdrawTableData: [],
  fetchWithdrawTableDataLoading: [],
};

const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BANK_BALANCE":
      return {
        ...state,
        bankBalance: action.payload,
      };
    case "SET_BANK_BALANCE_LOADING":
      return {
        ...state,
        fetchBankBalanceLoading: action.payload,
      };

    case "SET_WITHDRAW_FROM_BANK_LOADING":
      return {
        ...state,
        withdrawBalanceFromBankLoading: action.payload,
      };
    case "SET_WITHDRAW_TABLE_DATA":
      return {
        ...state,
        fetchWithdrawTableData: action.payload,
      };
    case "SET_WITHDRAW_TABLE_DATA_LOADING":
      return {
        ...state,
        fetchWithdrawTableDataLoading: action.payload,
      };
    default:
      return state;
  }
};

export default withdrawReducer;
