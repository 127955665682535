import React, { useState } from "react";

import PrimaryButton from "../primary-button/primary-button.component";

import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../../redux/auth/auth.action";

import "./systemAdmin-sidebar.component.styles.scss";

const SystemAdminSidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(history.location.pathname);
  const { features } = useSelector((state) => state.InfauqeInstance);
  const currentUser = useSelector((state) => state.user.currentUser);

  const signOutClickHandler = () => {
    dispatch(signOutAction());
  };

  return (
    <>
      <Nav className='admin-sidebar-system' activeKey='dashboard'>
        <div className='parent_side'>
          <div className='sidebar-sticky'>
            <div className='logo'>
              <h4 className='role'>{currentUser.role}</h4>
            </div>

            <div className='options'>
              <Link
                variant='flat'
                onClick={() => setCurrent("/admin-dashboard")}
                to='/admin-dashboard'
                data-test='admin-dashboard'>
                <Nav.Item
                  className={current === "/admin-dashboard" ? "current " : ""}>
                  <i className='fas fa-chart-line'></i> &nbsp; Dashboard
                </Nav.Item>
              </Link>
              <Link
                variant='flat'
                onClick={() => setCurrent("/admin-features")}
                to='/admin-features'
                data-test='admin-features'>
                <Nav.Item
                  className={current === "/admin-features" ? "current " : ""}>
                  <i className='fas fa-bars'></i> &nbsp; Admin Features
                </Nav.Item>
              </Link>
              <Link
                variant='flat'
                onClick={() => setCurrent("/brandingFeatures")}
                to='/brandingFeatures'
                data-test='brandingFeatures'>
                <Nav.Item
                  className={current === "/brandingFeatures" ? "current " : ""}>
                  <i className='fas fa-bars'></i> &nbsp; Branding Features
                </Nav.Item>
              </Link>
              <Link
                variant='flat'
                onClick={() => setCurrent("/donor-feedback")}
                to='/donor-feedback'
                data-test='donor-feedback'>
                <Nav.Item
                  className={current === "/donor-feedback" ? "current " : ""}>
                  <i className='fas fa-bars'></i> &nbsp; Donor Feedback
                </Nav.Item>
              </Link>

              {features && !features.organizationPage.active && (
                <Link
                  variant='flat'
                  onClick={() => setCurrent("/organization-page")}
                  to='/organization-page'
                  data-test='organization-campaign'>
                  <Nav.Item
                    className={
                      current === "/organization-page" ? "current " : ""
                    }>
                    <i className='fas fa-sitemap'></i> &nbsp; Organization
                  </Nav.Item>
                </Link>
              )}

              <div className='sign-outbtn'>
                <PrimaryButton
                  handleClick={() => signOutClickHandler()}
                  type='button'
                  className='sign-out-nav-item'
                  data-test='log-out'>
                  Sign Out
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default SystemAdminSidebar;
