import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Project } from "../assets/project.svg";
import { ReactComponent as Service } from "../assets/services.svg";
import { ReactComponent as Email } from "../assets/email-cms.svg";
import { ReactComponent as Users } from "../assets/all-users.svg";
import { ReactComponent as Uploads } from "../assets/upload-data.svg";
import { ReactComponent as Disbursements } from "../assets/disbursement.svg";
import { ReactComponent as CauseProjects } from "../assets/Cause Project.svg";
import { ReactComponent as Branding } from "../assets/branding.svg";
import { ReactComponent as Organization } from "../assets/organization.svg";
import { ReactComponent as AddAdmins } from "../assets/add-admins.svg";
import { ReactComponent as TransferFunds } from "../assets/transfer-funds.svg";
import { ReactComponent as LogsIcon } from "../assets/application-icons/logs-icon/logs-icon.svg";
import { ReactComponent as TransactionIcon } from "../assets/application-icons/transactions-icons/transactions.svg";
import { ReactComponent as AccountsIcon } from "../assets/application-icons/accounts-icon/accounts.svg";
import { ReactComponent as SentEmailIcon } from "../assets/application-icons/emails-sent-icon/emails-sent.svg";

import useBrandInfo from "./useGetBrandInfoProperties";
import { checkFormatingOfLabel } from "../utils/stringFormatting";

const useFetchFeaturesAndPermissions = () => {
  const { features, applicationMode } = useSelector(
    (state) => state.InfauqeInstance
  );
  const campaignName = checkFormatingOfLabel(useBrandInfo("campaignName"));
  const causeName = checkFormatingOfLabel(useBrandInfo("causeName"));
  const [MenuBarCategories, setMenubarCategories] = useState([]);
  useEffect(() => {
    const MenuBarCategoriestemp = [
      {
        category: "Dashboard",
        link: "/admin-dashboard",
        permission: features.dashboard.active,
      },
      {
        category: `${causeName} & ${campaignName}`,
        links: [
          {
            name: causeName,
            link: "/manage-causes",
            permission: features.manageServices.active,
            icon: <Service className='stroke' />,
          },
          {
            name: campaignName,
            link: "/campaigns/all-campaigns",
            permission: features.campaigns.active,
            icon: <Project className='top-plus' />,
          },
          {
            name: "Disbursements",
            link: "/disbursement-page",
            permission: features.disbursement.active,
            icon: <Disbursements />,
          },
          {
            name: "Transfer Funds",
            link: "/transfer-page",
            permission: features.transfer.active,
            icon: <TransferFunds className='stroke' />,
          },
        ],
      },
      {
        category: "Donors & Transactions",
        links: [
          {
            name: "Manage Donors",
            link: "/all-users/user-based-data",
            permission: features.allUsers.active,
            icon: <Users className='stroke top-minus' />,
          },
          {
            name: "Transactions",
            link: "/users-transactions/all-transactions",
            permission: features.allUsers.active,
            icon: <TransactionIcon className='top-minus' />,
          },
          {
            name: "Add History",
            link: "/upload-user-data",
            permission: features.uploadCSVPastUserRecord.active,
            icon: <Uploads className='top-minus' />,
          },
        ],
      },
      {
        category: "Emails",
        links: [
          {
            name: "Templates",
            link: "/email-cms",
            permission: features.emailCMS.active,
            icon: <Email className='top-minus' />,
          },
          {
            name: "Sent Emails",
            link: "/sent-emails",
            permission: features.emailCMS.active,
            icon: <SentEmailIcon className='top-minus' />,
          },
        ],
      },
      {
        category: "Donor Feedback",
        links: [
          {
            name: "Donor Feedback",
            link: "/donor-feedback",
            permission: true,
            icon: <Users className='stroke top-minus' />,
          },
        ],
      },
      {
        category: "Settings",
        links: [
          {
            name: "Accounts",
            link: "/web-basicInfo",
            permission: true,
            icon: <AccountsIcon className='stroke' />,
          },
          {
            name: "Branding & Links",
            link: "/web-branding",
            permission: true,
            icon: <Branding className='top-minus' />,
          },
          {
            name: "New Organization",
            link: "/project-page",
            permission: features.organizationPage.active,
            icon: <Organization className='top-minus' />,
          },
          {
            name: "Admins",
            link: "/all-admins",
            permission: features.addMoreAdmins.active,
            icon: <AddAdmins className='stroke top-minus' />,
          },
          // {
          //   name: "Configurations",
          //   link: "/web-configuration",
          //   permission: true,
          //   icon: <Gear className="stroke top-minus" />,
          // },
          {
            name: "Processes",
            link: "/logs",
            permission: true,
            icon: <LogsIcon className='logs-svg' />,
          },
        ],
      },
    ];

    if (applicationMode === "cause-project") {
      MenuBarCategoriestemp[1].links.splice(1, 0, {
        name: `${checkFormatingOfLabel(causeName, true)} ${campaignName}`,
        link: "/cause-projects/all",
        permission: true,
        icon: <CauseProjects className='color-white-using-path' />,
      });
      MenuBarCategoriestemp.splice(2, 0, {
        category: `Events & Ticketing`,
        links: [
          {
            name: "Events",
            link: "/events/all-events",
            permission: features.events.active,
            icon: <Service className='stroke' />,
          },
        ],
      });
    }

    setMenubarCategories(MenuBarCategoriestemp);
  }, [features, campaignName, causeName, applicationMode]);

  return MenuBarCategories;
};

export default useFetchFeaturesAndPermissions;
