import { routes } from "./features-array";
import { floatValue } from "./floatValue";

export function groupArrayOfObjects(list, key) {
  return list.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const groupBy = (list, key) => {
  let array = {};
  for (let i in list) {
    const item = list[i];
    array[item[key]] = [];
  }
  for (let i in array) {
    for (let j in list) {
      if (i === list[j][key]) {
        array[i].push(list[j]);
      }
    }
  }
  return array;
};
export const getGroupedData = (groupedData) => {
  let summedData = [];
  for (let i in groupedData) {
    const item = groupedData[i];
    if (item.length > 1) {
      let newItem = item;
      let sum = 0;
      let allocatedSum = 0;
      let remainingSum = 0;
      newItem.map((item) => (sum = sum + item.Contribution));
      newItem.map((item) => (allocatedSum = allocatedSum + item.Allocated));
      newItem.map(
        (item) => (remainingSum = remainingSum + item["Remaining Balance"])
      );

      const refObj = {
        ...newItem[0],
        Contribution: sum,
        Allocated: allocatedSum,
        "Remaining Balance": remainingSum,
        Cause: "All",
      };
      summedData.push(refObj);
    } else {
      summedData.push({ ...item[0], Cause: "All" });
    }
  }
  return summedData;
};

export const getServiceBasedGroupedData = (groupedData, allUserIndexed) => {
  let causeGroupedData = [];

  for (let i in groupedData) {
    const item = groupedData[i];
    if (item.length > 1) {
      const check = groupBy(item, "Cause");
      for (let i in check) {
        const newItem = check[i];
        if (newItem.length <= 1) {
          if (newItem[0].Cause_Amount !== 0) {
            causeGroupedData.push({
              ...newItem[0],
              Allocated:
                newItem[0].Cause_Amount < 0 ? newItem[0].Cause_Amount * -1 : 0,
              Contribution:
                newItem[0].Cause_Amount > 0 ? newItem[0].Cause_Amount : 0,
              "Remaining Balance":
                newItem[0].Cause_Amount < 0 ? 0 : newItem[0].Cause_Amount,
              Name: allUserIndexed[newItem[0].UID].Name
                ? allUserIndexed[newItem[0].UID].Name
                : "",
              email: allUserIndexed[newItem[0].UID].email,
              Status: allUserIndexed[newItem[0].UID].Status,
              id: newItem[0].UID,
            });
          }
        } else {
          let disburse = 0;
          let nonDisburse = 0;

          for (let j in newItem) {
            if (newItem[j].Cause_Amount !== 0) {
              if (newItem[j].Cause_Amount > 0) {
                nonDisburse = nonDisburse + newItem[j].Cause_Amount;
              } else {
                disburse = disburse + newItem[j].Cause_Amount;
              }
            }
          }
          delete newItem[0].Cause_Amount;
          causeGroupedData.push({
            ...newItem[0],
            Allocated: Math.abs(disburse),
            Contribution: nonDisburse,
            "Remaining Balance": Math.abs(
              floatValue(nonDisburse - Math.abs(disburse))
            ),
            Name: allUserIndexed[newItem[0].UID].Name
              ? allUserIndexed[newItem[0].UID].Name
              : "",
            email: allUserIndexed[newItem[0].UID].email,
            Status: allUserIndexed[newItem[0].UID].Status,
            id: newItem[0].UID,
          });
        }
      }
    } else {
      if (item[0].Cause_Amount !== 0) {
        causeGroupedData.push({
          ...item[0],
          Allocated:
            item[0].Cause_Amount < 0 ? Number(item[0].Cause_Amount) * -1 : 0,
          Contribution: item[0].Cause_Amount > 0 ? item[0].Cause_Amount : 0,
          "Remaining Balance":
            item[0].Cause_Amount < 0 ? 0 : item[0].Cause_Amount,
          Name: allUserIndexed[item[0].UID].Name
            ? allUserIndexed[item[0].UID].Name
            : "",
          email: allUserIndexed[item[0].UID].email,
          Status: allUserIndexed[item[0].UID].Status,
          id: item[0].UID,
        });
      }
    }
  }
  return causeGroupedData;
};

export const getGroupedDataTotalCash = (groupedData, applicationMode) => {
  let summedData = [];
  console.log(groupedData);
  for (let i in groupedData) {
    const item = groupedData[i];
    if (item.length > 1) {
      const check = groupBy(item, "Donor");
      for (let i in check) {
        const newItem = check[i];
        if (newItem.length <= 1) {
          summedData.push(newItem[0]);
        } else {
          let refObj = newItem[0];
          let sum = 0;
          let sumTips = 0;
          let sumTotal = 0;
          let grossService = 0;
          let grossPaymentProcessor = 0;
          let grossPlatformUsage = 0;
          let donorCoveredService = 0;
          let donorCoveredPaymentProcessor = 0;
          let donorCoveredPlatformUsage = 0;
          let fairMarketValue = 0;
          let donorCoveredTaxEligibleAmount = 0;
          newItem.map((item) => (sum = sum + item["Contribution Amount"]));
          newItem.map((item) => (sumTips = sumTips + item["Platform Charges"]));
          if (applicationMode === "cause-project") {
            newItem.map(
              (item) =>
                (grossService = grossService + item["Gross Service Fee"])
            );
            newItem.map(
              (item) =>
                (grossPaymentProcessor =
                  grossPaymentProcessor + item["Gross Payment Processor Fee"])
            );
            newItem.map(
              (item) =>
                (grossPlatformUsage =
                  grossPlatformUsage + item["Gross Platform Usage Fee"])
            );
            newItem.map(
              (item) =>
                (donorCoveredService =
                  donorCoveredService + item["Donor Covered Service Fee"])
            );
            newItem.map(
              (item) =>
                (donorCoveredPaymentProcessor =
                  donorCoveredPaymentProcessor +
                  item["Donor Covered Payment Processor Fee"])
            );
            newItem.map(
              (item) =>
                (donorCoveredPlatformUsage =
                  donorCoveredPlatformUsage +
                  item["Donor Covered Platform Usage Fee"])
            );
            newItem.map(
              (item) =>
                (fairMarketValue = fairMarketValue + item["Fair Market Value"])
            );
            newItem.map(
              (item) =>
                (donorCoveredTaxEligibleAmount =
                  donorCoveredTaxEligibleAmount + item["Tax Eligible Amount"])
            );
            newItem.map((item) => (sumTotal = sumTotal + item["Total"]));
          }

          refObj = {
            ...refObj,
            "Contribution Amount": sum,
            "Platform Charges": sumTips,
            "Gross Service Fee": grossService,
            "Gross Payment Processor Fee": grossPaymentProcessor,
            "Gross Platform Usage Fee": grossPlatformUsage,
            "Donor Covered Service Fee": donorCoveredService,
            "Donor Covered Payment Processor Fee": donorCoveredPaymentProcessor,
            "Donor Covered Platform Usage Fee": donorCoveredPlatformUsage,
            "Fair Market Value": fairMarketValue,
            "Tax Eligible Amount": donorCoveredTaxEligibleAmount,
            Total: sumTotal,
          };
          summedData.push(refObj);
        }
      }
    } else {
      summedData.push(item[0]);
    }
  }
  return summedData;
};

export function range(start, end) {
  let arr = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
    i = i + 4;
  }
  return arr;
}

export const filterRoutes = (adminRoutes, features, applicationMode) => {
  let newRoutes = [];
  for (let i in adminRoutes) {
    const item = adminRoutes[i];

    if (routes.includes(item.path)) {
      for (let j in features) {
        const Nitem = features[j];
        if (item.path === Nitem.route && Nitem.active) {
          newRoutes.push(item);
        }
      }
    } else {
      newRoutes.push(item);
    }
  }
  if (applicationMode === "cause-project") {
    const finalRoutes = newRoutes.filter(
      (item) =>
        item.path !== "/disbursement-page" &&
        item.path !== "/disbursement-detail-page/:id" &&
        item.path !== "/transfer-detail-page/:id"
    );
    return finalRoutes;
  } else {
    return newRoutes;
  }
};
export const filterEventRoutes = (adminRoutes, features, applicationMode) => {
  let newRoutes = [];
  for (let i in adminRoutes) {
    const item = adminRoutes[i];
    if (item.path.includes("events")) {
      if (applicationMode === "cause-project") {
        if (features.events.active) {
          newRoutes.push(item);
        }
      }
    } else {
      if (item.path.includes("tax-reciepts")) {
        if (applicationMode === "cause-project") {
          if (features.taxReciepts.active) {
            newRoutes.push(item);
          }
        }
      } else {
        newRoutes.push(item);
      }
    }
  }
  return newRoutes;
};

export const prepareDataToExport = (page) => {
  let rows = [];
  for (let i in page) {
    const row1 = page[i];
    rows.push(row1.original);
  }

  return rows;
};

export const filterLoggedOutRoutes = (loggedOutRoutes, applicationMode) => {
  let newRoutes = [];
  for (let i in loggedOutRoutes) {
    const item = loggedOutRoutes[i];

    if (item.path !== "/action/verify-email-pending") {
      newRoutes.push(item);
    }
  }
  if (applicationMode !== "cause-project") {
    const newRoutesFiltered = newRoutes.filter(
      (item) => !item.path.includes("events")
    );
    newRoutes = newRoutesFiltered;
  }
  return newRoutes;
};

export const getValuesFromLinkAfterPayment = (link) => {
  const pathArray = link.split("&");
  let obj = {};
  for (let i in pathArray) {
    const item = pathArray[i];
    const itemValue = item.split("=");
    obj[itemValue[0].replace("?", "")] = itemValue[1];
  }
  return obj;
};

export const makeActiveColumnsObject = (columns, defaultHiddenColumns) => {
  if (columns) {
    const columnsActual = columns[0].columns;
    let columnObj = [];
    for (let i = 1; i <= columnsActual.length - 1; i++) {
      columnObj.push({
        name: columnsActual[i].Header,
        active: defaultHiddenColumns
          ? defaultHiddenColumns.includes(columnsActual[i].Header)
            ? false
            : true
          : true,
        accessor: columnsActual[i].accessor,
      });
    }
    return columnObj;
  }
};

export const checkFeaturesInGroup = (features) => {};
