import firebase from "firebase";
import "firebase/analytics";
import { checkForErrors } from "../utils/errorFunctions";
import { getCoords } from "../utils/locationFunctions";

// import { chdandged } from "../utils/cause-db-temp";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

console.log(process.env.REACT_APP_FIREBASE_PROJECT_ID);
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

firestore
  .enablePersistence()
  .then(() => {
    console.log("Offline mode is on");
  })
  .catch((err) => {
    console.log(err);
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

export const addUserNameEmailOAuth = async (userAuth) => {
  if (!userAuth) return;
  const userRef = firebase.firestore().doc(`users/${userAuth.uid}`);
  try {
    const functions = firebase.functions();
    const checkAndUpdateAuthUserDocument = functions.httpsCallable(
      "userFunctions-checkAndUpdateAuthUserDocument"
    );
    const positionRes = await getCoords();

    const userAuthData = {
      displayName: userAuth.displayName,
      email: userAuth.email,
      lastSignInTime: userAuth.metadata.lastSignInTime,
      createdAt: userAuth.metadata.creationTime,
      sourceType: userAuth.providerData[0].providerId,
      source: "sign-in",
      location: positionRes.success ? positionRes.coords : positionRes.message,
    };

    const resp = await checkAndUpdateAuthUserDocument(userAuthData);
    checkForErrors(resp.data);

    return userRef;
  } catch (err) {
    console.log("Error inside user update function", err.message);
    return userRef;
  }
};

export const logOutFunction = async () => {
  await auth.signOut();
};

export default firebase;
export { firebaseConfig };
