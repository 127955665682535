import dashboard_icon from "../assets/menu-icons/disabledIcons/Dashboard.svg";
import contributions_icon from "../assets/menu-icons/disabledIcons/Contributions.svg";
import campaign_icon from "../assets/menu-icons/disabledIcons/Campaigns.svg";
import setting_icon from "../assets/menu-icons/disabledIcons/Settings.svg";
import events_icon from "../assets/menu-icons/disabledIcons/Events.svg"

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: dashboard_icon,
    cName: "nav-text",
  },
  {
    title: "Contributions",
    path: "/contributions/allCauses",
    icon: contributions_icon,
    cName: "nav-text",
  },

  {
    title: "Campaigns",
    path: "/active-campaigns",
    icon: campaign_icon,
    cName: "nav-text",
  },
  {
    title: "Tax Receipts",
    path: "/user-tax-reciepts",
    icon: events_icon,
    cName: "nav-text",
  },
  {
    title: "Events",
    path: "/events",
    icon: events_icon,
    cName: "nav-text",
  },
  {
    title: "Settings",
    path: "/settings/personal",
    icon: setting_icon,
    cName: "nav-text",
  },
];

export const SidebarDataRight = [
  {
    title: "preference",
    path: "/settings/preference",
    cName: "nav-text",
  },
  {
    title: "Personal",
    path: "/Personal",
    cName: "nav-text",
  },
  {
    title: "Billing",
    path: "/Billing",
    cName: "nav-text",
  },
];
