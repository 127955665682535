const initialState = {
  tipsBalance: [],
  tipsBalanceaLoading: { loading: false, success: false, error: null },
};
const tipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FETCH_TIPS_TABLE_RESULT":
      return {
        ...state,
        tipsBalance: action.payload,
      };
    case "SET_FETCH_TIPS_TABLE_RESULT_LOADING":
      return {
        ...state,
        tipsBalanceaLoading: action.payload,
      };
    default:
      return state;
  }
};

export default tipsReducer;
