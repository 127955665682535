export const setIndexForDisbursementInitiation = (value) => ({
  type: "SET_INDEX_TO_INITIATE_DISBURSEMENT",
  payload: value,
});

export const disbursementGlobalBalance = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: "SET_DISBURSEMENT_LOADING",
      payload: { loading: true, success: false, error: null },
    });
    const firebase = getFirebase();
    try {
      const disbursementInGlobalBalance = firebase
        .functions()
        .httpsCallable("disbursementInGlobalBalance");
      const result = await disbursementInGlobalBalance(data);
      console.log(result);
      if (result.data.success) {
        dispatch({
          type: "SET_DISBURSEMENT_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = {
          status: 400,
          message: result.data.error,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DISBURSEMENT_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const resetDisbursementLoading = () => ({
  type: "SET_DISBURSEMENT_LOADING",
  payload: { loading: false, success: false, error: null },
});

export const fetchDisbursementDataFromQLDB = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: "SET_DISBURSEMENT_TABLE_DATA_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const fetchDisbursementTableData = firebase
        .functions()
        .httpsCallable("fetchDisbursementTableData");
      const resp = await fetchDisbursementTableData(data);
      if (resp.data.success) {
        dispatch({
          type: "SET_DISBURSEMENT_TABLE_DATA",
          payload: resp.data.disbursement,
        });
        dispatch({
          type: "SET_DISBURSEMENT_TABLE_DATA_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: resp.data.error.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DISBURSEMENT_TABLE_DATA_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchDisbursementDataFromFirebase = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const disbursementCollectionRef = firestore.collection("disbursements");
    dispatch({
      type: "SET_DISBURSEMENTS_DATA_FIRESTORE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const disbursementRes = await disbursementCollectionRef.get();
      let disbursementsRecord = [];
      let globalDisbursementListNew = [];
      let individualDisbursementListNew = [];
      if (!disbursementRes.empty) {
        const length = disbursementRes.docs.length;
        for (let i in disbursementRes.docs) {
          const item = disbursementRes.docs[i];
          disbursementsRecord.push({ id: item.id, ...item.data() });
          let id = item.id;
          await disbursementCollectionRef
            .doc(item.id)
            .collection("global-disbursements")
            .onSnapshot((snapShot) => {
              if (!snapShot.empty) {
                dispatch({
                  type: "SET_LOADING_GLOBAL_LIST",
                  payload: true,
                });
                let globalDisbursementList = [];
                for (let j in snapShot.docs) {
                  const newItem = snapShot.docs[j];
                  const data = newItem.data();
                  globalDisbursementList.push({
                    status: returnStatuses(data.error, data.done),
                    id: item.id,
                    time: item.time,
                  });
                }

                globalDisbursementListNew[i] = { globalDisbursementList, id };

                if (parseInt(i) === parseInt(length) - 1) {
                  dispatch({
                    type: "SET_GLOBAL_DISBURSEMENT_LIST",
                    payload: globalDisbursementListNew,
                  });
                  dispatch({
                    type: "SET_LOADING_GLOBAL_LIST",
                    payload: false,
                  });
                }
              } else {
                dispatch({
                  type: "SET_GLOBAL_DISBURSEMENT_LIST",
                  payload: [],
                });
              }
            });
          await disbursementCollectionRef
            .doc(item.id)
            .collection("individual-disbursements")
            .onSnapshot((snapShot) => {
              dispatch({
                type: "SET_LOADING_INDIVIDUAL_LIST",
                payload: true,
              });
              let individualDisbursementList = [];
              for (let j in snapShot.docs) {
                const Nitem = snapShot.docs[j];
                const data = Nitem.data();
                individualDisbursementList.push({
                  status: returnStatuses(data.error, data.done),
                  id: Nitem.id,
                  time: data.time,
                  done: data.done,
                  error: data.error,
                  DCF: returnStatuses(
                    data.DisbursedContributionsFirestore
                      ? data.DisbursedContributionsFirestore.error
                      : false,
                    data.DisbursedContributionsFirestore
                      ? data.DisbursedContributionsFirestore.done
                      : false
                  ),
                  CDQ: returnStatuses(
                    data.addContributionDataQLDB
                      ? data.addContributionDataQLDB.error
                      : false,
                    data.addContributionDataQLDB
                      ? data.addContributionDataQLDB.done
                      : false
                  ),

                  IBF: returnStatuses(
                    data.updateIndividualBalanceFirestore
                      ? data.updateIndividualBalanceFirestore.error
                      : false,
                    data.updateIndividualBalanceFirestore
                      ? data.updateIndividualBalanceFirestore.done
                      : false
                  ),
                  IBQ: returnStatuses(
                    data.updateIndividualBalanceQLDB
                      ? data.updateIndividualBalanceQLDB.error
                      : false,
                    data.updateIndividualBalanceQLDB
                      ? data.updateIndividualBalanceQLDB.done
                      : false
                  ),
                  CDF: returnStatuses(
                    data.userContributionDataInfoFirestore.error,
                    data.userContributionDataInfoFirestore.done
                  ),
                  DDF: returnStatuses(
                    data.userDisbursementDataInfoFirestore
                      ? data.userDisbursementDataInfoFirestore.error
                      : false,
                    data.userDisbursementDataInfoFirestore
                      ? data.userDisbursementDataInfoFirestore.done
                      : false
                  ),
                  CFC: returnStatuses(
                    data.campaignsFundedCountByCauseFirestore
                      ? data.campaignsFundedCountByCauseFirestore.error
                      : false,
                    data.campaignsFundedCountByCauseFirestore
                      ? data.campaignsFundedCountByCauseFirestore.done
                      : false
                  ),
                  DCC: returnStatuses(
                    data.userDisbursementAmountCountByCauseFirestore
                      ? data.userDisbursementAmountCountByCauseFirestore.error
                      : false,
                    data.userDisbursementAmountCountByCauseFirestore
                      ? data.userDisbursementAmountCountByCauseFirestore.done
                      : false
                  ),
                });
              }
              individualDisbursementListNew[i] = {
                individualDisbursementList,
                id,
              };
              if (parseInt(i) === parseInt(length) - 1) {
                dispatch({
                  type: "SET_INDIVIDUAL_DISBURSEMENT_LIST",
                  payload: individualDisbursementListNew,
                });
                dispatch({
                  type: "SET_LOADING_INDIVIDUAL_LIST",
                  payload: false,
                });
              }
            });
        }
        dispatch({
          type: "SET_DISBURSEMENTS_DATA_FIRESTORE",
          payload: disbursementsRecord,
        });
      }
      // await disbursementCollectionRef.onSnapshot(async (snapshot) => {
      //   if (!snapshot.empty) {

      //     for (let i in snapshot.docs) {
      //       const item = snapshot.docs[i];
      //       disbursementsRecord.push({ id: item.id, ...item.data() });
      //       let id = item.id;
      //     }
      //   }

      // });

      dispatch({
        type: "SET_DISBURSEMENTS_DATA_FIRESTORE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DISBURSEMENTS_DATA_FIRESTORE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const fetchDisbursementCompleteDataFromFirebase = (id) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const disbursementDocRef = firestore.collection("disbursements").doc(id);
    const globalDisbursementsRef = disbursementDocRef.collection(
      "global-disbursements"
    );
    const individualDisbursementsRef = disbursementDocRef.collection(
      "individual-disbursements"
    );

    dispatch({
      type: "SET_DISBURSEMENT_DATA_FIRESTORE_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      let disbursementUnsubscribe = disbursementDocRef.onSnapshot(
        (disbursementDocRes) => {
          let disbursementData = {};
          const disbursementDocData = disbursementDocRes.data();
          disbursementData = {
            id: disbursementDocRes.id,
            ...disbursementDocData,
          };
          dispatch({
            type: "SET_DISBURSEMENT_DATA_FIRESTORE",
            payload: { data: disbursementData },
          });
        }
      );
      let globalDisbursementUnsubscribe = globalDisbursementsRef.onSnapshot(
        (disbursementGlobalRes) => {
          let globalDisbursementList = [];
          for (let index in disbursementGlobalRes.docs) {
            const doc = disbursementGlobalRes.docs[index];
            const data = doc.data();
            globalDisbursementList.push({
              status: returnStatuses(data.error, data.done),
              id: doc.id,
              time: doc.time,
            });
          }
          dispatch({
            type: "SET_DISBURSEMENT_DATA_FIRESTORE",
            payload: { global: globalDisbursementList },
          });
        }
      );

      let individualDisbursementUnsubscribe =
        individualDisbursementsRef.onSnapshot((disbursementIndividualRes) => {
          let individualDisbursementList = [];
          for (let index in disbursementIndividualRes.docs) {
            const doc = disbursementIndividualRes.docs[index];
            const data = doc.data();
            individualDisbursementList.push({
              status: returnStatuses(data.error, data.done),
              id: doc.id,
              time: data.time,
              done: data.done,
              error: data.error,
              DCF: returnStatuses(
                data.DisbursedContributionsFirestore
                  ? data.DisbursedContributionsFirestore.error
                  : false,
                data.DisbursedContributionsFirestore
                  ? data.DisbursedContributionsFirestore.done
                  : false
              ),
              CDQ: returnStatuses(
                data.addContributionDataQLDB
                  ? data.addContributionDataQLDB.error
                  : false,
                data.addContributionDataQLDB
                  ? data.addContributionDataQLDB.done
                  : false
              ),

              IBF: returnStatuses(
                data.updateIndividualBalanceFirestore
                  ? data.updateIndividualBalanceFirestore.error
                  : false,
                data.updateIndividualBalanceFirestore
                  ? data.updateIndividualBalanceFirestore.done
                  : false
              ),
              IBQ: returnStatuses(
                data.updateIndividualBalanceQLDB
                  ? data.updateIndividualBalanceQLDB.error
                  : false,
                data.updateIndividualBalanceQLDB
                  ? data.updateIndividualBalanceQLDB.done
                  : false
              ),
              CDF: returnStatuses(
                data.userContributionDataInfoFirestore.error,
                data.userContributionDataInfoFirestore.done
              ),
              DDF: returnStatuses(
                data.userDisbursementDataInfoFirestore
                  ? data.userDisbursementDataInfoFirestore.error
                  : false,
                data.userDisbursementDataInfoFirestore
                  ? data.userDisbursementDataInfoFirestore.done
                  : false
              ),
              CFC: returnStatuses(
                data.campaignsFundedCountByCauseFirestore
                  ? data.campaignsFundedCountByCauseFirestore.error
                  : false,
                data.campaignsFundedCountByCauseFirestore
                  ? data.campaignsFundedCountByCauseFirestore.done
                  : false
              ),
              DCC: returnStatuses(
                data.userDisbursementAmountCountByCauseFirestore
                  ? data.userDisbursementAmountCountByCauseFirestore.error
                  : false,
                data.userDisbursementAmountCountByCauseFirestore
                  ? data.userDisbursementAmountCountByCauseFirestore.done
                  : false
              ),
              userName: data.userName && data.userName,
            });
          }
          dispatch({
            type: "SET_DISBURSEMENT_DATA_FIRESTORE",
            payload: { individual: individualDisbursementList },
          });
        });

      dispatch({
        type: "SET_DISBURSEMENT_DATA_FIRESTORE_SNAPSHOT_SUBSCRIPTIONS",
        payload: {
          data: disbursementUnsubscribe,
          global: globalDisbursementUnsubscribe,
          individual: individualDisbursementUnsubscribe,
        },
      });
      dispatch({
        type: "SET_DISBURSEMENT_DATA_FIRESTORE_LOADING",
        payload: { loading: false, success: true, error: null },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DISBURSEMENT_DATA_FIRESTORE_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const disbursementReleaseFirestoreEnteries = (id) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const disbursementDataReleaseFirestore = firebase
      .functions()
      .httpsCallable("disbursementDataReleaseFirestore");
    dispatch({
      type: "SET_DISBURSEMENT_START_PROCESSING_LOADING",
      payload: { loading: true, success: false, error: null },
    });

    try {
      const res = await disbursementDataReleaseFirestore({ id });
      if (res.data.success) {
        dispatch({
          type: "SET_DISBURSEMENT_START_PROCESSING_LOADING",
          payload: { loading: false, success: true, error: null },
        });
      } else {
        const errorObject = { status: 400, message: res.data.message };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "SET_DISBURSEMENT_START_PROCESSING_LOADING",
        payload: { loading: false, success: false, error: err.message },
      });
    }
  };
};

export const unmountDisbursementDataSnapshots = () => {
  return (dispatch, getState) => {
    const disbursementDataFirestoreSnapshotsSubscriptions =
      getState().disbursement.disbursementDataFirestoreSnapshotsSubscriptions;
    if (
      disbursementDataFirestoreSnapshotsSubscriptions.data &&
      disbursementDataFirestoreSnapshotsSubscriptions.global &&
      disbursementDataFirestoreSnapshotsSubscriptions.individual
    ) {
      disbursementDataFirestoreSnapshotsSubscriptions.data();
      disbursementDataFirestoreSnapshotsSubscriptions.global();
      disbursementDataFirestoreSnapshotsSubscriptions.individual();
    }
    dispatch({ type: "RESET_DISBURSEMENT_DATA_FIRESTORE" });
  };
};

const returnStatuses = (error, done) => {
  if (error && done) {
    return "Error";
  } else if (!error && done) {
    return "Done";
  } else if (!error && !done) {
    return "In Progress";
  } else if (error && !done) {
    return "Error";
  }
};
