const getInitialsFromTitle = (title) => {
  return title
    .split(" ")
    .slice(0, 2)
    .join(" ")
    .match(/\b(\w)/g);
};
export const getCauseIconFromId = (cause, causeinfo) => {
  for (const causeObj of causeinfo) {
    if (causeObj.id === cause) {
      return causeObj.infoIcon;
    }
  }
};

export const getCauseFromCausesArray = (id, causesArray) => {
  return causesArray.find((item) => item.id === id);
};

export const getCauseBackgroundImageFromId = (cause, causeinfo) => {
  for (const causeObj of causeinfo) {
    if (causeObj.id === cause) {
      return causeObj.footer;
    }
  }
};
export const removeLastLetter = (string, option) => {
  let editedText;
  if (option) {
    const lastLetter = string[string.length - 1];
    if (lastLetter === "s" || lastLetter === "S") {
      editedText = string.slice(0, -1);
    }
  }
  return editedText;
};

export const getLabelFromItem = (item) => {
  return item === "name"
    ? "Full Name"
    : item === "email"
    ? "Email"
    : item === "gender"
    ? "Gender"
    : item === "dateOfBirth"
    ? "Year of Birth"
    : "";
};
export const getCampaignTitleFromCMS = (basicBrandInfo, noS) => {
  return basicBrandInfo
    ? basicBrandInfo.campaignName
      ? noS
        ? basicBrandInfo.campaignName
        : basicBrandInfo.campaignName + "s"
      : "Campaigns"
    : "Campaigns";
};

export const getCauseTitleFromCMS = (basicBrandInfo, noS) => {
  return basicBrandInfo
    ? basicBrandInfo.causeName
      ? noS
        ? basicBrandInfo.causeName
        : basicBrandInfo.causeName + "s"
      : "Causes"
    : "Causes";
};
export const getWebsiteNameFromCMS = (basicBrandInfo) => {
  return basicBrandInfo ? basicBrandInfo.websiteName : "";
};
export const getWebsiteLinkFromCMS = (basicBrandInfo) => {
  return basicBrandInfo ? basicBrandInfo.websiteLink : "";
};

export const getCauseNameFromId = (word) => {
  var reg = /([a-z0-9])([A-Z])/g;
  const words = word.replace(reg, "$1 $2").split(" ");
  let str = `${words[0]}${words[1] ? " " + words[1] : ""}`;
  return str;
};

export default getInitialsFromTitle;
