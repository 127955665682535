export const setEmailDesignLoading = (value) => ({
  type: "SET_EMAIL_DESIGN_LOADING",
  payload: value,
});

export const setEmailConfigurationLoading = (value) => ({
  type: "SET_EMAIL_CONFIGURATION_LOADING",
  payload: value,
});

export const setEmailHistoryLoading = (value) => ({
  type: "SET_EMAIL_HISTORY_LOADING",
  payload: value,
});
export const setToggleLoading = (value) => ({
  type: "SET_TOGGLE_LOADING",
  payload: value,
});

export const setEmailConfigurations = (value) => ({
  type: "SET_EMAIL_CONFIGURATIONS",
  payload: value,
});

export const setEmailConfigurationsById = (value) => ({
  type: "SET_EMAIL_CONFIGURATIONS_BY_ID",
  payload: value,
});

export const setEmailHistory = (value) => ({
  type: "SET_EMAIL_HISTORY",
  payload: value,
});

export const saveEmailDesignToDB = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      const firebase = getFirebase();
      dispatch(
        setEmailDesignLoading({ loading: true, success: false, error: false })
      );

      const saveEmailDesignToDB = firebase
        .functions()
        .httpsCallable("saveEmailDesignToDB");
      const result = await saveEmailDesignToDB(data);
      console.log(result);

      if (result.data.success) {
        dispatch(
          setEmailDesignLoading({ loading: false, success: true, error: false })
        );
        window.location.reload();
      } else {
        const errorObject = {
          status: 400,
          message: result.data.error,
        };
        throw errorObject;
      }
    } catch (err) {
      dispatch(
        setEmailDesignLoading({ loading: false, success: false, error: err })
      );
    }
  };
};

export const toggleTemplateStatus = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch(
        setToggleLoading({ loading: true, success: false, error: false })
      );
      const toggleEmailTemplateStatus = firebase
        .functions()
        .httpsCallable("toggleEmailTemplateStatus");
      const result = await toggleEmailTemplateStatus(data);
      if (result.data.success) {
        dispatch(
          setToggleLoading({ loading: false, success: true, error: false })
        );
      } else {
        const errorObject = {
          status: 400,
          message: result.data.error,
        };
        throw errorObject;
      }
    } catch (err) {
      console.log(err.message);
      dispatch(
        setToggleLoading({ loading: false, success: false, error: err })
      );
    }
  };
};

export const fetchEmailConfigurations = () => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch(
        setEmailConfigurationLoading({
          loading: true,
          success: false,
          error: false,
        })
      );
      const db = getFirestore();

      db.collection("email-configurations").onSnapshot((snapshot) => {
        if (snapshot.size) {
          let emailConfirgurations = [];
          let emailConfirgurationsById = {};
          snapshot.forEach((doc) => {
            const data = doc.data();

            emailConfirgurations.push({ ...data, id: doc.id });
            emailConfirgurationsById = {
              ...emailConfirgurationsById,
              [doc.id]: { ...data },
            };
            if (snapshot.size === emailConfirgurations.length) {
              dispatch(setEmailConfigurations([...emailConfirgurations]));
              dispatch(
                setEmailConfigurationsById({ ...emailConfirgurationsById })
              );

              dispatch(
                setEmailConfigurationLoading({
                  loading: false,
                  success: true,
                  error: false,
                })
              );
            }
          });
        } else {
          dispatch(
            setEmailConfigurationLoading({
              loading: false,
              success: true,
              error: false,
            })
          );
        }
      });
    } catch (err) {
      dispatch(
        setEmailConfigurationLoading({
          loading: false,
          success: false,
          error: err,
        })
      );
    }
  };
};

export const fetchEmailHistory = () => {
  return async (dispatch, getState, { getFirestore }) => {
    try {
      dispatch(
        setEmailHistoryLoading({
          loading: true,
          success: false,
          error: false,
        })
      );
      const db = getFirestore();
      let emailHistory = [];

      db.collection("notification").onSnapshot((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(async (doc) => {
            const data = doc.data();
            const { user: userData, uid } = data;

            if (userData) {
              emailHistory.push({
                ...data,
                id: uid,
                name: userData.displayName,
                email: userData.email,
              });
            }

            if (snapshot.size === emailHistory.length) {
              dispatch(setEmailHistory(emailHistory));
              dispatch(
                setEmailHistoryLoading({
                  loading: false,
                  success: true,
                  error: false,
                })
              );
            }
          });
        } else {
          dispatch(
            setEmailHistoryLoading({
              loading: false,
              success: true,
              error: false,
            })
          );
        }
      });
    } catch (err) {
      dispatch(
        setEmailHistoryLoading({
          loading: false,
          success: false,
          error: err,
        })
      );
    }
  };
};
