const initialState = {
  eventListLoading: { loading: false, success: true, error: null },
  eventDraftsLoading: { loading: false, success: false, error: null },
  eventCreationLoading: { loading: false, success: false, error: null },
  eventTicketLoading: { loading: false, success: false, error: null },
  allEvents: [],
  allEventDrafts: [],
  activeEvents: [],
  activeEventsIndexed: {},
  inactiveEvents: [],
  archivedEvents: [],
  userTickets: [],
  selectedEventBackRoute: "",
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EVENTS_LIST_LOADING":
      return { ...state, eventListLoading: action.payload };
    case "SET_EVENT_CREATION_LOADING":
      return { ...state, eventCreationLoading: action.payload };
    case "SET_ALL_EVENT_DRAFTS":
      return { ...state, allEventDrafts: action.payload };
    case "SET_EVENT_DRAFTS_LOADING":
      return { ...state, eventDraftsLoading: action.payload };
    case "SET_ALL_EVENTS_LIST":
      return { ...state, allEvents: action.payload };
    case "SET_ACTIVE_EVENTS_LIST":
      return { ...state, activeEvents: action.payload };
    case "SET_ACTIVE_EVENTS_LIST_INDEXED":
      return { ...state, activeEventsIndexed: action.payload };
    case "SET_INACTIVE_EVENTS_LIST":
      return { ...state, inactiveEvents: action.payload };
    case "SET_ARCHIVED_EVENTS_LIST":
      return { ...state, archivedEvents: action.payload };
    case "SET_SELECTED_EVENT_BACK_ROUTE":
      return { ...state, selectedEventBackRoute: action.payload };
    case "SET_EVENT_TICKET_LOADING":
      return { ...state, eventTicketLoading: action.payload };
    case "SET_ALL_TICKETS":
      return { ...state, userTickets: action.payload };
    default:
      return state;
  }
};

export default eventsReducer;
