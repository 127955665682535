import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router";

import Loader from "../../components/loader/loader.component";
import useFinalRoutes from "../../custom-hooks/useFinalRoutes";
import { getValueFromUrl } from "../../utils/linkFunctions";

const checkPaddingPages = (location) => {
  const path = location.pathname;

  if (path === "/confirmation" || path.startsWith("/about-us")) {
    return false;
  } else {
    return true;
  }
};

const RoutesHandling = () => {
  const location = useLocation();

  const { role, authenticated, explorePlatform, verified } = useSelector(
    (state) => state.user.currentUser
  );

  const finalRoutes = useFinalRoutes();
  const donateDirectly = window.localStorage.getItem("donateDirectly");

  const headerValue = getValueFromUrl(location.search, "header");
  const redirectUrl = getValueFromUrl(location.search, "redirectUrl");

  return (
    <React.Suspense fallback={<Loader />}>
      <div
        className={`app-container ${
          authenticated
            ? `${checkPaddingPages(location) ? "logged-in" : ""}`
            : window.location.pathname.startsWith("/donate-directly")
            ? "padding logged-out"
            : "logged-out"
        }
        ${headerValue ? "no-header" : ""}
        ${explorePlatform ? "explore-platform-header" : ""}
        `}
      >
        {finalRoutes.length && (
          <Switch>
            {finalRoutes.map(
              ({ component: Component, ...rest }, idx) =>
                Component && (
                  <Route
                    key={idx}
                    path={rest.path}
                    exact={rest.exact}
                    component={Component}
                  />
                )
            )}
            {
              {
                true: {
                  true: {
                    admin: <Redirect to="/admin-dashboard" />,
                    "system-admin": <Redirect to="/admin-features" />,
                    undefined: {
                      true: { null: <Redirect to="/dashboard" /> }[
                        redirectUrl
                      ] || <Redirect to={redirectUrl} />,
                      false: <Redirect to="/donate-directly/contribution" />,
                    }[explorePlatform],
                  }[role],
                  false: <Redirect to="/action/verify-email-pending" />,
                }[verified],
                false: {
                  false: <Redirect exact to="/login" />,
                  true: <Redirect exact to="/donate-directly/contribution" />,
                }[donateDirectly],
              }[authenticated]
            }
          </Switch>
        )}
      </div>
    </React.Suspense>
  );
};

export default RoutesHandling;
