const initialState = {
  basicBrandInfo: null,
  brandingColors: null,
  features: null,
  featuresLoading: { loading: true, success: false, error: null },
  basicInfoLoading: { loading: true, success: false, error: null },
  taxReceiptInfoLoading:{loading: true, success: false , error: null},
  brandingColorInfoLoading: { loading: true, success: false, error: null },
  brandingFeaturesLoading: { loading: true, success: false, error: null },
  brandingFeatures: null,
  configurations: null,
  configurationsLoading: { loading: false, success: false, error: null },
  addConfigurtationsLoading: { loading: false, success: false, error: null },
  applicationModeLoading: { loading: false, success: false, error: null },
  applicationMode: "",
  applicationModeLoaded: false,
};

const InfauqeInstanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BASIC_BRANDING_INFO":
      return { ...state, basicBrandInfo: action.payload };
     
    case "SET_SYSTEM_ADMIN_FEATURES_INFO":
      return { ...state, features: action.payload };
    case "SET_FEATURES_INFO_LOADING":
      return { ...state, featuresLoading: action.payload };
    case "SET_FETCH_APPLICATION_MODE_SETTING":
      return { ...state, applicationModeLoading: action.payload };
    case "SET_APPLICATION_MODE":
      return {
        ...state,
        applicationMode: action.payload,
        applicationModeLoaded: true,
      };
    case "SET_BASIC_INFO_LOADING":
      return { ...state, basicInfoLoading: action.payload };
     

    case "SET_BRANDING_COLOR_INFO_LOADING":
      return { ...state, brandingColorInfoLoading: action.payload };
    case "SET_BRANDING_COLORS":
      return { ...state, brandingColors: action.payload };
    case "SET_BRANDING_FEATURES_LOADING":
      return { ...state, brandingFeaturesLoading: action.payload };
    case "SET_BRANDING_FEATURES":
      return { ...state, brandingFeatures: action.payload };
    case "SET_CONFIGURATIONS_LOADING":
      return { ...state, configurationsLoading: action.payload };
    case "SET_CONFIGURATIONS":
      return { ...state, configurations: action.payload };
    case "ADD_CONFIGURATIONS_LOADING":
      return { ...state, addConfigurtationsLoading: action.payload };
    default:
      return state;
  }
};
export default InfauqeInstanceReducer;
